import { useEffect, useRef, useState } from "react";

import ReactCrop, { PixelCrop } from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";

import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";

import { useAppSelector } from "../../../../store/redux-hooks";
import { useUploadAvatarMutation } from "../../../../store/slices/apiSlice";

import { useDebounceEffect } from "../../../../util/useDebounceEffect";
import { canvasPreview } from "../../../../util/functions/canvasPreview";

const DEFAULT_CROP: PixelCrop = {
  unit: "px",
  x: 0,
  y: 0,
  width: 150,
  height: 150,
};
const DEFAULT_COMPLETED_CROP = null;
const DEFAULT_AVATAR = null;
const DEFAULT_ERROR_MESSAGE = "";

interface ChangeAvatarProps {
  resetChangeAvatar: () => void;
  updateCompanyUser: () => void;
}

export default ({
  resetChangeAvatar,
  updateCompanyUser,
}: ChangeAvatarProps) => {
  const user = useAppSelector((state) => state.auth.user);

  const [avatar, setAvatar] = useState<File | null | undefined>(DEFAULT_AVATAR);

  const [errorMessage, setErrorMessage] = useState<string>(
    DEFAULT_ERROR_MESSAGE
  );

  const [crop, setCrop] = useState<PixelCrop>(DEFAULT_CROP);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(
    DEFAULT_COMPLETED_CROP
  );

  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const [uploadAvatarMutation, { isSuccess, isLoading, isError }] =
    useUploadAvatarMutation();

  useEffect(() => {
    if (isSuccess) {
      setErrorMessage("");
      updateCompanyUser();
    }
  }, [isSuccess, updateCompanyUser]);

  const submitUploadAvatar = async () => {
    if (avatar && user?.id) {
      if (avatar.size >= 1024 * 1024 * 10) {
        setErrorMessage("File too large!");
      } else {
        // if completedCrop is not set, user used the default crop, generate preview from it
        if (!completedCrop && previewCanvasRef.current && imgRef.current) {
          canvasPreview(imgRef.current, previewCanvasRef.current, crop);
        }

        const blob = await new Promise<Blob | null>((resolve) =>
          previewCanvasRef.current?.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/png",
            "0.8"
          )
        );

        if (blob) {
          // name is irrelevant, backend will override it
          const file = new File([blob], "user-avatar.png");

          const formData = new FormData();

          formData.append("file", file);

          uploadAvatarMutation({
            body: formData,
            urlArgs: { userId: user.id },
          });
        }
      }
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  return (
    <Container fluid>
      <div className="max-width-medium">
        {(isError || errorMessage) && (
          <Row>
            <Col>
              <Alert variant="danger">
                {errorMessage ||
                  "Something went wrong. Please try again later."}
              </Alert>
            </Col>
          </Row>
        )}
        {isSuccess && !isLoading && (
          <Row>
            <Col>
              <Alert variant="success">
                You have successfully changed your avatar!
              </Alert>
            </Col>
          </Row>
        )}
        <Row className="mb-3">
          <Col className="text-title fw-medium custom-text-title">
            Upload Avatar
          </Col>
        </Row>
        <Form>
          <Row className="mb-4">
            <Col>
              <Form.Group>
                <Form.Control
                  disabled={isLoading}
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    if ("files" in e.target) {
                      setCrop(DEFAULT_CROP);
                      setCompletedCrop(DEFAULT_COMPLETED_CROP);
                      setAvatar(e.target?.files?.[0]);
                    }
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          {avatar && (
            <Row className="mb-5">
              <Col>
                <ReactCrop
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  circularCrop={true}
                  aspect={1}
                  keepSelection
                >
                  <img
                    ref={imgRef}
                    src={URL.createObjectURL(avatar)}
                    alt="user avatar"
                    className="crop-editor-img"
                  />
                </ReactCrop>
              </Col>
            </Row>
          )}
          {/* AVATAR PREVIEW / CANVAS IS HIDDEN */}
          <Row style={{ display: "none" }}>
            <Col>
              {crop && (
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: crop.width,
                    height: crop.height,
                  }}
                />
              )}
            </Col>
          </Row>
          <Row className="justify-content-end mb-3">
            <Col xs="auto">
              <Button
                variant="secondary"
                type="button"
                disabled={isLoading || !avatar}
                onClick={() => {
                  resetChangeAvatar();
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                variant="primary"
                type="submit"
                disabled={isLoading || !avatar}
                onClick={(event) => {
                  event.preventDefault();

                  submitUploadAvatar();
                }}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mx-2"
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Container>
  );
};
