import React, { useEffect, useState } from "react";
import { IErrorAPIRequest } from "../../../errors/apiErrors";
import { processCompanyClientRequestErrors } from "../../../errors/companyClientErrors";
import { useAppDispatch } from "../../../store/redux-hooks";
import {
  ICreateOrUpdateCompanyClientRequest,
  useCreateCompanyClientMutation,
  useUpdateCompanyClientMutation,
} from "../../../store/slices/apiSlice";
import { updateCompanyClientFormErrors } from "../../../store/slices/companyClientsSlice";

interface UpsertCompanyClientMutation {
  upsertCompanyClientMutation: (
    obj: ICreateOrUpdateCompanyClientRequest
  ) => unknown;
  isSuccess: boolean;
  isLoading: boolean;
  requestErrors?: IErrorAPIRequest;
  setRequestErrors: React.Dispatch<
    React.SetStateAction<IErrorAPIRequest | undefined>
  >;
}

const useUpsertCompanyClientMutation = (
  isFormEdit: boolean
): UpsertCompanyClientMutation => {
  const dispatch = useAppDispatch();

  const [requestErrors, setRequestErrors] = useState<IErrorAPIRequest>();

  const [
    createCompanyClient,
    {
      isSuccess: createCompanyClientIsSuccess,
      isLoading: createCompanyClientIsLoading,
      error: companyClientCreateError,
    },
  ] = useCreateCompanyClientMutation();

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processCompanyClientRequestErrors(companyClientCreateError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateCompanyClientFormErrors(formValidationErrors));
  }, [companyClientCreateError, dispatch]);

  const [
    updateCompanyClient,
    {
      isSuccess: updateCompanyClientIsSuccess,
      isLoading: updateCompanyClientIsLoading,
      error: companyClientUpdateError,
    },
  ] = useUpdateCompanyClientMutation();

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processCompanyClientRequestErrors(companyClientUpdateError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateCompanyClientFormErrors(formValidationErrors));
  }, [companyClientUpdateError, dispatch]);

  if (!isFormEdit) {
    return {
      upsertCompanyClientMutation: (obj) => createCompanyClient(obj),
      isSuccess: createCompanyClientIsSuccess,
      isLoading: createCompanyClientIsLoading,
      requestErrors,
      setRequestErrors,
    };
  } else {
    return {
      upsertCompanyClientMutation: ({ body, urlArgs }) =>
        updateCompanyClient({ urlArgs, body }),
      isSuccess: updateCompanyClientIsSuccess,
      isLoading: updateCompanyClientIsLoading,
      requestErrors,
      setRequestErrors,
    };
  }
};

export default useUpsertCompanyClientMutation;
