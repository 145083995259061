import qs from "qs";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  Order,
  STRIPE_SUBSCRIPTION_STATUS,
} from "../../util/constants/constants";
import {
  AccountsAndPayrollPermission,
  AccountsAndPayrollRole,
  CompanyUserPermission,
  CompanyUserRole,
  ProjectPermission,
  ProjectRole,
  ProposalRole,
} from "../../util/constants/userForm/userFormConstants";

interface ILoginRequest {
  email: string;
  password: string;
}

interface IResetPasswordRequest {
  email: string;
}

interface IRecoverAccountRequest {
  recoveryClaim: string;
  password: string;
}

interface ISetupAccountRequest {
  invitationClaim: string;
  password: string;
}

interface ISignUpRequest {
  firstName: string;
  email: string;
  password: string;
}

interface IChangePasswordRequest {
  currentPassword: string;
  newPassword: string;
}

interface IChangeUserSettingsRequest {
  body: {
    chatMessageUnreadEmailNotificationEnabled?: boolean;
  };
}

export interface IGetUserRequest {
  urlArgs: { userId: string };
}

export interface ICompanyBaseSchema {
  country?: string;
  // companyType: SelectOptionObject | null;
  homeCurrency?: string;
  isVatRegistered?: boolean;
  //
  type?: string | null;
  name?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  phoneNumber?: string | null;
  companyNumber?: string | null;
  vatNumber?: string | null;
  taxNumber?: string | null;
  website?: string | null;
}

export interface ICreateCompanyRequest {
  body: ICompanyBaseSchema;
  urlArgs?: never;
}

export interface IUpdateCompanyRequest {
  urlArgs: {
    companyId: string;
  };
  body: ICompanyBaseSchema & {
    deletedAt?: Date | null;
  };
}

export interface ICompanyUserBaseSchema {
  companyId: string;
  // user
  firstName: string;
  email: string;
  // user role
  user: {
    role: CompanyUserRole;
    permissions: CompanyUserPermission[];
  };
  // accounts and payroll
  accountsAndPayroll: {
    role: AccountsAndPayrollRole;
    permissions: AccountsAndPayrollPermission[];
  };
  // projects
  projects: {
    role: ProjectRole;
    permissions: ProjectPermission[];
  };
  // proposal
  proposal: {
    role: ProposalRole;
  };
  //
}
interface IUpdateCompanyUserRequestBodySchema {
  companyId: string;
  // user role
  user: {
    role: CompanyUserRole;
    permissions: CompanyUserPermission[];
  };
  // accounts and payroll
  accountsAndPayroll: {
    role: AccountsAndPayrollRole;
    permissions: AccountsAndPayrollPermission[];
  };
  // projects
  projects: {
    role: ProjectRole;
    permissions: ProjectPermission[];
  };
  // proposal
  proposal: {
    role: ProposalRole;
  };
}
interface ICompanyUserParams {
  userId: string;
}

export interface ICreateOrUpdateCompanyUserRequest {
  body: ICompanyUserBaseSchema;
  urlArgs: ICompanyUserParams;
}

interface IUpdateCompanyUserRequest {
  body: IUpdateCompanyUserRequestBodySchema;
  urlArgs: ICompanyUserParams;
}

export interface IRemoveCompanyUserRequest {
  urlArgs: { userId: string };
  body: { companyId: string };
}

export interface IGetCompaniesRequest {
  params?: {
    limit?: number;
    skip?: number;
  };
}

export interface IGetCompanyRequest {
  urlArgs: { companyId: string };
}

export interface IGetCompanyUsersRequest {
  urlArgs: {
    companyId: string;
  };
  params?: {
    limit?: number;
    skip?: number;
    filters?: {
      userId?: string;
      userRoles?: CompanyUserRole[];
      firstNameOrEmailContains?: string;
    };
    sort?: {
      column: string;
      order: Order;
    }[];
  };
}

export interface IGetCompanyUserRequest {
  urlArgs: {
    companyId: string;
    userId: string;
  };
}

export interface ICompanyUserRolesAndPermissions {
  companyId?: string;
  // user role
  user?: {
    role?: CompanyUserRole;
    permissions?: CompanyUserPermission[];
  };
  // accounts and payroll
  accountsAndPayroll?: {
    role?: AccountsAndPayrollRole;
    permissions?: AccountsAndPayrollPermission[];
  };
  // projects
  projects?: {
    role?: ProjectRole;
    permissions?: ProjectPermission[];
  };
  // proposal
  proposal?: {
    role?: ProposalRole;
  };
}

export interface IGetCompanyUserBaseResponse {
  _id: string;
  // user
  firstName?: string;
  email?: string;
  type?: string;
  createdAt?: Date;
  updatedAt?: Date;
  companies?: ICompanyUserRolesAndPermissions[];
  useSoundNotification?: boolean;
  chatMessageUnreadEmailNotificationEnabled?: boolean;
}

/**
 * company client interfaces
 */

export interface IGetCompanyClientBaseResponse {
  uuid: string;
  name?: string;
  companyName?: string;
  email?: string;
  phoneNumber?: string;
  vatNumber?: string;
  country?: string;
  currency?: string;
  addressLine1?: string;
  addressLine2?: string;
  hidden?: false;
}

export interface ICreateOrUpdateCompanyClientRequestBodySchema {
  name?: string;
  companyName?: string;
  email?: string;
  phoneNumber?: string;
  vatNumber?: string;
  country?: string;
  currency?: string;
  addressLine1?: string;
  addressLine2?: string;
  hidden?: false;
}
export interface ICreateOrUpdateCompanyClientRequest {
  body: ICreateOrUpdateCompanyClientRequestBodySchema;
  urlArgs: { companyId: string; clientUuid?: string };
}

export interface IGetCompanyClientsRequest {
  urlArgs: {
    companyId: string;
  };
  params?: {
    limit?: number;
    skip?: number;
    filters?: {
      uuid?: string;
      clientNameOrEmailContains?: string;
    };
    sort?: {
      column: string;
      order: Order;
    }[];
  };
}

export interface IGetCompanyClientRequest {
  urlArgs: {
    companyId: string;
    clientUuid: string;
  };
}

export interface IRemoveCompanyClientRequest {
  urlArgs: { companyId: string; clientUuid: string };
}

export interface IGetCompanyClientsBaseResponse {
  result: IGetCompanyClientBaseResponse[];
  total: number;
}

export interface IGetCompanyUsersBaseResponse {
  result: IGetCompanyUserBaseResponse[];
  total: number;
}

export interface ICompanyBaseResponse {
  _id?: string;
  name?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  phoneNumber?: string | null;
  companyNumber?: string | null;
  vatNumber?: string | null;
  taxNumber?: string | null;
  website?: string | null;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  //
  country?: string | null;
  type?: string | null; // companyType
  homeCurrency?: string | null;
  isVatRegistered?: boolean | null;
  // deletedAt?: Date | null;
  //
  subscriptionAmount?: string;
  subscriptionChargedUsersCount?: string;
}

export interface IGetCompaniesResponse {
  total?: number;
  result?: ICompanyBaseResponse[];
}

export interface ISelectCompanyRequest {
  body: {
    companyId: string;
  };
}

export interface ISelectCompanyResponse {
  message?: string;
}

/**
 * subscriptions interfaces
 */
export interface IGetCompanyInvoicesRequest {
  urlArgs: {
    companyId: string;
  };
}

export interface IGetSubscriptionDataRequest {
  urlArgs: {
    companyId: string;
    priceId: string;
  };
}

export interface IGetCreditCardsDataRequest {
  urlArgs: {
    companyId: string;
  };
}

export interface IGetCreditCardsDataResponse {
  data: {
    billing_details: {
      name: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    };
    card: {
      brand: string;
      exp_month: string;
      exp_year: string;
      last4: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
    created: number;
  }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface ISubscriptionResponse {
  id: string;
  status: STRIPE_SUBSCRIPTION_STATUS;
  cancel_at_period_end: boolean;
  latest_invoice: {
    payment_intent: {
      client_secret: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  pending_setup_intent: {
    client_secret: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface IStartSubscriptionRequest {
  urlArgs: {
    companyId: string;
  };
  body: {
    priceId: string;
    vatRateId: string;
    paymentMethodId: string;
  };
}
export interface ICancelSubscriptionRequest {
  urlArgs: {
    companyId: string;
  };
  body: {
    subscriptionId: string;
  };
}

export interface IAddCreditCardResponse {
  message: string;
  succedeed: boolean;
  setupIntent?:
    | { status?: string; client_secret: string }
    | {
        status?: string | null;
        rawType?: string | null;
        code?: string | null;
        statusCode?: string | null;
        decline_code?: string | null;
      };
}

export interface IAddCreditCardRequest {
  urlArgs: {
    companyId: string;
  };
  body: {
    paymentMethodId: string;
  };
}

export interface IGetCompanyInvoicesResponse {
  object: string;
  data: {
    id: string;
    total: number;
    currency: string;
    status: string;
    created: number;
    invoice_pdf: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }[];
  has_more: boolean;
  url: string;
}

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_HUB_API_URL,
    credentials: "include",
    paramsSerializer: (params) => qs.stringify(params),
  }),
  reducerPath: "api",
  tagTypes: ["COMPANIES"],
  endpoints: (builder) => ({
    /**
     * AUTH
     */
    login: builder.mutation<{ message: string }, ILoginRequest>({
      query: (body) => ({
        url: "/api/auth/login",
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation<{ message: string }, IResetPasswordRequest>(
      {
        query: (body) => ({
          url: "/api/auth/forgot-password",
          method: "POST",
          body,
        }),
      }
    ),
    recoverAccount: builder.mutation<
      { message: string },
      IRecoverAccountRequest
    >({
      query: (body) => ({
        url: "/api/auth/recover-account",
        method: "POST",
        body,
      }),
    }),
    setupAccount: builder.mutation<{ message: string }, ISetupAccountRequest>({
      query: (body) => ({
        url: "/api/auth/setup-account",
        method: "POST",
        body,
      }),
    }),
    signUp: builder.mutation<{ message: string }, ISignUpRequest>({
      query: (body) => ({
        url: "/api/auth/register",
        method: "POST",
        body,
      }),
    }),
    logout: builder.mutation<{ message: string }, void>({
      query: () => ({
        url: "/api/auth/logout",
        method: "POST",
      }),
    }),
    /**
     * USER
     */
    changePassword: builder.mutation<
      { message: string },
      IChangePasswordRequest
    >({
      query: (body) => ({
        url: "/api/v1/users/change-password",
        method: "POST",
        body,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uploadAvatar: builder.mutation<any, any>({
      query: ({ body, urlArgs }) => ({
        url: `/api/v1/users/${urlArgs.userId}/avatar/upload`,
        method: "POST",
        body,
      }),
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setNotificationsSound: builder.mutation<any, any>({
      query: ({ body, urlArgs }) => ({
        url: `/api/v1/users/${urlArgs.userId}/set-sound-notifications`,
        method: "POST",
        body,
      }),
    }),
    toggleChatMessageUnreadEmailNotifications: builder.mutation<
      IGetCompanyUserBaseResponse,
      IChangeUserSettingsRequest
    >({
      query: ({ body }) => ({
        url: `/api/v1/users/settings`,
        method: "POST",
        body,
      }),
    }),
    /**
     * COMPANY
     */
    createCompany: builder.mutation<
      ICompanyBaseResponse,
      ICreateCompanyRequest
    >({
      query: ({ body }) => ({
        url: "/api/companies",
        method: "POST",
        body,
      }),
      invalidatesTags: ["COMPANIES"],
    }),
    updateCompany: builder.mutation<
      ICompanyBaseResponse,
      IUpdateCompanyRequest
    >({
      query: ({ urlArgs, body }) => ({
        url: `/api/companies/${urlArgs.companyId}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["COMPANIES"],
    }),
    getCompanies: builder.query<
      IGetCompaniesResponse,
      IGetCompaniesRequest | void
    >({
      query: ({ params } = {}) => ({
        url: `/api/companies`,
        params: { ...params, limit: 1000 },
        method: "GET",
        // body,
      }),
      providesTags: ["COMPANIES"],
    }),
    getCompany: builder.query<ICompanyBaseResponse, IGetCompanyRequest>({
      query: ({ urlArgs }) => ({
        url: `/api/companies/${urlArgs.companyId}`,
        method: "GET",
        // body,
      }),
      providesTags: ["COMPANIES"],
    }),
    selectCompany: builder.mutation<
      ISelectCompanyResponse,
      ISelectCompanyRequest
    >({
      query: ({ body }) => ({
        url: `/api/companies/select`,
        method: "POST",
        body,
      }),
    }),
    /**
     * COMPANY USER
     */
    createCompanyUser: builder.mutation<
      { message: string },
      ICreateOrUpdateCompanyUserRequest
    >({
      query: ({ body }) => ({
        url: `/api/v1/users/companies`,
        method: "POST",
        body,
      }),
    }),
    updateCompanyUser: builder.mutation<
      { message: string },
      // ICreateOrUpdateCompanyUserRequest
      IUpdateCompanyUserRequest
    >({
      query: ({ urlArgs, body }) => ({
        url: `/api/v1/users/${urlArgs.userId}/companies`,
        method: "PATCH",
        body,
      }),
    }),
    getCompanyUsers: builder.query<
      IGetCompanyUsersBaseResponse,
      IGetCompanyUsersRequest
    >({
      query: ({ urlArgs, params }) => ({
        url: `/api/companies/${urlArgs.companyId}/users`,
        method: "GET",
        params,
      }),
    }),
    getCompanyUser: builder.query<
      IGetCompanyUserBaseResponse,
      IGetCompanyUserRequest
    >({
      query: ({ urlArgs }) => ({
        url: `/api/companies/${urlArgs.companyId}/users/${urlArgs.userId}`,
        method: "GET",
      }),
    }),
    removeCompanyUser: builder.mutation<void, IRemoveCompanyUserRequest>({
      query: ({ urlArgs, body }) => ({
        url: `/api/v1/users/${urlArgs.userId}/companies`,
        method: "DELETE",
        body,
      }),
    }),
    /**
     * COMPANY CLIENT
     */
    createCompanyClient: builder.mutation<
      { message: string },
      ICreateOrUpdateCompanyClientRequest
    >({
      query: ({ urlArgs, body }) => ({
        url: `/api/companies/${urlArgs.companyId}/clients`,
        method: "POST",
        body,
      }),
    }),
    updateCompanyClient: builder.mutation<
      { message: string },
      // ICreateOrUpdateCompanyClientRequest
      ICreateOrUpdateCompanyClientRequest
    >({
      query: ({ urlArgs, body }) => ({
        url: `/api/companies/${urlArgs.companyId}/clients/${urlArgs.clientUuid}`,
        method: "PATCH",
        body,
      }),
    }),
    getCompanyClients: builder.query<
      IGetCompanyClientsBaseResponse,
      IGetCompanyClientsRequest
    >({
      query: ({ urlArgs, params }) => ({
        url: `/api/companies/${urlArgs.companyId}/clients`,
        method: "GET",
        params,
      }),
    }),
    getCompanyClient: builder.query<
      IGetCompanyClientBaseResponse,
      IGetCompanyClientRequest
    >({
      query: ({ urlArgs }) => ({
        url: `/api/companies/${urlArgs.companyId}/clients/${urlArgs.clientUuid}`,
        method: "GET",
      }),
    }),
    removeCompanyClient: builder.mutation<void, IRemoveCompanyClientRequest>({
      query: ({ urlArgs }) => ({
        url: `/api/companies/${urlArgs.companyId}/clients/${urlArgs.clientUuid}`,
        method: "DELETE",
      }),
    }),
    /**
     * SUBSCRIPTIONS
     */
    getInvoices: builder.query<
      IGetCompanyInvoicesResponse,
      IGetCompanyInvoicesRequest
    >({
      query: ({ urlArgs }) => ({
        url: `/api/invoices/${urlArgs.companyId}`,
        method: "GET",
      }),
    }),
    getSubscription: builder.query<
      ISubscriptionResponse,
      IGetSubscriptionDataRequest
    >({
      query: ({ urlArgs }) => ({
        url: `/api/subscriptions/monthly/${urlArgs.companyId}/prices/${urlArgs.priceId}`,
        method: "GET",
      }),
    }),
    startSubscription: builder.mutation<
      ISubscriptionResponse,
      IStartSubscriptionRequest
    >({
      query: ({ urlArgs, body }) => ({
        url: `/api/subscriptions/monthly/${urlArgs.companyId}`,
        method: "POST",
        body,
      }),
    }),
    cancelSubscription: builder.mutation<
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      any,
      ICancelSubscriptionRequest
    >({
      query: ({ urlArgs, body }) => ({
        url: `/api/subscriptions/cancel/${urlArgs.companyId}`,
        method: "POST",
        body,
      }),
    }),
    getCreditCardsData: builder.query<
      IGetCreditCardsDataResponse,
      IGetCreditCardsDataRequest
    >({
      query: ({ urlArgs }) => ({
        url: `/api/credit-card/${urlArgs.companyId}`,
        method: "GET",
      }),
    }),
    addCreditCard: builder.mutation<
      IAddCreditCardResponse,
      IAddCreditCardRequest
    >({
      query: ({ urlArgs, body }) => ({
        url: `/api/credit-card/${urlArgs.companyId}`,
        method: "POST",
        body,
      }),
    }),
    /**
     * OTHER
     */
    health: builder.query<JSON, void>({
      query: () => ({
        url: "/health",
        method: "GET",
      }),
    }),
  }),
});

export const {
  //
  useLazyHealthQuery,
  useLoginMutation,
  useResetPasswordMutation,
  useSignUpMutation,
  useRecoverAccountMutation,
  useSetupAccountMutation,
  useLogoutMutation,
  useChangePasswordMutation,
  useSetNotificationsSoundMutation,
  useToggleChatMessageUnreadEmailNotificationsMutation,
  //
  useUploadAvatarMutation,
  //
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  //
  useCreateCompanyUserMutation,
  useUpdateCompanyUserMutation,
  //
  useGetCompaniesQuery,
  useLazyGetCompaniesQuery,
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  //
  useGetCompanyUsersQuery,
  useLazyGetCompanyUsersQuery,
  useGetCompanyUserQuery,
  useLazyGetCompanyUserQuery,
  //
  useSelectCompanyMutation,
  //
  useCreateCompanyClientMutation,
  useUpdateCompanyClientMutation,
  useRemoveCompanyClientMutation,
  //
  useRemoveCompanyUserMutation,
  useLazyGetCompanyClientQuery,
  useLazyGetCompanyClientsQuery,
  //
  useGetInvoicesQuery,
  useLazyGetInvoicesQuery,
  useGetSubscriptionQuery,
  useLazyGetSubscriptionQuery,
  useStartSubscriptionMutation,
  useGetCreditCardsDataQuery,
  useLazyGetCreditCardsDataQuery,
  useAddCreditCardMutation,
  useCancelSubscriptionMutation,
  //
  useHealthQuery,
} = apiSlice;
