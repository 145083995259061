import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { IErrorData, IErrorAPIRequest } from "./apiErrors";

export const processAuthRequestErrors = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  let formValidationErrors = {};
  let authRequestErrors: IErrorAPIRequest = {};

  if (error && "status" in error) {
    switch (error.status) {
      case "CUSTOM_ERROR":
      // fallsthrough
      case "PARSING_ERROR":
      // fallsthrough
      case "FETCH_ERROR":
        authRequestErrors = {
          ...authRequestErrors,
          message: "Something went wrong. Please try again later.",
        };
        break;
      default:
      // status is number, handle that in next IF statement
    }
  }

  if (error && "data" in error) {
    const data = error.data as IErrorData;

    if (Array.isArray(data?.message)) {
      // messages in array are thrown by failing validation
      data.message.forEach((errorMessage: string) => {
        /**
         * Order errors by priority
         * First one will be triggered and displayed.
         * Put specific errors on top, and generic errors on bottom
         */
        switch (errorMessage) {
          /**
           * EMAIL
           */
          case "email should not be empty":
            formValidationErrors = {
              ...formValidationErrors,
              email: "Email is required",
            };
            break;
          case "email must be a string":
          // fallsthrough
          case "email must be an email":
            formValidationErrors = {
              ...formValidationErrors,
              email: "Invalid email",
            };
            break;
          /**
           * PASSWORD
           */
          // password
          case "password must be a string":
          // fallsthrough
          case "newPassword must be a string":
            formValidationErrors = {
              ...formValidationErrors,
              password: "Invalid value",
            };
            break;
          case "password should not be empty":
          // fallsthrough
          case "newPassword should not be empty":
            formValidationErrors = {
              ...formValidationErrors,
              password: "Password is required",
            };
            break;
          case "password must be shorter than or equal to 72 characters":
          // fallsthrough
          case "newPassword must be shorter than or equal to 72 characters":
            formValidationErrors = {
              ...formValidationErrors,
              password: "Password can be at most 72 characters long",
            };
            break;
          // password has to be at least 1 char for login (for backwards compatibility)
          case "password must be longer than or equal to 1 characters":
            formValidationErrors = {
              ...formValidationErrors,
              password: "Password must be at least 1 character long",
            };
            break;
          // password has to be at least 8 chars for signup or when changing it
          case "password must be longer than or equal to 8 characters":
            formValidationErrors = {
              ...formValidationErrors,
              password: "Password must be at least 8 character long",
            };
            break;
          case "newPassword must be longer than or equal to 8 characters":
            formValidationErrors = {
              ...formValidationErrors,
              password: "Password must be at least 8 character long",
            };
            break;
          case "Password needs to contain at least one upper case, at least one lower case English letter, at least one digit and at least one special character.":
            formValidationErrors = {
              ...formValidationErrors,
              password:
                "Weak password. Must contain at least one uppercase and lowercase letter, digit and a special character.",
            };
            break;
          case "currentPassword should not be empty":
          // fallsthrough
          case "currentPassword must be longer than or equal to 1 characters":
            formValidationErrors = {
              ...formValidationErrors,
              currentPassword: "Current password is required",
            };
            break;
          /**
           * RECOVERY CLAIM
           */
          case "recoveryClaim must be a string":
            /**
             * this shouldn't occur - users do not control recoveryClaim
             */
            authRequestErrors = {
              ...authRequestErrors,
              message: "Something went wrong. Please try again later.",
            };
            break;
          /**
           * first name (sign up / register)
           */
          case "firstName must be shorter than or equal to 100 characters":
            formValidationErrors = {
              ...formValidationErrors,
              firstName: "First name cannot be longer than 100 characters",
            };
            break;
          case "firstName must be longer than or equal to 2 characters":
            formValidationErrors = {
              ...formValidationErrors,
              firstName: "First name cannot be shorter than 2 characters",
            };
            break;
          case "firstName must be a string":
            formValidationErrors = {
              ...formValidationErrors,
              firstName: "Invalid value",
            };
            break;
          case "firstName should not be empty":
            formValidationErrors = {
              ...formValidationErrors,
              firstName: "First name is required",
            };
            break;
          default:
            if (
              errorMessage.startsWith("email must match") &&
              errorMessage.endsWith("regular expression")
            ) {
              formValidationErrors = {
                ...formValidationErrors,
                email: "Email is invalid",
              };
              break;
            }
        }
      });
    } else {
      /**
       * it's a string
       * Messages as string as usually thrown in/by the code itself (custom checks, library errors, ...)
       */
      switch (data.message) {
        // credentials
        case "Invalid credentials. Please try again.":
          authRequestErrors = {
            ...authRequestErrors,
            message:
              "Invalid credentials. Make sure you're using correct email and password.",
          };
          break;
        // password
        case "Invalid current password. Please input current password again.":
          formValidationErrors = {
            ...formValidationErrors,
            currentPassword: "Current password is incorrect",
          };
          break;
        // email
        case "Email is already in use.":
          formValidationErrors = {
            ...formValidationErrors,
            email: "Account with this email already exists",
          };
          break;
        case "Verification token expired":
          authRequestErrors = {
            ...authRequestErrors,
            message: "Token expired or was already used",
          };
          break;
        /**
         * Default / other
         *  Includes 'dynamic' error messages (those that have invalid value as part of the error)
         */
        default:
          if (
            data.message?.startsWith("User with email") &&
            data.message?.endsWith("not found")
          ) {
            formValidationErrors = {
              ...formValidationErrors,
              email: "There is no user with this email",
            };
            break;
          }

          /**
           * Can be message like:
           *   "Cannot POST /api/auth/something-that-doesnt-exist"
           *   "Internal server error"
           */
          authRequestErrors = {
            ...authRequestErrors,
            message: "Something went wrong. Please try again later.",
          };
      }
    }
  }

  return { formValidationErrors, authRequestErrors };
};
