import {
  Col,
  Container,
  // Figure
  Row,
} from "react-bootstrap";
import { PersonCircle } from "react-bootstrap-icons";

export default () => {
  return (
    <Container
      fluid
      className="h-100 d-flex align-items-end justify-content-end px-0"
    >
      <div id="bullet-review" className="w-100 h-93 ml-auto ">
        <Container fluid className="h-100 d-flex flex-column py-5 px-5">
          <Row className="mb-5 bullet-review-main-content">
            <Col>
              <span className="quote-start">“</span>
              <span className="bullet-review-text">
                <span>I’d actually be lost without Bullet these days. </span>
                <span className="text-nowrap">Crazy how </span>
                <span>quick you can get used to things being this easy. </span>
                <span className="text-nowrap">Never have </span>
                <span>to think about book-keeping or tracking invoicing. </span>
                <span className="text-nowrap">Thanks for </span>
                <span>a great service!</span>
              </span>
              <span className="quote-end">„</span>
            </Col>
          </Row>
          <Row>
            <Col xs="auto">
              {/* <Figure>
                <Figure.Image
                  className="bullet-review-user-image"
                  src="/logo.png"
                />
              </Figure> */}
              <PersonCircle size={48} />
            </Col>
            <Col className="d-flex align-items-center">Darren Yorke</Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};
