import { Button, Col, Row } from "react-bootstrap";
import { CheckLg } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../routes/routes";
import TrialQASection from "./TrialQASection";
import TrialRemaining from "./TrialRemaining";

export default () => {
  const navigate = useNavigate();

  return (
    <>
      <Row className="mb-4">
        <Col>
          <Row className="bg-color-pale-pink py-5 px-4 border-top-radius-12px">
            <Col xs={12} className="mb-3">
              <Row className="justify-content-between text-heading">
                <Col xs="auto">Unlimited everything</Col>
                <Col xs="auto">€49/m pp</Col>
              </Row>
            </Col>
            <Col xs={12}>
              <span>
                Our all-in-one fixed price app bundles over 8 apps together{" "}
                <span className="fw-bold text-nowrap">
                  saving you over €3,324
                </span>{" "}
                a year per person.
              </span>
            </Col>
          </Row>
          <Row className="bg-color-pale-grey py-4 px-4 border-bottom-radius-12px justify-content-around">
            <Col xs="auto">
              <Row className="mb-2">
                <Col className="text-nowrap">
                  <CheckLg color="black" />
                  <span className="ms-1">Accounts</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="text-nowrap">
                  <CheckLg color="black" />
                  <span className="ms-1">Payroll</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="text-nowrap">
                  <CheckLg color="black" />
                  <span className="ms-1">Project Management</span>
                </Col>
              </Row>
            </Col>
            <Col xs="auto">
              <Row className="mb-2">
                <Col className="text-nowrap">
                  <CheckLg color="black" />
                  <span className="ms-1">Banking</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="text-nowrap">
                  <CheckLg color="black" />
                  <span className="ms-1">Proposals</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="text-nowrap">
                  <CheckLg color="black" />
                  <span className="ms-1">Communication</span>
                </Col>
              </Row>
            </Col>
            <Col xs="auto">
              <Row className="mb-2">
                <Col className="text-nowrap">
                  <CheckLg color="black" />
                  <span className="ms-1">Storage</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="text-nowrap">
                  <CheckLg color="black" />
                  <span className="ms-1">Receipts</span>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="text-nowrap">
                  <CheckLg color="black" />
                  <span className="ms-1">Invoices</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <Row className="bg-color-pale-grey py-4 px-4 border-radius-12px">
            <Col xs={12}>
              <Row className="justify-content-between mb-4">
                <Col xs="auto">
                  <span className="fw-bold">Free trial</span>
                </Col>
                <Col xs="auto">
                  <TrialRemaining />
                </Col>
              </Row>
              <Row className="justify-content-around mb-5">
                <Col xs="auto">
                  <Row className="mb-2">
                    <Col>
                      <span className="fw-semibold">General</span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="text-nowrap">
                      <CheckLg color="black" />
                      <span className="ms-1">1 Company</span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="text-nowrap">
                      <CheckLg color="black" />
                      <span className="ms-1">3 Guests</span>
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto">
                  <Row className="mb-2">
                    <Col>
                      <span className="fw-semibold">Projects</span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="text-nowrap">
                      <CheckLg color="black" />
                      <span className="ms-1">1 Project</span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="text-nowrap">
                      <CheckLg color="black" />
                      <span className="ms-1">100 MG Storage</span>
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto">
                  <Row className="mb-2">
                    <Col>
                      <span className="fw-semibold">Accounts/Payroll</span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="text-nowrap">
                      <CheckLg color="black" />
                      <span className="ms-1">Full Access</span>
                    </Col>
                  </Row>
                </Col>
                <Col xs="auto">
                  <Row className="mb-2">
                    <Col>
                      <span className="fw-semibold">Proposal</span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col className="text-nowrap">
                      <CheckLg color="black" />
                      <span className="ms-1">Full Access</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col xs="auto">
                  <Button
                    onClick={() =>
                      navigate(RoutePaths.BILLING_START_SUBSCRIPTION)
                    }
                  >
                    Add Card Details
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <TrialQASection />
    </>
  );
};
