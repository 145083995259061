import Select, { Props, Theme, StylesConfig, components } from "react-select";

/**
 * TS support for react-select is rather insufficient or lacking.
 * Refer to https://github.com/JedWatson/react-select/issues
 *  for workarounds.
 */

export type SelectOptionObject = {
  value: string | number | boolean;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

const customStyles: StylesConfig<SelectOptionObject> = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "40px",
    border: state.isFocused
      ? "solid 1px var(--pale-pink)"
      : "solid 1px var(--silver)",
    transition: "none",
    ":hover": {
      border: state.isFocused
        ? "solid 1px var(--pale-pink)"
        : "solid 1px var(--silver)",
    },
    boxShadow: state.isFocused
      ? "0 0 0 1px var(--pale-pink) !important"
      : "none",
    // if it's a child of .input-group, assume it has a button on right-hand side
    ".input-group &": {
      borderRadius: "8px 0px 0px 8px",
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
    marginTop: "2px",
    borderRadius: "12px",
    boxShadow: "var(--box-shadow-larger)",
  }),
  menuList: (provided) => ({ ...provided, padding: "8px" }),
  option: (provided, state) => ({
    ...provided,
    borderRadius: "4px",
    padding: "9.5px 12px",
    ":hover": {
      cursor: "pointer",
      ...(!state.isFocused && { backgroundColor: "var(--pale-grey-two)" }),
    },
    wordBreak: "break-all",
  }),
  container: (provided) => ({
    ...provided,
    ".input-group &": {
      flexGrow: "1",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#0A1F44",
    svg: {
      width: "17px",
      height: "17px",
    },
    // rotate: state.isFocused ? "180deg" : "0deg",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#f5f6f8",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#979797",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#979797",
    ":hover": {
      color: "var(--dark)",
    },
  }),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Input = (props: any) => {
  const { autoComplete = props.autoComplete } = props.selectProps;
  return <components.Input {...props} autoComplete={autoComplete} />;
};

const CustomSelect = <T,>(
  props: Props<T extends undefined ? SelectOptionObject : T>
) => {
  return (
    <Select
      // this line is okay but @types/react-select doesn't have adequate configuration
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      styles={customStyles}
      // TODO: revisit after fix for https://github.com/JedWatson/react-select/issues/5502
      menuPlacement="auto"
      components={{ Input }}
      autoComplete="prevent-autocomplete"
      theme={(theme: Theme) => ({
        ...theme,
        borderRadius: 8,
        colors: {
          ...theme.colors,
          primary: "var(--dark)",
          primary25: "#f5f6f8",
        },
      })}
      {...props}
    />
  );
};

export default CustomSelect;
