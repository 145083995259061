import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { RoutePaths } from "../../routes/routes";

const DEFAULT_REDIRECT_TIMEOUT = 5;

export default () => {
  const [count, setCount] = useState(DEFAULT_REDIRECT_TIMEOUT);
  const navigate = useNavigate();

  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      setCount((count) => count - 1);
    }, 1000);

    return () => {
      interval.current && clearTimeout(interval.current);
    };
  }, []);

  useEffect(() => {
    if (count <= 0) {
      interval.current && clearInterval(interval.current);
      navigate(RoutePaths.LOGIN, { replace: true });
    }
  }, [count, navigate]);

  return (
    <div>
      <p>Page you're looking for does not exist</p>
      <div>
        Redirecting you to {RoutePaths.LOGIN} in {count} second
        {count === 1 ? "" : "s"}
      </div>
    </div>
  );
};
