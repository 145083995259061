import "./scss/bootstrapWithOverrides.scss";
import "./App.scss";

import { BrowserRouter, Routes } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store/store";

import { applicationRoutes } from "./routes/routes";

import Header from "./components/header/Header";
import { generateRoutes } from "./routes/generateRoutes";
import CookieHandler from "./components/config/CookieHandler";
import GlobalLoader from "./components/common/GlobalLoader";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <CookieHandler />
        <Header />
        <GlobalLoader />
        <div id="main-content-view">
          <Routes>{generateRoutes(applicationRoutes)}</Routes>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
