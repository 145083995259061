export interface IVatRegistrationType {
  value: boolean;
  label: string;
}

export const vatRegistrationTypeTrue: IVatRegistrationType = {
  value: true,
  label: `Registered for `,
};

export const vatRegistrationTypeFalse: IVatRegistrationType = {
  value: false,
  label: `Not registered for `,
};

export const vatRegistrationTypesDropdown: IVatRegistrationType[] = [
  vatRegistrationTypeTrue,
  vatRegistrationTypeFalse,
];
