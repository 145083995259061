import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { RoutePaths } from "../../../routes/routes";

import { useAppDispatch, useAppSelector } from "../../../store/redux-hooks";
import {
  updateResetPasswordForm,
  updateResetPasswordFormErrors,
} from "../../../store/slices/authSlice";
import { getNestedObject } from "../../../util/functions";
import { processAuthRequestErrors } from "../../../errors/authErrors";
import { useResetPasswordMutation } from "../../../store/slices/apiSlice";
import useAuthenticatedUserRedirect from "../../../hooks/useAuthenticatedUserRedirect";
import { IErrorAPIRequest } from "../../../errors/apiErrors";

export default () => {
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useAuthenticatedUserRedirect();

  const location = useLocation();

  const [requestErrors, setRequestErrors] = useState<IErrorAPIRequest>();

  const [
    resetPasswordQuery,
    { isSuccess, error: resetPasswordError, isLoading },
  ] = useResetPasswordMutation();

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processAuthRequestErrors(resetPasswordError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateResetPasswordFormErrors(formValidationErrors));
  }, [resetPasswordError, dispatch]);

  useEffect(() => {
    const emailFromLocationState = getNestedObject(location, [
      "state",
      "email",
    ]);

    emailFromLocationState &&
      !authState.resetPasswordData.email &&
      !authState.resetPasswordData.hasUserModifiedData &&
      dispatch(
        updateResetPasswordForm({
          email: emailFromLocationState,
        })
      );
  }, [
    dispatch,
    location,
    authState.resetPasswordData.email,
    authState.resetPasswordData.hasUserModifiedData,
  ]);

  return (
    <Container
      fluid
      className="height-100 px-0 authForms"
      id="resetPasswordForm"
    >
      <Row className="max-width-560px mx-auto my-auto align-items-center height-100">
        <Card body border="light" className="px-0 px-sm-5 py-5">
          <Form>
            <Col>
              <Row className="justify-content-center mb-4">
                <span className="w-auto text-heading">Forgot Password</span>
              </Row>
              <Row>
                <Form.Group className="mb-5" controlId="formBasicEmail">
                  <Form.Text className="ps-1 custom-form-text">
                    Please enter email address you registered with
                  </Form.Text>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    tabIndex={1}
                    value={authState.resetPasswordData.email}
                    onChange={({ target: { value } }) =>
                      dispatch(updateResetPasswordForm({ email: value }))
                    }
                    isInvalid={Boolean(
                      authState.resetPasswordData.errors?.email
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {authState.resetPasswordData.errors?.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row
                className={
                  "justify-content-center " +
                  ((isSuccess && !isLoading) || requestErrors?.message
                    ? "mb-3"
                    : "mb-5")
                }
              >
                <Button
                  variant="dark"
                  type="submit"
                  className="w-auto"
                  tabIndex={3}
                  disabled={isLoading}
                  onClick={(event) => {
                    event.preventDefault();

                    resetPasswordQuery({
                      email: authState.resetPasswordData.email,
                    });
                  }}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Send Instructions"
                  )}
                </Button>
              </Row>
              {isSuccess && !isLoading && (
                <Row>
                  <Alert variant="success">
                    Password recovery email sent. Please check your inbox.
                  </Alert>
                </Row>
              )}
              {requestErrors?.message && (
                <Row>
                  <Alert variant="danger">{requestErrors?.message}</Alert>
                </Row>
              )}
              <Row className="mb-2">
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row className="justify-content-between">
                <Form.Text className="width-fit-content muted- custom-text-tiny">
                  {`Just remembered your password? `}
                  <Link
                    to={RoutePaths.LOGIN}
                    state={{ email: authState.resetPasswordData.email }}
                    replace
                    tabIndex={5}
                  >
                    Login here
                  </Link>
                </Form.Text>
                <Form.Text className="width-fit-content muted- custom-text-tiny">
                  {"Don't have an account? "}
                  <Link
                    to={RoutePaths.SIGNUP}
                    state={{ email: authState.resetPasswordData.email }}
                    tabIndex={6}
                  >
                    Sign Up
                  </Link>
                </Form.Text>
              </Row>
            </Col>
          </Form>
        </Card>
      </Row>
    </Container>
  );
};
