import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { determineHomeNavigationEventKey } from "../components/main/home/HomeNavigation";
import useAuth from "../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../store/redux-hooks";
import { useLazyGetCompanyUserQuery } from "../store/slices/apiSlice";
import { setLoadedCompanyUserRolesAndPermissions } from "../store/slices/companySlice";
import { addAlert } from "../store/slices/notificationSlice";
import {
  CompanyUserPermission,
  CompanyUserRole,
  ProjectRole,
} from "../util/constants/userForm/userFormConstants";
import { RoutePaths } from "./routes";
import { PROJECTS_APP_URL } from "../util/constants/constants";

let initialRequestPathname: string | null = null;
let initialRequestSearch: URLSearchParams | null = null;

/*eslint @typescript-eslint/no-explicit-any: ["off"]*/
export default ({ children }: any) => {
  const user = useAppSelector((state) => state.auth.user);
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);
  const dispatch = useAppDispatch();
  const [didRedirect, setDidRedirect] = useState(false);
  const [didInitialRender, setDidInitialRender] = useState(false);

  const location = useLocation();

  const { isLoadingCookie } = useAuth();

  const navigate = useNavigate();

  const [getCompanyUser, { data: getCompanyUserData, error }] =
    useLazyGetCompanyUserQuery();

  /**
   * handle loading company user roles and permissions after switching/selecting company
   *  also handles redirect to initially requested path
   */
  useEffect(() => {
    if (user?.id && loadedCompany?.id) {
      getCompanyUser({
        urlArgs: { companyId: loadedCompany.id, userId: user.id },
      });
    }
  }, [user?.id, loadedCompany?.id, getCompanyUser]);

  useEffect(() => {
    const userRolesAndPermissions = !error
      ? getCompanyUserData?.companies?.[0]
      : undefined;
    dispatch(setLoadedCompanyUserRolesAndPermissions(userRolesAndPermissions));

    if (getCompanyUserData?.companies || error) {
      if (!didRedirect && didInitialRender && initialRequestPathname) {
        setDidRedirect(true);

        const firstLogin = initialRequestSearch?.get("firstLogin") === "true";
        if (userRolesAndPermissions && firstLogin) {
          if (
            userRolesAndPermissions.projects?.role !== ProjectRole.NO_ACCESS
          ) {
            initialRequestPathname = PROJECTS_APP_URL + "/projects";
          }
        }

        if (initialRequestPathname.startsWith(window.location.origin)) {
          initialRequestPathname = initialRequestPathname.replace(
            window.location.origin,
            ""
          );
        }

        if (
          initialRequestPathname.startsWith("http://") ||
          initialRequestPathname.startsWith("https://")
        ) {
          window.location.href = initialRequestPathname;
        } else {
          navigate(initialRequestPathname);
        }
      }
    }
  }, [
    getCompanyUserData?.companies,
    error,
    dispatch,
    didRedirect,
    didInitialRender,
    navigate,
  ]);

  if (!initialRequestPathname) {
    if ((location.state as any)?.["returnTo"]) {
      initialRequestPathname = (location.state as any)?.["returnTo"];
    } else {
      initialRequestPathname = location.pathname;
    }
    initialRequestSearch = new URLSearchParams(location.search);
  }

  if (isLoadingCookie) {
    // basic message while waiting for cookie to be parsed (stored in browser, should be so quick it's barely be visible)
    return <p>Attempting authentication...</p>;
  }

  if (!user?.id) {
    // user does not exist, redirect to login
    return <Navigate to={RoutePaths.LOGIN} />;
  }

  if (!loadedCompany?.id) {
    // user exsits. if a company is not loaded, redirect to company selection
    return <Navigate to={RoutePaths.COMPANY_SELECT} />;
  }
  /**
   * handle company user roles and permissions
   * - if company was switched, wait for new roles and permissions to load (or fail loading)
   *  and then redirect user away if they don't have permissions for current page/tab
   */
  if (!loadedCompany.loadingCompanyUserRolesAndPermissions) {
    switch (determineHomeNavigationEventKey(location.pathname)) {
      case RoutePaths.USERS:
        if (
          !loadedCompany.companyUserRolesAndPermissions?.user?.permissions?.includes(
            CompanyUserPermission.MANAGE_USERS
          )
        ) {
          return <Navigate to={RoutePaths.SETTINGS_USER} />;
        }
        break;
      case RoutePaths.BILLING:
        if (
          !loadedCompany.companyUserRolesAndPermissions?.user?.permissions?.includes(
            CompanyUserPermission.BILLING_ACCESS
          )
        ) {
          if (initialRequestSearch?.get("subscriptionExpired") === "true") {
            dispatch(
              addAlert({
                uniqueKey: "subscription-expired-unique-message",
                variant: "warning",
                forRoute: RoutePaths.SETTINGS_USER,
                message: `Please contact your company administrator to get more details about access to application.`,
              })
            );
            return <Navigate to={RoutePaths.SETTINGS_USER} />;
          } else {
            return <Navigate to={RoutePaths.HOME} />;
          }
        }
        break;
      case RoutePaths.CLIENTS:
        if (
          ![CompanyUserRole.ADMINISTRATOR, CompanyUserRole.MEMBER].includes(
            loadedCompany.companyUserRolesAndPermissions?.user
              ?.role as CompanyUserRole
          )
        ) {
          return <Navigate to={RoutePaths.HOME} />;
        }
        break;
      case RoutePaths.SETTINGS_COMPANY:
        if (
          ![CompanyUserRole.ADMINISTRATOR].includes(
            loadedCompany.companyUserRolesAndPermissions?.user
              ?.role as CompanyUserRole
          )
        ) {
          return <Navigate to={RoutePaths.SETTINGS_USER} />;
        }
        break;
      default:
      // do nothing
    }
  }

  // user is authenticated and a company is loaded
  !didInitialRender && setDidInitialRender(true);

  return children;
};
