import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICountry } from "../../util/constants/countries";
import { ICurrency } from "../../util/constants/currencies";
import { clientShow, IClientVisibility } from "../../util/constants/clientVisibility";

export interface ICompanyClientSliceData {
  clientUuid?: string | null;
  name?: string | null;
  companyName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  vatNumber?: string | null;
  country?: ICountry | null;
  currency?: ICurrency | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  hidden?: IClientVisibility | null;
  //
  errors: {
    name?: string | null;
    companyName?: string | null;
    email?: string | null;
    phoneNumber?: string | null;
    vatNumber?: string | null;
    country?: string | null;
    currency?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    hidden?: boolean | null;
  };
}
export interface ICompanyClientSliceState {
  formData: ICompanyClientSliceData;
}

const defaultFormDataErrors = () => ({
  name: null,
  companyName: null,
  email: null,
  phoneNumber: null,
  vatNumber: null,
  country: null,
  currency: null,
  addressLine1: null,
  addressLine2: null,
  hidden: null,
});

export const defaultCompanyClientsSliceFormData = () => ({
  clientUuid: null,
  name: null,
  companyName: null,
  email: null,
  phoneNumber: null,
  vatNumber: null,
  country: null,
  currency: null,
  addressLine1: null,
  addressLine2: null,
  hidden: clientShow,
  //
  errors: defaultFormDataErrors(),
});

const initialState: ICompanyClientSliceState = {
  formData: defaultCompanyClientsSliceFormData(),
};

const companyClientsSlice = createSlice({
  name: "companyClients",
  initialState,
  reducers: {
    setCompanyClientForm: (
      state,
      action: PayloadAction<Omit<ICompanyClientSliceData, "errors">>
    ) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
        // errors
        errors: defaultFormDataErrors(),
      };
    },
    changeCompanyClientForm: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action: PayloadAction<ICompanyClientSliceData | { [key: string]: any }>
    ) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
        // errors
        errors: {
          ...state.formData.errors,
          // remove error from any property that was updated
          ...Object.keys(action.payload).reduce(
            (acc, curr) => ({ ...acc, [curr]: undefined }),
            {}
          ),
        },
      };
    },
    updateCompanyClientFormErrors: (state, action) => {
      state.formData = {
        ...state.formData,
        errors: action.payload,
      };
    },
  },
});

export const {
  setCompanyClientForm,
  changeCompanyClientForm,
  updateCompanyClientFormErrors,
} = companyClientsSlice.actions;

export default companyClientsSlice.reducer;
