import { useCallback, useState } from "react";

type ValidateFunction<T = never> = (
  data: T,
  field?: string
) => { valid: boolean; errors: Record<string, string> };

export default <T = never>(validateFn: ValidateFunction<T>, data: T) => {
  const [validateErrors, setValidateErrors] = useState<Record<string, string>>(
    {}
  );

  const validate = useCallback(
    (field?: string) => {
      const result = validateFn(data, field);

      if (result.valid) {
        if (field) {
          setValidateErrors((errors) => {
            const newErrors = { ...errors };
            delete newErrors[field];
            return newErrors;
          });
        } else {
          setValidateErrors({});
        }
      } else {
        if (field) {
          const error = result.errors[field];
          setValidateErrors((errors) => ({
            ...errors,
            [field]: error,
          }));
        } else {
          setValidateErrors(result.errors);
        }
      }
    },
    [data, validateFn]
  );

  return {
    validateErrors,
    validate,
  };
};
