import { Accordion, Col, Row } from "react-bootstrap";

const QA_DATA = [
  {
    question: "Do I get all these Bullet products for €49 per user per month?",
    answer:
      "Yep, we’ve built an all-in-one tool kit. All the products are included and work together.",
  },
  {
    question: "Can I cancel anytime?",
    answer:
      "Anytime. Just cancel and we won't bill you for the next monthly cycle.",
  },
  {
    question: "Will I be dealing with Chatbots on support?",
    answer: "No, we’ll never use chatbots. You’ll just speak to lovely humans.",
  },
  {
    question: "Can I add a short-term freelancer as a free guest?",
    answer:
      "Sure, Free-Guest users can be given the following access. Accounts/ Payroll - View/Comment. Projects - view/comment. Chats - view/comment. You’ll need to add users to gain full access to Bullets tools. You can cancel at any time.",
  },
  {
    question: "Where does my data sit and is it safe?",
    answer:
      "We use a tier-one hosting provider that provides a fully redundant infrastructure platform, with a team always tracking it. It’s safe to say, it’s safe. Quicknotes is a serverless technology so the documents sit on your computer, but you can publish them to the same tier one hosting provider.",
  },
  { question: "Will you sell my data?", answer: "Nope, never." },
  {
    question: "Can I try the full toolkit out first?",
    answer: "Sure, we offer a 7-day trial - signup; you've nothing to lose.",
  },
  {
    question: "Are you a fly-by-night crowd?",
    answer: "We’ve been in business since 2010 and are fully self-funded.",
  },
];

export default () => {
  return (
    <>
      <Row className="mb-4 justify-content-center">
        <Col xs="auto">
          <Row>
            <Col xs="auto">
              <span className="text-heading">Questions & answers</span>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <span>Can't find the answer here? Contact support</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <Accordion flush alwaysOpen>
            {QA_DATA.map((item, index) => (
              <Accordion.Item key={index} eventKey={`${index}`}>
                <Accordion.Header>
                  <span className="fw-bold">{item.question}</span>
                </Accordion.Header>
                <Accordion.Body>{item.answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
    </>
  );
};
