import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RoutePaths } from "../../../../routes/routes";
import { useAppSelector } from "../../../../store/redux-hooks";
import { useLazyGetCompanyUserQuery } from "../../../../store/slices/apiSlice";
import AlertHandler from "../../../common/AlertHandler";
import UserAvatars from "../../../common/avatars/UserAvatars";

import ChangeAvatar from "./ChangeAvatar";
import ChangePassword from "./ChangePassword";
import SettingsNotifications from "./SettingsNotifications";

export default () => {
  const user = useAppSelector((state) => state.auth.user);
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);

  const [getCompanyUser, { data: getCompanyUserData, isSuccess }] =
    useLazyGetCompanyUserQuery();

  const [key, setKey] = useState<number>(Date.now());
  // TODO: rework this once auth flow has been improved,
  // add a dedicated call for getting all relevant user info at initial login
  const users = useMemo(
    () =>
      isSuccess && getCompanyUserData
        ? [{ ...getCompanyUserData, name: getCompanyUserData.firstName }]
        : [],
    [getCompanyUserData, isSuccess]
  );

  const handleLoadCompanyUser = useCallback(() => {
    if (user?.id && loadedCompany?.id) {
      getCompanyUser({
        urlArgs: { companyId: loadedCompany.id, userId: user.id },
      });
    }
  }, [getCompanyUser, loadedCompany?.id, user?.id]);

  useEffect(() => {
    handleLoadCompanyUser();
  }, [handleLoadCompanyUser]);

  return (
    <Container fluid id="settings-user">
      <Row className="mb-4 align-items-center">
        <Col xs="auto" className="text-heading fw-medium">
          My Settings
        </Col>
        <Col xs="auto">
          <UserAvatars users={users} />
        </Col>
      </Row>
      <div className="mb-4 max-width-medium">
        <AlertHandler currentRoute={RoutePaths.SETTINGS_USER} />
      </div>
      <Row className="mb-3">
        <ChangeAvatar
          key={key}
          resetChangeAvatar={() => setKey(Date.now())}
          updateCompanyUser={handleLoadCompanyUser}
        />
      </Row>
      <div className="mb-4 max-width-medium">
        <hr />
      </div>
      <Row className="mb-3">
        <ChangePassword />
      </Row>
      <div className="mb-4 max-width-medium">
        <hr />
      </div>
      <Row className="mb-3">
        <SettingsNotifications />
      </Row>
    </Container>
  );
};
