export interface IClientVisibility {
    value: boolean;
    label: string;
}

export const clientShow: IClientVisibility = {
    value: false,
    label: "Show",
};
export const clientHide: IClientVisibility = {
    value: true,
    label: "Hide",
};

export const clientVisibilityDropdown: IClientVisibility[] = [
    clientShow,
    clientHide,
];