import { ICompanyBaseResponse } from "../store/slices/apiSlice";
import { ILoadedCompanySchema } from "../store/slices/companySlice";
import { companyTypesDropdown } from "./constants/companyTypes";
import { allCountries } from "./constants/countries";
import { allCurrencies } from "./constants/currencies";
import { CompanyUserRole } from "./constants/userForm/userFormConstants";
import { vatRegistrationTypesDropdown } from "./constants/vatRegistrationTypes";

/*eslint @typescript-eslint/no-explicit-any: ["off"]*/
export const getNestedObject = (
  nestedObj: any,
  pathArr: Array<string | number>
) => {
  return pathArr.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    nestedObj
  );
};

export const toggleElementInArray = <T>(array: T[] | null, element: T): T[] => {
  if (!array) {
    return [element];
  }

  if (array?.includes(element)) {
    return array.filter((item) => item !== element);
  } else {
    return (array || []).concat([element]);
  }
};

export const transformCompanyResponseToLoadedCompany = (
  /**
   * TODO: typescript does not want to play nice with making _id required here
   * These don't appear to work (when calling the function):
   *  https://bobbyhadz.com/blog/typescript-make-property-required
   *  https://pawelgrzybek.com/make-the-typescript-interface-partially-optional-required/
   */
  company: ICompanyBaseResponse
): Omit<ILoadedCompanySchema, "loadingCompanyUserRolesAndPermissions"> => ({
  ...company,
  name: company.name || "Company name missing!",
  companyType: companyTypesDropdown.find(
    (companyType) => companyType.value === company.type
  ),
  country: allCountries.find(
    (country) => country.countryCode === company.country
  ),
  isVatRegistered: vatRegistrationTypesDropdown.find(
    (vatRegistration) => vatRegistration.value === company.isVatRegistered
  ),
  currency: allCurrencies.find(
    (currency) => currency.currencyCode === company.homeCurrency
  ),
  id: company._id || "<invalid id>",
});

export const generateRoleDisplayValue = (role?: string) => {
  if (!role) {
    return "Unknown";
  }

  switch (role) {
    case CompanyUserRole.ADMINISTRATOR:
      return "Administrator";
    case CompanyUserRole.MEMBER:
      return "Member";
    case CompanyUserRole.GUEST:
      return "Guest";
    // combined other roles
    case "FULL_ACCESS":
      return "Full Access";
    case "LIMITED_ACCESS":
      return "Limited Access";
    case "VIEW_ACCESS":
      return "View/comment";
    case "GUEST_ACCESS":
      return "Guest Access";
    case "NO_ACCESS":
      return "No Access";
    default:
      return "Unknown";
  }
};

export const currencyDisplayByCode = (code: string) => {
  const currency = allCurrencies.find(
    (currency) => currency.currencyCode.toLowerCase() === code.toLowerCase()
  );

  if (currency) {
    return currency.currencySymbol || currency.currencyCode;
  } else {
    return code;
  }
};

export const determinePaginationBoxValue = (
  boxNumber: 3 | 4 | 5,
  pageCount: number,
  page: number
) => {
  if (pageCount < 7 || page <= 4) return boxNumber;

  if (page > pageCount - 3) {
    switch (boxNumber) {
      case 3:
        return pageCount - 4;
      case 4:
        return pageCount - 3;
      case 5:
        return pageCount - 2;
    }
  }

  switch (boxNumber) {
    case 3:
      return page - 1;
    case 4:
      return page;
    case 5:
      return page + 1;
  }
};

// https://stackoverflow.com/a/41491220
// https://stackoverflow.com/a/35970186
export const pickTextColorBasedOnBgColorSimpleBW = (bgColor: string) => {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
};
