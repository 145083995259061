import { Route } from "react-router-dom";

import { IApplicationRoute } from "./routes";

export const generateRoutes = (
  routes?: IApplicationRoute[],
  parentPrefix = "K"
) => {
  return (
    <>
      {routes?.map(
        ({ path, index, element, nestedRoutes, ...rest }, arrayIndex) => (
          <Route
            /**
             * We cannot use path or index from routes reliably for keys, so use custom logic.
             * Basically a string that adds array index on each recursive call
             *
             * / will be K0
             * /users will be K01 and K010 (since it's both a regular and index route)
             * /users/:id will be K011
             * /users/create will be K012
             */
            key={parentPrefix + Number(arrayIndex).toString()}
            index={index}
            path={path}
            element={element}
            {...rest}
          >
            {generateRoutes(
              nestedRoutes,
              Number(parentPrefix).toString() + Number(arrayIndex).toString()
            )}
          </Route>
        )
      )}
    </>
  );
};
