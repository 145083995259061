import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";

import cookies from "../components/config/cookies";

import { useAppDispatch } from "../store/redux-hooks";

import { setUserData } from "../store/slices/authSlice";
import { AUTH_COOKIE_NAME } from "../util/constants/constants";

export interface IAuthJWTPayload {
  id: string;
  email: string;
  iat: number;
  exp: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export default () => {
  const [isLoadingCookie, setIsLoadingCookie] = useState(true);

  const dispatch = useAppDispatch();

  const accessToken = cookies.get(AUTH_COOKIE_NAME)?.accessToken;

  useEffect(() => {
    try {
      const decodedUserToken = jwtDecode<IAuthJWTPayload>(accessToken);

      if (decodedUserToken.exp * 1000 >= Date.now()) {
        dispatch(setUserData(decodedUserToken));
      }
    } catch (e) {
      // token expired or invalid
      dispatch(setUserData(null));
    } finally {
      setIsLoadingCookie(false);
    }
  }, [accessToken, dispatch]);

  return { isLoadingCookie };
};
