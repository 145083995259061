import { SetStateAction } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ReactComponent as IconReset } from "../../../assets/icons/reset.svg";
import { CompanyUserRole } from "../../../util/constants/userForm/userFormConstants";

export interface IGetUsersFilters {
  firstNameOrEmailContains: string;
  userRoles: CompanyUserRole[];
}

export const DEFAULT_FILTERS: IGetUsersFilters = {
  firstNameOrEmailContains: "",
  userRoles: [],
};

interface UsersFiltersProps {
  queryFilters: IGetUsersFilters;
  setQueryFilters: React.Dispatch<SetStateAction<IGetUsersFilters>>;
}

const UsersFilters = ({ setQueryFilters, queryFilters }: UsersFiltersProps) => {
  return (
    <Row className="justify-content-end align-items-center mb-4">
      <Col className="mb-3">
        <Form.Group>
          <Form.Control
            className="filter-input-min-width"
            placeholder="By user name or email"
            value={queryFilters.firstNameOrEmailContains}
            onChange={({ target: { value } }) =>
              setQueryFilters((state) => ({
                ...state,
                firstNameOrEmailContains: value,
              }))
            }
          />
        </Form.Group>
      </Col>
      <Col xs="auto" className="mb-3">
        <Button
          variant="outline-dark"
          onClick={() => {
            setQueryFilters((state) => ({
              ...DEFAULT_FILTERS,
              // note: intentional exceptional behaviour, keep tab filters as they were
              userRoles: state.userRoles,
            }));
          }}
        >
          <IconReset />
        </Button>
      </Col>
    </Row>
  );
};

export default UsersFilters;
