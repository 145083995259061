import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "../routes/routes";
import { useAppSelector } from "../store/redux-hooks";

/**
 * hook redirects users who are authenticated AND have a company loaded
 *  (a company is selected), away from current page.
 * Should be used in components like select-company,
 *  user shouldn't do those actions if they're already logged in and have
 *  a selected company. (They can change company through header dropdown)
 */
export default () => {
  const user = useAppSelector((state) => state.auth.user);
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (user?.id) {
      if (loadedCompany?.id) {
        navigate(RoutePaths.HOME, { replace: true, state: location.state });
      } else {
        if (location.pathname !== RoutePaths.COMPANY_SELECT) {
          navigate(RoutePaths.COMPANY_SELECT, { state: location.state });
        }
      }
    } else {
      navigate(RoutePaths.LOGIN, { state: location.state });
    }
  }, [user, loadedCompany, location.state, location.pathname, navigate]);
};
