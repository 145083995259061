import React, { useEffect, useState } from "react";
import { IErrorAPIRequest } from "../../../errors/apiErrors";
import { processCompanyRequestErrors } from "../../../errors/companyErrors";
import { useAppDispatch } from "../../../store/redux-hooks";
import {
  ICompanyBaseResponse,
  ICreateCompanyRequest,
  IUpdateCompanyRequest,
  useCreateCompanyMutation,
  useSelectCompanyMutation,
  useUpdateCompanyMutation,
} from "../../../store/slices/apiSlice";
import { updateCompanyFormErrors } from "../../../store/slices/companySlice";
import { CompanyEditState } from "./CompanyForm";

interface UpsertCompanyMutation {
  upsertCompanyMutation: (
    obj: ICreateCompanyRequest | IUpdateCompanyRequest | void
  ) => unknown;
  data?: ICompanyBaseResponse;
  isSuccess: boolean;
  isLoading: boolean;
  requestErrors?: IErrorAPIRequest;
  setRequestErrors: React.Dispatch<
    React.SetStateAction<IErrorAPIRequest | undefined>
  >;
}

const useUpsertCompanyMutation = (
  editState: CompanyEditState | undefined
): UpsertCompanyMutation => {
  const dispatch = useAppDispatch();

  const [requestErrors, setRequestErrors] = useState<IErrorAPIRequest>();

  const [
    createCompany,
    {
      data: createCompanyData,
      isSuccess: createCompanyIsSuccess,
      isLoading: createCompanyIsLoading,
      error: companyCreateError,
    },
  ] = useCreateCompanyMutation();

  const [
    selectCompany,
    { isSuccess: selectCompanyIsSuccess, isLoading: selectCompanyIsLoading },
  ] = useSelectCompanyMutation();

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processCompanyRequestErrors(companyCreateError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateCompanyFormErrors(formValidationErrors));
  }, [companyCreateError, dispatch]);

  const [
    updateCompany,
    {
      data: updateCompanyData,
      isSuccess: updateCompanyIsSuccess,
      isLoading: updateCompanyIsLoading,
      error: companyUpdateError,
    },
  ] = useUpdateCompanyMutation();

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processCompanyRequestErrors(companyUpdateError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateCompanyFormErrors(formValidationErrors));
  }, [companyUpdateError, dispatch]);

  switch (editState) {
    case CompanyEditState.CREATE:
    case CompanyEditState.SIGN_UP_CREATE:
      return {
        upsertCompanyMutation: async (obj) => {
          const company = await createCompany(
            obj as ICreateCompanyRequest
          ).unwrap();
          await selectCompany({
            body: {
              companyId: company._id as string,
            },
          }).unwrap();
        },
        data: createCompanyData,
        isSuccess: createCompanyIsSuccess && selectCompanyIsSuccess,
        isLoading: createCompanyIsLoading || selectCompanyIsLoading,
        requestErrors,
        setRequestErrors,
      };
    case CompanyEditState.EDIT:
      return {
        upsertCompanyMutation: (obj) =>
          updateCompany(obj as IUpdateCompanyRequest),
        data: updateCompanyData,
        isSuccess: updateCompanyIsSuccess,
        isLoading: updateCompanyIsLoading,
        requestErrors,
        setRequestErrors,
      };
    default:
      return {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        upsertCompanyMutation: () => {},
        isSuccess: false,
        isLoading: false,
        requestErrors,
        setRequestErrors,
      };
  }
};

export default useUpsertCompanyMutation;
