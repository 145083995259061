import { SortingState } from "@tanstack/react-table";

export const AUTH_COOKIE_NAME =
  process.env.REACT_APP_COOKIE_NAME || "auth-cookie";
export const PROJECTS_APP_URL = process.env.REACT_APP_PROJECTS_APP_URL || "";

export  const ONBOARDING_PROPOSAL_URL = process.env.REACT_APP_ONBOARDING_PROPOSAL_URL || "";

export const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || "";

export const REMOVE_USER_FROM_COMPANY_CONFIRMATION_KEYWORD = "remove";
export const REMOVE_CLIENT_FROM_COMPANY_CONFIRMATION_KEYWORD = "remove";
export const CANCEL_SUBSCRIPTION_CONFIRMATION_KEYWORD = "cancel";

// https://reactrouter.com/docs/en/v6/hooks/use-navigate
export const NAVIGATE_GO_BACK = -1;

export const VALID_PASSWORD_REGEX =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^a-zA-Z0-9\s]).+$/;
export const VALID_EMAIL_REGEX =
  /^[A-Za-z0-9_\-./#&+]+@(?!.*\.{2})[A-Za-z0-9_\-./#&+]+\.[a-zA-Z]{2,15}$/;

export const MAIN_PASSWORD_FIELD_PLACEHOLDER_TEXT =
  "8 long + 1 Upp/Lowercase + Digit + Special Character";

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export const MILISECONDS_IN_DAY = 86400000;

export const SELECT_COMPANY_RESPONSE_MESSAGE_SUCCESS = "Success!!";

export enum STRIPE_SUBSCRIPTION_STATUS {
  TRIALING = "trialing",
  INCOMPLETE_ = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  ACTIVE = "active",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
}

export const DEFAULT_REACT_TABLE_PAGESIZE_OPTIONS = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
];

export interface IDefaultSortAndPagination {
  pageIndex: number;
  pageSize: number;
  sorting: SortingState;
}

export const DEFAULT_SORT_AND_PAGINATION: IDefaultSortAndPagination = {
  pageIndex: 0,
  pageSize: 10,
  sorting: [],
};

export const HYGGE_COLOR_PALETTE_HEX_ARRAY = [
  "#0F0F0F",
  "#DEC2B4",
  "#F1E0D9",
  "#BC7252",
  "#ABA787",
  "#405552",
  "#5C6F6E",
  "#C49952",
  "#9D8E83",
  "#9E673E",
  "#243A40",
  "#867F6C",
  "#C89382",
  "#939696",
  "#B3BBB1",
  "#A37646",
  "#353D4B",
  "#D7B5B2",
  "#7B494F",
  "#EDE5DA",
  "#A8856E",
  "#6E4439",
  "#222C2F",
  "#ECDFC8",
  "#CE9951",
  "#5E6671",
  "#B29574",
  "#A06C5B",
  "#C3AA91",
  "#26211B",
];

export enum StripePaymentCardDeclineCode {
  AUTHENTICATION_REQUIRED = "authentication_required",
  APPROVE_WITH_ID = "approve_with_id",
  CALL_ISSUER = "call_issuer",
  ISSUER_NOT_AVAILABLE = "issuer_not_available",
  GENERIC_DECLINE = "generic_decline",
  NO_ACTION_TAKEN = "no_action_taken",
  NOT_PERMITTED = "not_permitted",
  REVOCATION_OF_ALL_AUTHORIZATIONS = "revocation_of_all_authorizations",
  REVOCATION_OF_AUTHORIZATION = "revocation_of_authorization",
  SECURITY_VIOLATION = "security_violation",
  SERVICE_NOT_ALLOWED = "service_not_allowed",
  STOP_PAYMENT_ORDER = "stop_payment_order",
  TRANSACTION_NOT_ALLOWED = "transaction_not_allowed",
  CARD_NOT_SUPPORTED = "card_not_supported",
  CARD_VELOCITY_EXCEEDED = "card_velocity_exceeded",
  INSUFFICIENT_FUNDS = "insufficient_funds",
  WITHDRAWAL_COUNT_LIMIT_EXCEEDED = "withdrawal_count_limit_exceeded",
  CURRENCY_NOT_SUPPORTED = "currency_not_supported",
  DUPLICATE_TRANSACTION = "duplicate_transaction",
  EXPIRED_CARD = "expired_card",
  FRAUDULENT = "fraudulent",
  LOST_CARD = "lost_card",
  PICKUP_CARD = "pickup_card",
  RESTRICTED_CARD = "restricted_card",
  STOLEN_CARD = "stolen_card",
  PROCESSING_ERROR = "processing_error",
  REENTER_TRANSACTION = "reenter_transaction",
  TRY_AGAIN_LATER = "try_again_later",
  INCORRECT_NUMBER = "incorrect_number",
  INCORRECT_CVC = "incorrect_cvc",
  INCORRECT_PIN = "incorrect_pin",
  INCORRECT_ZIP = "incorrect_zip",
  INVALID_CVC = "invalid_cvc",
  INVALID_EXPIRY_MONTH = "invalid_expiry_month",
  INVALID_EXPIRY_YEAR = "invalid_expiry_year",
  INVALID_NUMBER = "invalid_number",
  INVALID_ACCOUNT = "invalid_account",
  NEW_ACCOUNT_INFORMATION_AVAILABLE = "new_account_information_available",
}

export enum StripePaymentCardDeclineCodeMessage {
  AUTHENTICATION_REQUIRED = "Your card was declined as the transaction requires authentication. Please try again and authenticate your card when prompted during the transaction. Your bank might use 3D Secure.",
  APPROVE_WITH_ID = "Payment can’t be authorized. Please try again or contact your card issuer for more information.",
  CARD_DECLINED = "Card declined. Please try again, if it’s declined again please call your bank for more information as Bullet can’t access your bank on your behalf.",
  CARD_NOT_SUPPORTED = "The card does not support this type of purchase. Please contact your bank for more information as Bullet can’t access your bank on your behalf.",
  INSUFFICIENT_FUNDS = "No sufficient funds. Please use an alternative payment method.",
  CURRENCY_NOT_SUPPORTED = "The card does not support the currency of payment. We bill in Euros. ",
  DUPLICATE_TRANSACTION = "Identical transaction recently submitted. Refresh the page to check if your subscription is activated.",
  EXPIRED_CARD = "The card has expired, check the exp date. Please use another card.",
  PAYMENT_UNSUCCESSFUL = "Payment unsuccessful. Please try again later or with a different payment method",
  INCORRECT_CARD_DATA = "Card data is incorrect. Please check the card data again, the data you’ve entered doesn’t match the card. Contact your bank for more information.",
}
