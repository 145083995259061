import { createColumnHelper } from "@tanstack/react-table";

import { IGetCompanyClientBaseResponse } from "../../../store/slices/apiSlice";
import { allCountries } from "../../../util/constants/countries";

const columnHelper = createColumnHelper<IGetCompanyClientBaseResponse>();

export const clientsTableColumns = [
  columnHelper.accessor("name", {
    header: "Client Contact Name",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("companyName", {
    header: "Business name",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("email", {
    header: "Email",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("phoneNumber", {
    header: "Phone",
    enableSorting: false,
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor(
    (row) =>
      allCountries.find((country) => country.countryCode === row.country)
        ?.countryName,
    {
      header: "Country",
      enableSorting: false,
      cell: (info) => info.getValue(),
    }
  ),
  columnHelper.accessor("vatNumber", {
    header: "VAT number",
    enableSorting: false,
    cell: (info) => info.getValue(),
  }),
];
