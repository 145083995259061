import { useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";

import { useNavigate, useSearchParams } from "react-router-dom";
import { RoutePaths } from "../../../routes/routes";
import { useAppDispatch } from "../../../store/redux-hooks";

import { apiSlice, useLogoutMutation } from "../../../store/slices/apiSlice";
import { resetStore } from "../../../store/store";

export default () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [logout, { isSuccess, isUninitialized }] = useLogoutMutation();

  useEffect(() => {
    if (isUninitialized) {
      logout();
    }
  }, [isUninitialized, logout]);

  useEffect(() => {
    const getNavigateState = () => {
      const hasAccountsSessionExpiredParam = searchParams.get(
        "accounts-session-expired"
      );

      if (hasAccountsSessionExpiredParam) {
        return {
          returnTo: searchParams.get("returnTo"),
          alert: {
            variant: "warning",
            content:
              "Your session on Accounts&Payroll has expired. Please relogin to get an access to the Accounts&Payroll application",
          },
          email: decodeURIComponent(searchParams.get("email") || ""),
        };
      }

      return { returnTo: searchParams.get("returnTo") };
    };

    if (isSuccess) {
      // reset api slice (cleares cache and query/mutation statuses)
      dispatch(apiSlice.util.resetApiState());
      // reset whole store and it's slices to their initial states
      dispatch(resetStore());
      // navigate user back to login
      navigate(RoutePaths.LOGIN, {
        replace: true,
        state: getNavigateState(),
      });
    }
  }, [isSuccess, searchParams, dispatch, navigate]);

  return (
    <Container fluid className="height-100 px-0">
      <Row className=" align-items-center justify-content-center h-100">
        <Col xs="auto">
          <Row className="mb-3">Logging out...</Row>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
