import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { RoutePaths } from "../../../routes/routes";

import { useAppDispatch, useAppSelector } from "../../../store/redux-hooks";
import {
  updateLoginForm,
  updateLoginFormErrors,
} from "../../../store/slices/authSlice";
import { useEffect, useState } from "react";
import { getNestedObject } from "../../../util/functions";
import { useLoginMutation } from "../../../store/slices/apiSlice";
import { processAuthRequestErrors } from "../../../errors/authErrors";
import useAuthenticatedUserRedirect from "../../../hooks/useAuthenticatedUserRedirect";
import { IErrorAPIRequest } from "../../../errors/apiErrors";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { IAuthLocationState } from "../../../util/constants/types/tsConstants";
import ReactGA from "../../../reactGA";

export default () => {
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const location = useLocation();

  const [searchParams] = useSearchParams();

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useAuthenticatedUserRedirect();

  const navigate = useNavigate();

  const [requestErrors, setRequestErrors] = useState<IErrorAPIRequest>({});

  const [login, { isSuccess, isLoading, error: loginError }] =
    useLoginMutation();

  useEffect(() => {
    if (isSuccess) {
      ReactGA.event({
        action: "form_submission",
        label: "hub-login-success",
        category: "hub-login",
      });

      navigate(RoutePaths.HOME, {
        state: {
          returnTo:
            (location.state as IAuthLocationState)?.returnTo ??
            searchParams.get("returnTo"),
        },
      });
    }
  }, [isSuccess, location.state, searchParams, navigate, dispatch]);

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processAuthRequestErrors(loginError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateLoginFormErrors(formValidationErrors));
  }, [loginError, dispatch]);

  useEffect(() => {
    const emailFromLocationState = getNestedObject(location, [
      "state",
      "email",
    ]);

    emailFromLocationState &&
      !authState.loginData.email &&
      !authState.loginData.hasUserModifiedData &&
      dispatch(
        updateLoginForm({
          email: emailFromLocationState,
        })
      );
  }, [
    dispatch,
    location,
    authState.loginData.email,
    authState.loginData.hasUserModifiedData,
  ]);

  return (
    <Container fluid className="height-100 px-0 authForms" id="loginForm">
      <Row className="max-width-560px mx-auto my-auto align-items-center height-100">
        <Card body border="light" className="px-0 px-sm-5 py-5">
          <Form>
            <Col>
              <Row className="justify-content-center mb-4">
                <span className="w-auto text-heading">Login</span>
              </Row>
              <Row>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    tabIndex={1}
                    value={authState.loginData.email}
                    onChange={({ target: { value } }) =>
                      dispatch(updateLoginForm({ email: value }))
                    }
                    isInvalid={Boolean(authState.loginData.errors?.email)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {authState.loginData.errors?.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className="mb-1" controlId="formBasicPassword">
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      tabIndex={2}
                      value={authState.loginData.password}
                      onChange={({ target: { value } }) =>
                        dispatch(updateLoginForm({ password: value }))
                      }
                      isInvalid={Boolean(authState.loginData.errors?.password)}
                    />
                    <Button
                      variant="outline-secondary"
                      id="toggle-password"
                      type="button"
                      // https://github.com/twbs/bootstrap/issues/36049
                      className="border-radius-right-8px"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? (
                        <Eye size="1.7em" />
                      ) : (
                        <EyeSlash size="1.7em" />
                      )}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      {authState.loginData.errors?.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row className="justify-content-between mb-5" id="forgotPassword">
                <Form.Text className="width-fit-content custom-form-text">
                  Not From Ireland?{" "}
                  <Link
                    to={""}
                    onClick={() =>
                      (window.location.href =
                        "https://accounts-app-intl.bullethq.com/users/login.page")
                    }
                  >
                    Login Here
                  </Link>
                </Form.Text>
                <Form.Text className="width-fit-content custom-form-text">
                  <Link
                    to={RoutePaths.RESET_PASSWORD}
                    state={{ email: authState.loginData.email }}
                    tabIndex={4}
                  >
                    Forgot Password?
                  </Link>
                </Form.Text>
              </Row>
              <Row className="justify-content-center mb-5">
                <Button
                  variant="dark"
                  type="submit"
                  className="w-auto"
                  tabIndex={3}
                  disabled={isLoading}
                  onClick={(event) => {
                    event.preventDefault();

                    login({
                      email: authState.loginData.email,
                      password: authState.loginData.password,
                    });
                  }}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Login"
                  )}
                </Button>
              </Row>
              {requestErrors?.message && (
                <Row>
                  <Alert variant="danger">{requestErrors?.message}</Alert>
                </Row>
              )}
              {
                // show alert initially on page display, until user attempts login (at which point they're redirected, or another error is displayed)
                (location.state as IAuthLocationState)?.alert &&
                  !Object.keys(requestErrors).length && (
                    <Row>
                      <Alert
                        variant={
                          (location.state as IAuthLocationState)?.alert?.variant
                        }
                      >
                        {(location.state as IAuthLocationState)?.alert?.content}
                      </Alert>
                    </Row>
                  )
              }
              <Row className="mb-2">
                <Col>
                  <hr />
                </Col>
              </Row>
              <Row className="justify-content-center custom-text-tiny">
                <Col xs="auto">
                  {"Don't have an account? "}
                  <Link
                    to={RoutePaths.SIGNUP}
                    state={{ email: authState.loginData.email }}
                    tabIndex={6}
                  >
                    Sign Up
                  </Link>
                </Col>
              </Row>
            </Col>
          </Form>
        </Card>
      </Row>
    </Container>
  );
};
