import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";

import BulletReviewBox from "./BulletReviewBox";

export default () => {
  return (
    <Container
      fluid
      className="height-100 pl-sm-5 pr-0 authForms"
      id="signUpForm"
    >
      <Row className="h-100 pl-1 pl-sm-5 pt-5 flex-row">
        <Col xs={12} lg={8} className="mb-4">
          <Row className="justify-content-center h-100">
            <Outlet />
          </Row>
        </Col>
        <Col xs={12} lg={4} className="h-auto h-sm-100 px-0">
          <BulletReviewBox />
        </Col>
      </Row>
    </Container>
  );
};
