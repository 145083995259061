import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import {
  IErrorData,
  IErrorAPIRequest,
  APIResponseExtraAction,
} from "./apiErrors";

export const processCompanyRequestErrors = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  let formValidationErrors = {};
  let apiRequestErrors: IErrorAPIRequest = {};

  if (error && "status" in error) {
    switch (error.status) {
      case "CUSTOM_ERROR":
      // fallsthrough
      case "PARSING_ERROR":
      // fallsthrough
      case "FETCH_ERROR":
        apiRequestErrors = {
          ...apiRequestErrors,
          message: "Something went wrong. Please try again later.",
        };
        break;
      default:
      // status is number, handle that in next IF statement
    }
  }

  if (error && "data" in error) {
    const data = error.data as IErrorData;

    if (Array.isArray(data?.message)) {
      data.message.forEach((errorMessage: string) => {
        /**
         * Order errors by priority
         * First one will be triggered and displayed.
         * Put specific errors on top, and generic errors on bottom
         */
        switch (errorMessage) {
          /**
           * addressLine1
           */
          case "addressLine1 must be a string":
          case "addressLine1 should not be empty":
            formValidationErrors = {
              ...formValidationErrors,
              addressLine1: "Address line 1 is required",
            };
            break;
          /**
           * company number
           */
          case "companyNumber must be a string":
          case "companyNumber should not be empty":
          case "companyNumber is required for IE":
            formValidationErrors = {
              ...formValidationErrors,
              companyNumber: "Company number is required for Ireland",
            };
            break;

          case "companyNumber is invalid":
            formValidationErrors = {
              ...formValidationErrors,
              companyNumber: "Company number should have only numbers",
            };
            break;
          /**
           * currency
           */
          case "homeCurrency must be a string":
          case "homeCurrency should not be empty":
            formValidationErrors = {
              ...formValidationErrors,
              currency: "Currency is required",
            };
            break;
          /**
           * name
           */
          case "name must be a string":
          case "name should not be empty":
            formValidationErrors = {
              ...formValidationErrors,
              name: "Company name is required",
            };
            break;
          /**
           * phone number
           */
          case "phoneNumber must be a string":
          case "phoneNumber should not be empty":
            formValidationErrors = {
              ...formValidationErrors,
              phoneNumber: "Phone number is required",
            };
            break;
          /**
           * country
           */
          case "country must be a string":
            formValidationErrors = {
              ...formValidationErrors,
              country: "Country is required",
            };
            break;
          /**
           * tax number
           */
          case "taxNumber is required for IE":
            formValidationErrors = {
              ...formValidationErrors,
              taxNumber: "Tax number is required for Ireland",
            };
            break;
          case "taxNumber is invalid":
            formValidationErrors = {
              ...formValidationErrors,
              taxNumber: "Tax number is invalid",
            };
            break;
          /**
           * vat number
           */
          case "vatNumber is required":
            formValidationErrors = {
              ...formValidationErrors,
              vatNumber: " number is required",
            };
            break;
          case "vatNumber should be identical to taxNumber":
            formValidationErrors = {
              ...formValidationErrors,
              vatNumber: " number has to be the same as Tax number",
            };
            break;
          case "vatNumber is invalid":
            formValidationErrors = {
              ...formValidationErrors,
              vatNumber: " number is invalid",
            };
            break;
          default:
        }
      });
    } else {
      // it's a string
      switch (data.message) {
        case "Invalid credentials. Please try again.":
          apiRequestErrors = {
            ...apiRequestErrors,
            message: "Invalid credentials. Please login again.",
          };
          break;
        case "Unauthorized":
          apiRequestErrors = {
            ...apiRequestErrors,
            message: "Unauthorized",
            extra: {
              action: APIResponseExtraAction.UNAUTHORIZED_REDIRECT,
            },
          };
          break;
        default:
          /**
           * Can be message like:
           *   "Cannot POST /api/something-that-doesnt-exist"
           *   "Internal server error"
           */
          apiRequestErrors = {
            ...apiRequestErrors,
            message: "Something went wrong. Please try again later.",
          };
      }
    }
  }

  return { formValidationErrors, authRequestErrors: apiRequestErrors };
};
