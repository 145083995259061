import { Spinner } from "react-bootstrap";

/**
 * TODO: rework this to either use React context or (most preferably) redux store.
 */

export const showGlobalLoader = () => {
  const el = document.getElementById("global-loader");
  if (el) {
    el.style.display = "flex";
  }
};

export const hideGlobalLoader = () => {
  const el = document.getElementById("global-loader");
  if (el) {
    el.style.display = "none";
  }
};

const GlobalLoader = () => {
  return (
    <div
      id="global-loader"
      className="vw-100 vh-100 position-fixed top-0 start-0 justify-content-center align-items-center"
      style={{
        display: "none",
        background: "rgba(80, 82, 86, 0.4)",
        zIndex: 1040,
      }}
    >
      <Spinner />
    </div>
  );
};

export default GlobalLoader;
