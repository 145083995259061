import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../routes/routes";

export default () => {
  const navigate = useNavigate();

  return (
    <Row
      id="internal-server-content"
      className="flex-column align-items-center justify-content-center h-100"
    >
      <Col xs="auto">
        <img alt="internal server error" src="/_images/internalServer.png" />
      </Col>
      <Col xs="auto">
        <span className="title text-heading fw-medium">
          Sorry, It’s not you. It’s us.
        </span>
      </Col>
      <Col xs="auto">
        <p className="description info text-paragraph fw-normal text-center">
          We are fixing the problem. Please try again at a later stage.
        </p>
      </Col>
      <Col xs="auto">
        <Button
          variant="primary"
          onClick={() => navigate(RoutePaths.HOME, { replace: true })}
        >
          Go Home
        </Button>
      </Col>
    </Row>
  );
};
