import Avatar from "react-avatar";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { PersonLinesFill } from "react-bootstrap-icons";
import { HYGGE_COLOR_PALETTE_HEX_ARRAY } from "../../../util/constants/constants";
import { pickTextColorBasedOnBgColorSimpleBW } from "../../../util/functions";

const UserAvatars = ({
  size = 40,
  users = [],
  maxAvatars = 3,
  hideRemainingUsersIcon = false,
}: {
  size?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  users?: { name?: string; avatar?: string; [key: string]: any }[];
  maxAvatars?: number;
  hideRemainingUsersIcon?: boolean;
}) => {
  return (
    <Container fluid>
      <Row className="flex-row-reverse align-items-center">
        {users.length > maxAvatars && !hideRemainingUsersIcon && (
          <Col
            xs="auto"
            className="px-0"
            style={{ marginLeft: `-${size / 6}px` }}
          >
            <OverlayTrigger
              rootClose
              key={"bottom"}
              placement={"bottom"}
              trigger={["hover"]}
              overlay={
                <Tooltip id={`tooltip-remaining-users`}>
                  <>
                    {users.slice(maxAvatars).map((user) => (
                      <div key={user._id}>{user.name}</div>
                    ))}
                  </>
                </Tooltip>
              }
            >
              <PersonLinesFill size={size} />
            </OverlayTrigger>
          </Col>
        )}
        {users
          .slice(0, maxAvatars)
          .reverse()
          .map((user, index) => {
            /**
             * hygge color palette has too bright colors for react-avatar's default white text color
             *  to be clearly visible in some cases
             * For brighter background colors, use dark text color for contrast instead
             *
             * Note2: Avatar.getRandomColor - it's exported and in documentation, but is not in typings
             */
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const bgColor = Avatar.getRandomColor(
              user.name || "",
              HYGGE_COLOR_PALETTE_HEX_ARRAY
            );
            const textColor = pickTextColorBasedOnBgColorSimpleBW(bgColor);

            return (
              <Col
                key={index}
                xs="auto"
                className="px-0"
                style={{
                  marginLeft:
                    index === Math.min(users.length, maxAvatars) - 1
                      ? undefined
                      : `-${size / 3}px`,
                }}
              >
                <OverlayTrigger
                  rootClose
                  key={index}
                  placement={"bottom"}
                  trigger={["hover", "focus"]}
                  overlay={
                    <Tooltip id={`tooltip-${index}`}>
                      <span>{user.name || "Unkown"}</span>
                    </Tooltip>
                  }
                >
                  <div>
                    <Avatar // react-avatar, not polydone custom avatar
                      className="avatars-single"
                      round={true}
                      size={String(size)}
                      name={user.name || ""}
                      src={user.avatar}
                      title={false}
                      color={bgColor}
                      fgColor={textColor}
                    />
                  </div>
                </OverlayTrigger>
              </Col>
            );
          })}
      </Row>
    </Container>
  );
};

export default UserAvatars;
