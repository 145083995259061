import { useEffect } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { PeopleFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../routes/routes";
import { useAppSelector } from "../../../store/redux-hooks";
import { useLazyGetCompanyQuery } from "../../../store/slices/apiSlice";

export default () => {
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);

  const navigate = useNavigate();

  const [getCompany, { data: company, isFetching }] = useLazyGetCompanyQuery();

  useEffect(() => {
    if (loadedCompany?.id) {
      getCompany({ urlArgs: { companyId: loadedCompany?.id } });
    }
  }, [loadedCompany?.id, getCompany]);

  return (
    <Container fluid id="paymentCardDetails" className="px-0">
      <Row className="mb-3">
        <Col className="text-subheader">Subscription Details</Col>
      </Row>
      {isFetching ? (
        <Row className="justify-content-center my-5">
          <Col xs="auto">
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </Col>
        </Row>
      ) : (
        <>
          <Row className="mb-2 align-items-center">
            <Col xs="auto"> Paid Users in Company:</Col>
            <Col xs="auto" className="fw-medium px-0">
              <Button
                variant="secondary"
                onClick={() => navigate(RoutePaths.USERS)}
              >
                <Row>
                  <Col xs="auto" className="px-1">
                    <span className="fw-bold">
                      {company?.subscriptionChargedUsersCount ?? "Unknown"}
                    </span>
                  </Col>
                  <Col xs="auto" className="px-1">
                    <PeopleFill size="1.5em" />
                  </Col>
                </Row>
              </Button>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs="auto" className="fw-medium">
              Total Subscription Amount:
              <span style={{ color: "red" }}>* </span>
              {company?.subscriptionAmount ? (
                <>
                  €
                  <span className="fw-bold">
                    {Number(company.subscriptionAmount).toFixed(2)}
                  </span>
                </>
              ) : (
                <span className="fw-bold">Unknown</span>
              )}
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs="auto" className="text-tiny">
          <span style={{ color: "red" }}>*</span> Subscription amount is updated
          when new users are added or removed. The invoice amount for the next
          month will include a prorated amount for the days users were
          registered in the system.
        </Col>
      </Row>
    </Container>
  );
};
