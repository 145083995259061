import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { ReactComponent as IconSearch } from "../../../assets/icons/search.svg";

import { RoutePaths } from "../../../routes/routes";
import { useAppSelector } from "../../../store/redux-hooks";
import { useLazyGetCompanyClientsQuery } from "../../../store/slices/apiSlice";
import {
  DEFAULT_SORT_AND_PAGINATION,
  IDefaultSortAndPagination,
  Order,
} from "../../../util/constants/constants";
import {
  AccountsAndPayrollPermission,
  AccountsAndPayrollRole,
  ProposalRole,
} from "../../../util/constants/userForm/userFormConstants";
import AlertHandler from "../../common/AlertHandler";
import ReactTable from "../../common/table/ReactTable";
import ClientsFilters, {
  DEFAULT_FILTERS,
  IGetClientsFilters,
} from "./ClientsFilters";

import { clientsTableColumns } from "./ClientsTableColumns";

export default () => {
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);
  const navigate = useNavigate();

  const loadedCompanyUserRolesAndPermissions =
    loadedCompany?.companyUserRolesAndPermissions;

  const hasPermissionToCreateOrUpdateClients = useMemo(() => {
    const canAccessAccountsAndPayrollInvoices =
      loadedCompanyUserRolesAndPermissions?.accountsAndPayroll?.role ===
        AccountsAndPayrollRole.FULL_ACCESS ||
      (loadedCompanyUserRolesAndPermissions?.accountsAndPayroll?.role ===
        AccountsAndPayrollRole.LIMITED_ACCESS &&
        loadedCompanyUserRolesAndPermissions?.accountsAndPayroll?.permissions?.includes(
          AccountsAndPayrollPermission.SALES_ACCESS
        ));

    return (
      loadedCompanyUserRolesAndPermissions?.proposal?.role ===
        ProposalRole.FULL_ACCESS || canAccessAccountsAndPayrollInvoices
    );
  }, [loadedCompanyUserRolesAndPermissions]);

  const [showFilters, setShowFilters] = useState(false);

  const [queryFilters, setQueryFilters] =
    useState<IGetClientsFilters>(DEFAULT_FILTERS);

  const [querySortAndPagination, setQuerySortAndPagination] =
    useState<IDefaultSortAndPagination>(DEFAULT_SORT_AND_PAGINATION);

  const [getCompanyClients, { data, isFetching }] =
    useLazyGetCompanyClientsQuery();

  useEffect(() => {
    if (loadedCompany) {
      const params = {
        ...(queryFilters.clientNameOrEmailContains && {
          filters: {
            clientNameOrEmailContains: queryFilters.clientNameOrEmailContains,
          },
        }),
        ...(querySortAndPagination.pageIndex && {
          skip:
            querySortAndPagination.pageIndex * querySortAndPagination.pageSize,
        }),
        ...(querySortAndPagination.pageSize && {
          limit: querySortAndPagination.pageSize,
        }),
        ...(querySortAndPagination?.sorting?.[0] && {
          sort: [
            {
              column: querySortAndPagination.sorting[0].id,
              order: querySortAndPagination.sorting[0].desc
                ? Order.DESC
                : Order.ASC,
            },
          ],
        }),
      };
      getCompanyClients({
        urlArgs: { companyId: loadedCompany.id },
        params,
      });
    }
  }, [loadedCompany, getCompanyClients, querySortAndPagination, queryFilters]);

  const onChange = useCallback(
    ({ pageIndex, pageSize, sorting }: IDefaultSortAndPagination) => {
      setQuerySortAndPagination({ pageIndex, pageSize, sorting });
    },
    [setQuerySortAndPagination]
  );

  return (
    <Container fluid id="clients">
      <Row className="mb-4 justify-content-between">
        <Col xs="auto" className="text-heading fw-medium">
          Clients
        </Col>
        <Col xs="auto">
          <Row className="h-100 align-items-center">
            <Col xs="auto">
              <Button
                variant="outline-dark"
                onClick={(e) => {
                  setShowFilters(!showFilters);
                }}
              >
                <IconSearch />
              </Button>
            </Col>
            <Col xs="auto" className="my-auto">
              <Button
                onClick={() => navigate(RoutePaths.CLIENTS_CREATE)}
                disabled={!hasPermissionToCreateOrUpdateClients}
              >
                Create Client
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <AlertHandler currentRoute={RoutePaths.CLIENTS} />
      {showFilters && (
        <Row>
          <Col>
            <ClientsFilters
              queryFilters={queryFilters}
              setQueryFilters={setQueryFilters}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <ReactTable
            data={data?.result || []}
            totalRows={data?.total || 0}
            loading={isFetching}
            columns={clientsTableColumns}
            filters={queryFilters}
            onChange={onChange}
            onRowClick={(data) => {
              hasPermissionToCreateOrUpdateClients &&
                navigate(RoutePaths.CLIENT.replace(":id", data.uuid));
            }}
            enablePagination
            enableSorting
          />
        </Col>
      </Row>
    </Container>
  );
};
