import { useAppSelector } from "../../../store/redux-hooks";
import { MILISECONDS_IN_DAY } from "../../../util/constants/constants";

export default () => {
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);

  if (loadedCompany?.trialExpirationDate) {
    const trialRemainingMS =
      new Date(loadedCompany.trialExpirationDate).getTime() -
      new Date().getTime();

    if (trialRemainingMS > MILISECONDS_IN_DAY) {
      return (
        <span className="color-brick-orange">
          <span
            className="text-no-wrap fw-bold"
            title={new Date(loadedCompany.trialExpirationDate).toLocaleString()}
          >
            Expires in {Math.ceil(trialRemainingMS / MILISECONDS_IN_DAY)} days
          </span>
        </span>
      );
    }

    if (trialRemainingMS <= MILISECONDS_IN_DAY && trialRemainingMS > 0) {
      return (
        <span className="color-brick-orange">
          <span
            className="text-no-wrap fw-bold"
            title={new Date(loadedCompany.trialExpirationDate).toLocaleString()}
          >
            Expires in less than a day
          </span>
        </span>
      );
    }

    if (trialRemainingMS <= 0) {
      return (
        <span className="color-brick-orange">
          <span
            className="text-no-wrap fw-bold"
            title={new Date(loadedCompany.trialExpirationDate).toLocaleString()}
          >
            Trial period has expired
          </span>
        </span>
      );
    }
  }

  return <span className="color-brick-orange">Trial expiration unknown</span>;
};
