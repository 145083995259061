import React, { useEffect, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  RowData,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import ReactTablePagination from "./ReactTablePagination";

// https://tanstack.com/table/v8/docs/api/core/column-def#meta
// https://github.com/TanStack/table/discussions/4072
declare module "@tanstack/table-core" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    cellClickable: boolean;
  }
}

const ReactTable = <T,>({
  data,
  totalRows,
  loading,
  columns,
  filters,
  onChange,
  onRowClick,
  enablePagination = false,
  enableSorting = false,
}: {
  data: T[];
  totalRows: number;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters?: any;
  onChange?: (obj: {
    pageIndex: number;
    pageSize: number;
    sorting: SortingState;
  }) => void;
  onRowClick?: (row: T) => void;
  //
  enablePagination?: boolean;
  enableSorting?: boolean;
}) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const pageCount = React.useMemo(() => {
    return Math.ceil((totalRows || 0) / pageSize);
  }, [totalRows, pageSize]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    /**
     * reset page index back to 0 (1 for user) when changing sort, page size or filters
     */
    setPagination((state) => ({ ...state, pageIndex: 0 }));
  }, [pageSize, sorting, filters]);

  useEffect(() => {
    onChange && onChange({ pageIndex, pageSize, sorting });
  }, [onChange, pageIndex, pageSize, sorting]);

  const table = useReactTable<T>({
    columns: columns,
    data,
    pageCount,
    state: { sorting, pagination },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    //
    manualPagination: true,
    onPaginationChange: setPagination,
    //
    enableSorting: enableSorting,
    manualSorting: true,
    // TODO: fix or change after: https://github.com/TanStack/table/issues/4289
    sortDescFirst: true,
    onSortingChange: setSorting,
  });

  return (
    <div>
      <div className="react-table">
        <table>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: `${
                              header.column.getCanSort() ? "cursor-pointer" : ""
                            } ${
                              header.column.getIsSorted()
                                ? "header-text-sorted"
                                : ""
                            }`,
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          <div className="th-sort">
                            {{
                              asc: (
                                <div className="react-table-sort react-table-sort-asc" />
                              ),
                              desc: (
                                <div className="react-table-sort react-table-sort-desc" />
                              ),
                              false: header.column.getCanSort() ? (
                                <div className="react-table-sort react-table-sort-neutral" />
                              ) : null,
                              // note: left for reference
                              // asc: <ChevronUp color="#4E5D78" size="1rem" />,
                              // desc: <ChevronDown color="#4E5D78" size="1rem" />,
                              // false: header.column.getCanSort() ? (
                              //   // <ChevronExpand color="#4E5D78" size="1rem" />
                              //   <ChevronDown color="#4E5D78" size="1rem" />
                              // ) : null,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td
                  colSpan={999}
                  className="loading-data"
                  /** TODO: replace with skeleton loaders in the future?
                   * 181px is space taken by 5 rows when none of them wrap into a new line
                   */
                  style={{ height: `${181 * (pageSize / 5)}px` }}
                >
                  Loading...
                </td>
              </tr>
            ) : (
              <>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className={`${
                              cell.column.columnDef.meta?.cellClickable !==
                                false && onRowClick
                                ? "cursor-pointer"
                                : ""
                            } ${
                              cell.column.getIsSorted()
                                ? "cell-column-sorted"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                cell.column.columnDef.meta?.cellClickable !==
                                false
                              ) {
                                onRowClick && onRowClick(row.original);
                              }
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {!table.getRowModel().rows.length && (
                  <tr>
                    <td colSpan={999} className="no-data">
                      No matching records found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {enablePagination && (
        <ReactTablePagination
          table={table}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageCount={pageCount}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ReactTable;
