import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RoutePaths } from "../routes/routes";
import { useAppSelector } from "../store/redux-hooks";

/**
 * hook redirects users who are authenticated away from current page
 * Should be used in components like login and signup,
 *  user shouldn't do those actions if they're already logged in
 *  (they can log out and do them)
 */
export default () => {
  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (user?.id) {
      navigate(RoutePaths.COMPANY_SELECT, {
        replace: true,
        state: {
          returnTo: searchParams.get("returnTo"),
        },
      });
    }
  }, [user, searchParams, navigate]);
};
