/* eslint-disable no-throw-literal */
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { SyntheticEvent, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactGA from "../../../../reactGA";
import { RoutePaths } from "../../../../routes/routes";
import { useAppDispatch, useAppSelector } from "../../../../store/redux-hooks";
import {
  useAddCreditCardMutation,
  useLazyGetSubscriptionQuery,
  useStartSubscriptionMutation,
} from "../../../../store/slices/apiSlice";
import {
  changeStartSubscriptionCardForm,
  updateStartSubscriptionCardFormErrors,
} from "../../../../store/slices/companyBillingSlice";
import {
  NAVIGATE_GO_BACK,
  StripePaymentCardDeclineCode,
  StripePaymentCardDeclineCodeMessage,
  STRIPE_SUBSCRIPTION_STATUS,
} from "../../../../util/constants/constants";
import SubscriptionAmount from "../SubscriptionAmount";

export default () => {
  const stripe = useStripe();
  const elements = useElements();

  const startSubscription = useAppSelector(
    (state) => state.companyBilling.startSubscription
  );
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);
  const dispatch = useAppDispatch();

  const [isProcessing, setIsProcessing] = useState(false);

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [startSubscriptionMutation] = useStartSubscriptionMutation();

  const [addCreditCardMutation] = useAddCreditCardMutation();

  const [getSubscriptionData] = useLazyGetSubscriptionQuery();

  const getOrStartSubscription = async ({
    paymentMethodId,
  }: {
    paymentMethodId: string;
  }) => {
    let result;

    try {
      if (loadedCompany?.id) {
        // attempt to start subscription
        result = await startSubscriptionMutation({
          urlArgs: { companyId: loadedCompany?.id },
          body: {
            priceId: process.env.REACT_APP_STRIPE_PRICE_ID || "",
            vatRateId: process.env.REACT_APP_STRIPE_VAT_RATE_ID || "",
            paymentMethodId: paymentMethodId,
          },
        }).unwrap();
      }
    } catch (e) {
      if (loadedCompany?.id) {
        // sub may already exist if start failed, attempt to fetch it
        result = await getSubscriptionData({
          urlArgs: {
            companyId: loadedCompany.id,
            priceId: process.env.REACT_APP_STRIPE_PRICE_ID || "",
          },
        }).unwrap();
      }
    }

    return result;
  };

  const handleDeclineCodes = (detailedCode: string | null | undefined) => {
    switch (detailedCode) {
      case StripePaymentCardDeclineCode.AUTHENTICATION_REQUIRED:
        throw new Error(
          StripePaymentCardDeclineCodeMessage.AUTHENTICATION_REQUIRED
        );
      //
      case StripePaymentCardDeclineCode.APPROVE_WITH_ID:
        throw new Error(StripePaymentCardDeclineCodeMessage.APPROVE_WITH_ID);
      //
      case StripePaymentCardDeclineCode.CALL_ISSUER:
      case StripePaymentCardDeclineCode.ISSUER_NOT_AVAILABLE:
      case StripePaymentCardDeclineCode.GENERIC_DECLINE:
      case StripePaymentCardDeclineCode.NO_ACTION_TAKEN:
      case StripePaymentCardDeclineCode.NOT_PERMITTED:
      case StripePaymentCardDeclineCode.REVOCATION_OF_ALL_AUTHORIZATIONS:
      case StripePaymentCardDeclineCode.REVOCATION_OF_AUTHORIZATION:
      case StripePaymentCardDeclineCode.SECURITY_VIOLATION:
      case StripePaymentCardDeclineCode.SERVICE_NOT_ALLOWED:
      case StripePaymentCardDeclineCode.STOP_PAYMENT_ORDER:
      case StripePaymentCardDeclineCode.TRANSACTION_NOT_ALLOWED:
      // fallsthrough
      case StripePaymentCardDeclineCode.FRAUDULENT:
      case StripePaymentCardDeclineCode.LOST_CARD:
      case StripePaymentCardDeclineCode.PICKUP_CARD:
      case StripePaymentCardDeclineCode.STOLEN_CARD:
      case StripePaymentCardDeclineCode.RESTRICTED_CARD:
        throw new Error(StripePaymentCardDeclineCodeMessage.CARD_DECLINED);
      //
      case StripePaymentCardDeclineCode.CARD_NOT_SUPPORTED:
        throw new Error(StripePaymentCardDeclineCodeMessage.CARD_NOT_SUPPORTED);
      //
      case StripePaymentCardDeclineCode.CARD_VELOCITY_EXCEEDED:
      case StripePaymentCardDeclineCode.INSUFFICIENT_FUNDS:
      case StripePaymentCardDeclineCode.WITHDRAWAL_COUNT_LIMIT_EXCEEDED:
        throw new Error(StripePaymentCardDeclineCodeMessage.INSUFFICIENT_FUNDS);
      //
      case StripePaymentCardDeclineCode.CURRENCY_NOT_SUPPORTED:
        throw new Error(
          StripePaymentCardDeclineCodeMessage.CURRENCY_NOT_SUPPORTED
        );
      //
      case StripePaymentCardDeclineCode.DUPLICATE_TRANSACTION:
        throw new Error(
          StripePaymentCardDeclineCodeMessage.DUPLICATE_TRANSACTION
        );
      //
      case StripePaymentCardDeclineCode.EXPIRED_CARD:
        throw new Error(StripePaymentCardDeclineCodeMessage.EXPIRED_CARD);
      //
      case StripePaymentCardDeclineCode.PROCESSING_ERROR:
      case StripePaymentCardDeclineCode.REENTER_TRANSACTION:
      case StripePaymentCardDeclineCode.TRY_AGAIN_LATER:
        throw new Error(
          StripePaymentCardDeclineCodeMessage.PAYMENT_UNSUCCESSFUL
        );
      //
      case StripePaymentCardDeclineCode.INCORRECT_NUMBER:
      case StripePaymentCardDeclineCode.INCORRECT_CVC:
      case StripePaymentCardDeclineCode.INCORRECT_ZIP:
      case StripePaymentCardDeclineCode.INCORRECT_PIN:
      case StripePaymentCardDeclineCode.INVALID_EXPIRY_MONTH:
      case StripePaymentCardDeclineCode.INVALID_EXPIRY_YEAR:
      case StripePaymentCardDeclineCode.INVALID_NUMBER:
      case StripePaymentCardDeclineCode.INVALID_ACCOUNT:
      case StripePaymentCardDeclineCode.NEW_ACCOUNT_INFORMATION_AVAILABLE:
        throw new Error(
          StripePaymentCardDeclineCodeMessage.INCORRECT_CARD_DATA
        );

      default:
    }
  };
  const handleSubmit = async (e: SyntheticEvent) => {
    try {
      e.preventDefault();

      setIsProcessing(true);
      setErrorMessage(null);

      if (!loadedCompany?.id) {
        throw new Error(
          "Loading company data, please try again in few seconds..."
        );
      }

      // validate name
      if (!startSubscription.cardholderName) {
        dispatch(
          updateStartSubscriptionCardFormErrors({
            cardholderName: "Please provide cardholder name",
          })
        );
        return;
      }

      if (!stripe || !elements) {
        throw new Error("Loading Stripe, please try again in few seconds...");
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        throw new Error("Loading Stripe, please try again in few seconds...");
      }

      const newPaymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { name: startSubscription.cardholderName },
      });

      if (!newPaymentMethod.paymentMethod?.id) {
        throw new Error("Unable to add credit card as payment method!");
      }

      const attachedCreditCard = await addCreditCardMutation({
        urlArgs: { companyId: loadedCompany.id },
        body: { paymentMethodId: newPaymentMethod.paymentMethod?.id },
      }).unwrap();
      let cardAddedSuccessfully = attachedCreditCard?.succedeed;

      if (attachedCreditCard.setupIntent?.status === "requires_action") {
        if ("client_secret" in attachedCreditCard.setupIntent) {
          const { error } = await stripe.confirmCardSetup(
            attachedCreditCard.setupIntent?.["client_secret"]
          );

          if (error) {
            throw new Error(error.message);
          } else {
            cardAddedSuccessfully = true;
          }
        }
      }

      if (!cardAddedSuccessfully) {
        if (
          attachedCreditCard.setupIntent &&
          ("decline_code" in attachedCreditCard.setupIntent ||
            "code" in attachedCreditCard.setupIntent)
        ) {
          // choose decline_code if it's available (since it's more detailed), code otherwise
          const detailedCode =
            attachedCreditCard.setupIntent?.decline_code ||
            attachedCreditCard.setupIntent?.code;
          handleDeclineCodes(detailedCode);
        }

        throw new Error("Unable to add credit card as payment method!");
      }

      const result = await getOrStartSubscription({
        paymentMethodId: newPaymentMethod.paymentMethod.id,
      });
      if (result?.message === "Your card was declined.") {
        const detailedCode =
          result.response?.decline_code || result.response?.code;
        if (detailedCode) {
          handleDeclineCodes(detailedCode);
        }
      }
      if (!result) {
        throw new Error(
          "Unable to start subscription at this time. Please try again later."
        );
      }

      if (
        [
          STRIPE_SUBSCRIPTION_STATUS.ACTIVE,
          STRIPE_SUBSCRIPTION_STATUS.TRIALING,
        ].includes(result?.status)
      ) {
        ReactGA.event({
          action: "form_submission",
          label: "hub-subscription-start-success",
          category: "hub-subscription-start",
          value: result.plan?.amount / 100,
        });

        navigate(RoutePaths.BILLING);
      } else {
        throw new Error(
          "Something went wrong activating your subscription. Please try again later."
        );
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e?.message) {
        setErrorMessage(e?.message);
      } else {
        setErrorMessage("Something went wrong, please try again later.");
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Container fluid id="changePaymentCard">
      <Row className="mb-4">
        <Col className="text-heading fw-medium">
          <span className="color-greyish">Billing: </span>
          <span>Start Subscription</span>
        </Col>
      </Row>
      <Form className="max-width-medium" onSubmit={handleSubmit}>
        <Row>
          <Col>
            <SubscriptionAmount />
          </Col>
        </Row>
        <Row className="mt-5 mb-3">
          <Col className="text-subheader">Card Details</Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <Form.Control
                placeholder="Name on card"
                type="text"
                value={startSubscription.cardholderName}
                onChange={({ target: { value } }) => {
                  dispatch(
                    changeStartSubscriptionCardForm({ cardholderName: value })
                  );
                }}
                isInvalid={Boolean(startSubscription.errors.cardholderName)}
              />
              <Form.Control.Feedback type="invalid">
                {startSubscription.errors.cardholderName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="flex-column mb-5">
          <Col className="my-3">
            <CardElement />
          </Col>
          <Col>
            <Row className="justify-content-end">
              <Col xs="auto">
                <Button
                  variant="secondary"
                  disabled={isProcessing}
                  onClick={() => {
                    navigate(NAVIGATE_GO_BACK);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs="auto">
                <Button type="submit" disabled={isProcessing}>
                  {isProcessing ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {errorMessage && (
          <Row>
            <Alert variant="danger">{errorMessage}</Alert>
          </Row>
        )}
      </Form>
    </Container>
  );
};
