import { AuthSliceState } from "../../store/slices/authSlice";
import { VALID_PASSWORD_REGEX } from "../constants/constants";

export default (data: AuthSliceState["changePasswordData"], field?: string) => {
  const errors: Record<string, string> = {};

  if (!field || field === "password") {
    if (data.password.length === 0) {
      errors.password = "Password is required";
    } else if (!VALID_PASSWORD_REGEX.test(data.password)) {
      errors.password =
        `Password needs to contain at least one upper case, at least one lower case English letter, at least one digit and at least one special character.`.trim();
    }
  }

  if (!field || field === "repeatPassword") {
    if (data.password !== data.repeatPassword) {
      errors.repeatPassword = "Password does not match";
    }
  }

  return {
    valid: Object.keys(errors).length === 0,
    errors,
  };
};
