export interface ICompanyType {
  value: string;
  label: string;
}

export const companyTypeSoleTrader: ICompanyType = {
  value: "SOLE_TRADER",
  label: "Sole Trader",
};
export const companyTypeLimitedCompany: ICompanyType = {
  value: "LIMITED_COMPANY",
  label: "Limited Company",
};

export const companyTypesDropdown: ICompanyType[] = [
  companyTypeSoleTrader,
  companyTypeLimitedCompany,
];
