import { SetStateAction } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ReactComponent as IconReset } from "../../../assets/icons/reset.svg";

export interface IGetClientsFilters {
  clientNameOrEmailContains: string;
}

export const DEFAULT_FILTERS: IGetClientsFilters = {
  clientNameOrEmailContains: "",
};

interface ClientsFiltersProps {
  queryFilters: IGetClientsFilters;
  setQueryFilters: React.Dispatch<SetStateAction<IGetClientsFilters>>;
}

const ClientsFilters = ({
  setQueryFilters,
  queryFilters,
}: ClientsFiltersProps) => {
  return (
    <Row className="justify-content-end align-items-center mb-4">
      <Col className="mb-3">
        <Form.Group>
          <Form.Control
            className="filter-input-min-width"
            placeholder="By client name or email"
            value={queryFilters.clientNameOrEmailContains}
            onChange={({ target: { value } }) =>
              setQueryFilters((state) => ({
                ...state,
                clientNameOrEmailContains: value,
              }))
            }
          />
        </Form.Group>
      </Col>
      <Col xs="auto" className="mb-3">
        <Button
          variant="outline-dark"
          onClick={() => {
            setQueryFilters((state) => ({
              ...DEFAULT_FILTERS,
            }));
          }}
        >
          <IconReset />
        </Button>
      </Col>
    </Row>
  );
};

export default ClientsFilters;
