import { createSlice } from "@reduxjs/toolkit";

export interface AuthSliceState {
  user: null | {
    id: string;
    email: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
  loginData: {
    hasUserModifiedData: boolean;
    email: string;
    password: string;
    errors: {
      email: string | undefined;
      password: string | undefined;
    };
  };
  resetPasswordData: {
    hasUserModifiedData: boolean;
    email: string;
    errors: {
      email: string | undefined;
    };
  };
  recoverAccountData: {
    hasUserModifiedData: boolean;
    password: string;
    repeatPassword: string;
    errors: {
      password: string | undefined;
      repeatPassword: string | undefined;
    };
  };
  setupInvitedAccountData: {
    hasUserModifiedData: boolean;
    password: string;
    repeatPassword: string;
    errors: {
      password: string | undefined;
      repeatPassword: string | undefined;
    };
  };
  signUpData: {
    hasUserModifiedData: boolean;
    firstName: string;
    email: string;
    password: string;
    repeatPassword: string;
    errors: {
      firstName: string | undefined;
      email: string | undefined;
      password: string | undefined;
      repeatPassword: string | undefined;
    };
  };
  changePasswordData: {
    hasUserModifiedData: boolean;
    currentPassword: string;
    password: string;
    repeatPassword: string;
    errors: {
      currentPassword: string | undefined;
      password: string | undefined;
      repeatPassword: string | undefined;
    };
  };
}

export const defaultChangePasswordDataErrors = () => ({
  currentPassword: undefined,
  password: undefined,
  repeatPassword: undefined,
});

export const defaultChangePasswordData = () => ({
  hasUserModifiedData: false,
  currentPassword: "",
  password: "",
  repeatPassword: "",
  errors: defaultChangePasswordDataErrors(),
});

const initialState: AuthSliceState = {
  user: null,
  loginData: {
    hasUserModifiedData: false,
    email: "",
    password: "",
    errors: {
      email: undefined,
      password: undefined,
    },
  },
  resetPasswordData: {
    hasUserModifiedData: false,
    email: "",
    errors: {
      email: undefined,
    },
  },
  recoverAccountData: {
    hasUserModifiedData: false,
    password: "",
    repeatPassword: "",
    errors: {
      password: undefined,
      repeatPassword: undefined,
    },
  },
  setupInvitedAccountData: {
    hasUserModifiedData: false,
    password: "",
    repeatPassword: "",
    errors: {
      password: undefined,
      repeatPassword: undefined,
    },
  },
  signUpData: {
    hasUserModifiedData: false,
    firstName: "",
    email: "",
    password: "",
    repeatPassword: "",
    errors: {
      firstName: undefined,
      email: undefined,
      password: undefined,
      repeatPassword: undefined,
    },
  },
  changePasswordData: defaultChangePasswordData(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    init: (state) => {
      //   const userJWT = window.localStorage.getItem("userJWT");
      //   state.isAuthenticated = true;
    },
    // set user data
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    //
    updateLoginForm: (state, action) => {
      state.loginData = {
        ...state.loginData,
        ...action.payload,
        errors: {
          ...state.loginData.errors,
          // remove error from any property that was updated
          ...Object.keys(action.payload).reduce(
            (acc, curr) => ({ ...acc, [curr]: undefined }),
            {}
          ),
        },
        hasUserModifiedData: true,
      };
    },
    updateLoginFormErrors: (state, action) => {
      state.loginData = {
        ...state.loginData,
        errors: action.payload,
      };
    },
    //
    updateResetPasswordForm: (state, action) => {
      state.resetPasswordData = {
        ...state.resetPasswordData,
        ...action.payload,
        errors: {
          ...state.resetPasswordData.errors,
          // remove error from any property that was updated
          ...Object.keys(action.payload).reduce(
            (acc, curr) => ({ ...acc, [curr]: undefined }),
            {}
          ),
        },
        hasUserModifiedData: true,
      };
    },
    updateResetPasswordFormErrors: (state, action) => {
      state.resetPasswordData = {
        ...state.resetPasswordData,
        errors: action.payload,
      };
    },
    //
    updateRecoverAccountForm: (state, action) => {
      state.recoverAccountData = {
        ...state.recoverAccountData,
        ...action.payload,
        errors: {
          ...state.recoverAccountData.errors,
          // remove error from any property that was updated
          ...Object.keys(action.payload).reduce(
            (acc, curr) => ({ ...acc, [curr]: undefined }),
            {}
          ),
        },
        hasUserModifiedData: true,
      };
    },
    updateRecoverAccountFormErrors: (state, action) => {
      state.recoverAccountData = {
        ...state.recoverAccountData,
        errors: action.payload,
      };
    },
    //
    updateSetupInvitedAccountForm: (state, action) => {
      state.setupInvitedAccountData = {
        ...state.setupInvitedAccountData,
        ...action.payload,
        errors: {
          ...state.setupInvitedAccountData.errors,
          // remove error from any property that was updated
          ...Object.keys(action.payload).reduce(
            (acc, curr) => ({ ...acc, [curr]: undefined }),
            {}
          ),
        },
        hasUserModifiedData: true,
      };
    },
    updateSetupInvitedAccountFormErrors: (state, action) => {
      state.setupInvitedAccountData = {
        ...state.setupInvitedAccountData,
        errors: action.payload,
      };
    },
    //
    updateSignUpForm: (state, action) => {
      state.signUpData = {
        ...state.signUpData,
        ...action.payload,
        errors: {
          ...state.signUpData.errors,
          // remove error from any property that was updated
          ...Object.keys(action.payload).reduce(
            (acc, curr) => ({ ...acc, [curr]: undefined }),
            {}
          ),
        },
        hasUserModifiedData: true,
      };
    },
    updateSignUpFormErrors: (state, action) => {
      state.signUpData = {
        ...state.signUpData,
        errors: action.payload,
      };
    },
    //
    updateChangePasswordForm: (state, action) => {
      state.changePasswordData = {
        ...state.changePasswordData,
        ...action.payload,
        errors: {
          ...state.changePasswordData.errors,
          // remove error from any property that was updated
          ...Object.keys(action.payload).reduce(
            (acc, curr) => ({ ...acc, [curr]: undefined }),
            {}
          ),
        },
        hasUserModifiedData: true,
      };
    },
    setChangePasswordForm: (state, action) => {
      state.changePasswordData = {
        ...state.changePasswordData,
        ...action.payload,
      };
    },
    updateChangePasswordFormErrors: (state, action) => {
      state.changePasswordData = {
        ...state.changePasswordData,
        errors: action.payload,
      };
    },
  },
});

export const {
  init,
  setUserData,
  updateLoginForm,
  updateLoginFormErrors,
  updateResetPasswordForm,
  updateResetPasswordFormErrors,
  updateRecoverAccountForm,
  updateRecoverAccountFormErrors,
  updateSetupInvitedAccountForm,
  updateSetupInvitedAccountFormErrors,
  updateSignUpForm,
  updateSignUpFormErrors,
  setChangePasswordForm,
  updateChangePasswordForm,
  updateChangePasswordFormErrors,
} = authSlice.actions;

export default authSlice.reducer;
