import { GroupBase } from "react-select";

export interface ICurrency {
  currencyCode: string;
  currencyName: string;
  currencySymbol?: string;
}

const otherCurrencies: ICurrency[] = [
  {
    currencyCode: "AED",
    currencyName: "United Arab Emirates Dirham",
    currencySymbol: "د.إ.‏",
  },
  {
    currencyCode: "AFN",
    currencyName: "Afghan Afghani",
    currencySymbol: undefined,
  },
  { currencyCode: "ALL", currencyName: "Albanian Lek", currencySymbol: "Lek" },
  {
    currencyCode: "AMD",
    currencyName: "Armenian Dram",
    currencySymbol: undefined,
  },
  {
    currencyCode: "ANG",
    currencyName: "Netherlands Antillean Guilder",
    currencySymbol: undefined,
  },
  {
    currencyCode: "AOA",
    currencyName: "Angolan Kwanza",
    currencySymbol: undefined,
  },
  { currencyCode: "ARS", currencyName: "Argentine Peso", currencySymbol: "$" },
  {
    currencyCode: "AUD",
    currencyName: "Australian Dollar",
    currencySymbol: "$",
  },
  {
    currencyCode: "AWG",
    currencyName: "Aruban Florin",
    currencySymbol: undefined,
  },
  {
    currencyCode: "AZN",
    currencyName: "Azerbaijani Manat",
    currencySymbol: undefined,
  },
  {
    currencyCode: "BAM",
    currencyName: "Bosnia-Herzegovina Convertible Mark",
    currencySymbol: "КМ.",
  },
  {
    currencyCode: "BBD",
    currencyName: "Barbadian Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "BDT",
    currencyName: "Bangladeshi Taka",
    currencySymbol: undefined,
  },
  { currencyCode: "BGN", currencyName: "Bulgarian Lev", currencySymbol: "лв." },
  {
    currencyCode: "BHD",
    currencyName: "Bahraini Dinar",
    currencySymbol: "د.ب.‏",
  },
  {
    currencyCode: "BIF",
    currencyName: "Burundian Franc",
    currencySymbol: undefined,
  },
  {
    currencyCode: "BMD",
    currencyName: "Bermudan Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "BND",
    currencyName: "Brunei Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "BOB",
    currencyName: "Bolivian Boliviano",
    currencySymbol: "B$",
  },
  { currencyCode: "BRL", currencyName: "Brazilian Real", currencySymbol: "R$" },
  {
    currencyCode: "BSD",
    currencyName: "Bahamian Dollar",
    currencySymbol: undefined,
  },
  { currencyCode: "BTC", currencyName: "Bitcoin", currencySymbol: undefined },
  {
    currencyCode: "BTN",
    currencyName: "Bhutanese Ngultrum",
    currencySymbol: undefined,
  },
  {
    currencyCode: "BWP",
    currencyName: "Botswanan Pula",
    currencySymbol: undefined,
  },
  {
    currencyCode: "BYN",
    currencyName: "Belarusian Ruble",
    currencySymbol: undefined,
  },
  {
    currencyCode: "BZD",
    currencyName: "Belize Dollar",
    currencySymbol: undefined,
  },
  { currencyCode: "CAD", currencyName: "Canadian Dollar", currencySymbol: "$" },
  {
    currencyCode: "CDF",
    currencyName: "Congolese Franc",
    currencySymbol: undefined,
  },
  { currencyCode: "CHF", currencyName: "Swiss Franc", currencySymbol: "SFr." },
  {
    currencyCode: "CLF",
    currencyName: "Chilean Unit of Account (UF)",
    currencySymbol: undefined,
  },
  { currencyCode: "CLP", currencyName: "Chilean Peso", currencySymbol: "Ch$" },
  {
    currencyCode: "CNH",
    currencyName: "Chinese Yuan (Offshore)",
    currencySymbol: undefined,
  },
  { currencyCode: "CNY", currencyName: "Chinese Yuan", currencySymbol: "￥" },
  { currencyCode: "COP", currencyName: "Colombian Peso", currencySymbol: "$" },
  {
    currencyCode: "CRC",
    currencyName: "Costa Rican Colón",
    currencySymbol: "C",
  },
  {
    currencyCode: "CUC",
    currencyName: "Cuban Convertible Peso",
    currencySymbol: undefined,
  },
  {
    currencyCode: "CUP",
    currencyName: "Cuban Peso",
    currencySymbol: undefined,
  },
  {
    currencyCode: "CVE",
    currencyName: "Cape Verdean Escudo",
    currencySymbol: undefined,
  },
  {
    currencyCode: "CZK",
    currencyName: "Czech Republic Koruna",
    currencySymbol: "Kč",
  },
  {
    currencyCode: "DJF",
    currencyName: "Djiboutian Franc",
    currencySymbol: undefined,
  },
  { currencyCode: "DKK", currencyName: "Danish Krone", currencySymbol: "kr" },
  {
    currencyCode: "DOP",
    currencyName: "Dominican Peso",
    currencySymbol: "RD$",
  },
  {
    currencyCode: "DZD",
    currencyName: "Algerian Dinar",
    currencySymbol: "د.ج.‏",
  },
  {
    currencyCode: "EGP",
    currencyName: "Egyptian Pound",
    currencySymbol: "ج.م.‏",
  },
  {
    currencyCode: "ERN",
    currencyName: "Eritrean Nakfa",
    currencySymbol: undefined,
  },
  {
    currencyCode: "ETB",
    currencyName: "Ethiopian Birr",
    currencySymbol: undefined,
  },
  // eur
  {
    currencyCode: "FJD",
    currencyName: "Fijian Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "FKP",
    currencyName: "Falkland Islands Pound",
    currencySymbol: undefined,
  },
  // gbp
  {
    currencyCode: "GEL",
    currencyName: "Georgian Lari",
    currencySymbol: undefined,
  },
  {
    currencyCode: "GGP",
    currencyName: "Guernsey Pound",
    currencySymbol: undefined,
  },
  {
    currencyCode: "GHS",
    currencyName: "Ghanaian Cedi",
    currencySymbol: undefined,
  },
  {
    currencyCode: "GIP",
    currencyName: "Gibraltar Pound",
    currencySymbol: undefined,
  },
  {
    currencyCode: "GMD",
    currencyName: "Gambian Dalasi",
    currencySymbol: undefined,
  },
  {
    currencyCode: "GNF",
    currencyName: "Guinean Franc",
    currencySymbol: undefined,
  },
  {
    currencyCode: "GTQ",
    currencyName: "Guatemalan Quetzal",
    currencySymbol: "Q",
  },
  {
    currencyCode: "GYD",
    currencyName: "Guyanaese Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "HKD",
    currencyName: "Hong Kong Dollar",
    currencySymbol: "HK$",
  },
  {
    currencyCode: "HNL",
    currencyName: "Honduran Lempira",
    currencySymbol: "L",
  },
  { currencyCode: "HRK", currencyName: "Croatian Kuna", currencySymbol: "Kn" },
  {
    currencyCode: "HTG",
    currencyName: "Haitian Gourde",
    currencySymbol: undefined,
  },
  {
    currencyCode: "HUF",
    currencyName: "Hungarian Forint",
    currencySymbol: "Ft",
  },
  {
    currencyCode: "IDR",
    currencyName: "Indonesian Rupiah",
    currencySymbol: "Rp",
  },
  {
    currencyCode: "ILS",
    currencyName: "Israeli New Sheqel",
    currencySymbol: 'ש"ח',
  },
  {
    currencyCode: "IMP",
    currencyName: "Manx pound",
    currencySymbol: undefined,
  },
  {
    currencyCode: "INR",
    currencyName: "Indian Rupee",
    currencySymbol: "Rs.", // रू ?
  },
  { currencyCode: "IQD", currencyName: "Iraqi Dinar", currencySymbol: "د.ع.‏" },
  {
    currencyCode: "IRR",
    currencyName: "Iranian Rial",
    currencySymbol: undefined,
  },
  {
    currencyCode: "ISK",
    currencyName: "Icelandic Króna",
    currencySymbol: "kr.",
  },
  {
    currencyCode: "JEP",
    currencyName: "Jersey Pound",
    currencySymbol: undefined,
  },
  {
    currencyCode: "JMD",
    currencyName: "Jamaican Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "JOD",
    currencyName: "Jordanian Dinar",
    currencySymbol: "د.أ.‏",
  },
  { currencyCode: "JPY", currencyName: "Japanese Yen", currencySymbol: "￥" },
  {
    currencyCode: "KES",
    currencyName: "Kenyan Shilling",
    currencySymbol: undefined,
  },
  {
    currencyCode: "KGS",
    currencyName: "Kyrgystani Som",
    currencySymbol: undefined,
  },
  {
    currencyCode: "KHR",
    currencyName: "Cambodian Riel",
    currencySymbol: undefined,
  },
  {
    currencyCode: "KMF",
    currencyName: "Comorian Franc",
    currencySymbol: undefined,
  },
  {
    currencyCode: "KPW",
    currencyName: "North Korean Won",
    currencySymbol: undefined,
  },
  {
    currencyCode: "KRW",
    currencyName: "South Korean Won",
    currencySymbol: "￦",
  },
  {
    currencyCode: "KWD",
    currencyName: "Kuwaiti Dinar",
    currencySymbol: "د.ك.‏",
  },
  {
    currencyCode: "KYD",
    currencyName: "Cayman Islands Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "KZT",
    currencyName: "Kazakhstani Tenge",
    currencySymbol: undefined,
  },
  {
    currencyCode: "LAK",
    currencyName: "Laotian Kip",
    currencySymbol: undefined,
  },
  {
    currencyCode: "LBP",
    currencyName: "Lebanese Pound",
    currencySymbol: "ل.ل.‏",
  },
  {
    currencyCode: "LKR",
    currencyName: "Sri Lankan Rupee",
    currencySymbol: undefined,
  },
  {
    currencyCode: "LRD",
    currencyName: "Liberian Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "LSL",
    currencyName: "Lesotho Loti",
    currencySymbol: undefined,
  },
  {
    currencyCode: "LYD",
    currencyName: "Libyan Dinar",
    currencySymbol: "د.ل.‏",
  },
  {
    currencyCode: "MAD",
    currencyName: "Moroccan Dirham",
    currencySymbol: "د.م.‏",
  },
  {
    currencyCode: "MDL",
    currencyName: "Moldovan Leu",
    currencySymbol: undefined,
  },
  {
    currencyCode: "MGA",
    currencyName: "Malagasy Ariary",
    currencySymbol: undefined,
  },
  {
    currencyCode: "MKD",
    currencyName: "Macedonian Denar",
    currencySymbol: "Den",
  },
  {
    currencyCode: "MMK",
    currencyName: "Myanma Kyat",
    currencySymbol: undefined,
  },
  {
    currencyCode: "MNT",
    currencyName: "Mongolian Tugrik",
    currencySymbol: undefined,
  },
  {
    currencyCode: "MOP",
    currencyName: "Macanese Pataca",
    currencySymbol: undefined,
  },
  {
    currencyCode: "MRU",
    currencyName: "Mauritanian Ouguiya",
    currencySymbol: undefined,
  },
  {
    currencyCode: "MUR",
    currencyName: "Mauritian Rupee",
    currencySymbol: undefined,
  },
  {
    currencyCode: "MVR",
    currencyName: "Maldivian Rufiyaa",
    currencySymbol: undefined,
  },
  {
    currencyCode: "MWK",
    currencyName: "Malawian Kwacha",
    currencySymbol: undefined,
  },
  { currencyCode: "MXN", currencyName: "Mexican Peso", currencySymbol: "$" },
  {
    currencyCode: "MYR",
    currencyName: "Malaysian Ringgit",
    currencySymbol: "RM",
  },
  {
    currencyCode: "MZN",
    currencyName: "Mozambican Metical",
    currencySymbol: undefined,
  },
  {
    currencyCode: "NAD",
    currencyName: "Namibian Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "NGN",
    currencyName: "Nigerian Naira",
    currencySymbol: undefined,
  },
  {
    currencyCode: "NIO",
    currencyName: "Nicaraguan Córdoba",
    currencySymbol: "$C",
  },
  {
    currencyCode: "NOK",
    currencyName: "Norwegian Krone",
    currencySymbol: "kr",
  },
  {
    currencyCode: "NPR",
    currencyName: "Nepalese Rupee",
    currencySymbol: undefined,
  },
  {
    currencyCode: "NZD",
    currencyName: "New Zealand Dollar",
    currencySymbol: "$",
  },
  { currencyCode: "OMR", currencyName: "Omani Rial", currencySymbol: "ر.ع.‏" },
  {
    currencyCode: "PAB",
    currencyName: "Panamanian Balboa",
    currencySymbol: "B",
  },
  {
    currencyCode: "PEN",
    currencyName: "Peruvian Nuevo Sol",
    currencySymbol: "S/",
  },
  {
    currencyCode: "PGK",
    currencyName: "Papua New Guinean Kina",
    currencySymbol: undefined,
  },
  {
    currencyCode: "PHP",
    currencyName: "Philippine Peso",
    currencySymbol: "Php",
  },
  {
    currencyCode: "PKR",
    currencyName: "Pakistani Rupee",
    currencySymbol: undefined,
  },
  { currencyCode: "PLN", currencyName: "Polish Zloty", currencySymbol: "zł" },
  {
    currencyCode: "PYG",
    currencyName: "Paraguayan Guarani",
    currencySymbol: "G",
  },
  { currencyCode: "QAR", currencyName: "Qatari Rial", currencySymbol: "ر.ق.‏" },
  { currencyCode: "RON", currencyName: "Romanian Leu", currencySymbol: "LEI" },
  { currencyCode: "RSD", currencyName: "Serbian Dinar", currencySymbol: "RSD" },
  {
    currencyCode: "RUB",
    currencyName: "Russian Ruble",
    currencySymbol: "руб.",
  },
  {
    currencyCode: "RWF",
    currencyName: "Rwandan Franc",
    currencySymbol: undefined,
  },
  { currencyCode: "SAR", currencyName: "Saudi Riyal", currencySymbol: "ر.س.‏" },
  {
    currencyCode: "SBD",
    currencyName: "Solomon Islands Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "SCR",
    currencyName: "Seychellois Rupee",
    currencySymbol: undefined,
  },
  {
    currencyCode: "SDG",
    currencyName: "Sudanese Pound",
    currencySymbol: "ج.س.‏",
  },
  { currencyCode: "SEK", currencyName: "Swedish Krona", currencySymbol: "kr" },
  {
    currencyCode: "SGD",
    currencyName: "Singapore Dollar",
    currencySymbol: "S$",
  },
  {
    currencyCode: "SHP",
    currencyName: "Saint Helena Pound",
    currencySymbol: undefined,
  },
  {
    currencyCode: "SLL",
    currencyName: "Sierra Leonean Leone",
    currencySymbol: undefined,
  },
  {
    currencyCode: "SOS",
    currencyName: "Somali Shilling",
    currencySymbol: undefined,
  },
  {
    currencyCode: "SRD",
    currencyName: "Surinamese Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "SSP",
    currencyName: "South Sudanese Pound",
    currencySymbol: undefined,
  },
  {
    currencyCode: "STD",
    currencyName: "São Tomé and Príncipe Dobra (pre-2018)",
    currencySymbol: undefined,
  },
  {
    currencyCode: "STN",
    currencyName: "São Tomé and Príncipe Dobra",
    currencySymbol: undefined,
  },
  {
    currencyCode: "SVC",
    currencyName: "Salvadoran Colón",
    currencySymbol: "C",
  },
  {
    currencyCode: "SYP",
    currencyName: "Syrian Pound",
    currencySymbol: "ل.س.‏",
  },
  {
    currencyCode: "SZL",
    currencyName: "Swazi Lilangeni",
    currencySymbol: undefined,
  },
  { currencyCode: "THB", currencyName: "Thai Baht", currencySymbol: "฿" },
  {
    currencyCode: "TJS",
    currencyName: "Tajikistani Somoni",
    currencySymbol: undefined,
  },
  {
    currencyCode: "TMT",
    currencyName: "Turkmenistani Manat",
    currencySymbol: undefined,
  },
  {
    currencyCode: "TND",
    currencyName: "Tunisian Dinar",
    currencySymbol: "د.ت.‏",
  },
  {
    currencyCode: "TOP",
    currencyName: "Tongan Pa'anga",
    currencySymbol: undefined,
  },
  { currencyCode: "TRY", currencyName: "Turkish Lira", currencySymbol: "TL" },
  {
    currencyCode: "TTD",
    currencyName: "Trinidad and Tobago Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "TWD",
    currencyName: "New Taiwan Dollar",
    currencySymbol: "NT$",
  },
  {
    currencyCode: "TZS",
    currencyName: "Tanzanian Shilling",
    currencySymbol: undefined,
  },
  {
    currencyCode: "UAH",
    currencyName: "Ukrainian Hryvnia",
    currencySymbol: "грн.",
  },
  {
    currencyCode: "UGX",
    currencyName: "Ugandan Shilling",
    currencySymbol: undefined,
  },
  // usd
  {
    currencyCode: "UYU",
    currencyName: "Uruguayan Peso",
    currencySymbol: "NU$",
  },
  {
    currencyCode: "UZS",
    currencyName: "Uzbekistan Som",
    currencySymbol: undefined,
  },
  {
    currencyCode: "VEF",
    currencyName: "Venezuelan Bolívar Fuerte (Old)",
    currencySymbol: "BsF.",
  },
  {
    currencyCode: "VES",
    currencyName: "Venezuelan Bolívar Soberano",
    currencySymbol: undefined,
  },
  { currencyCode: "VND", currencyName: "Vietnamese Dong", currencySymbol: "đ" },
  {
    currencyCode: "VUV",
    currencyName: "Vanuatu Vatu",
    currencySymbol: undefined,
  },
  {
    currencyCode: "WST",
    currencyName: "Samoan Tala",
    currencySymbol: undefined,
  },
  {
    currencyCode: "XAF",
    currencyName: "CFA Franc BEAC",
    currencySymbol: undefined,
  },
  {
    currencyCode: "XAG",
    currencyName: "Silver Ounce",
    currencySymbol: undefined,
  },
  {
    currencyCode: "XAU",
    currencyName: "Gold Ounce",
    currencySymbol: undefined,
  },
  {
    currencyCode: "XCD",
    currencyName: "East Caribbean Dollar",
    currencySymbol: undefined,
  },
  {
    currencyCode: "XDR",
    currencyName: "Special Drawing Rights",
    currencySymbol: undefined,
  },
  {
    currencyCode: "XOF",
    currencyName: "CFA Franc BCEAO",
    currencySymbol: undefined,
  },
  {
    currencyCode: "XPD",
    currencyName: "Palladium Ounce",
    currencySymbol: undefined,
  },
  { currencyCode: "XPF", currencyName: "CFP Franc", currencySymbol: undefined },
  {
    currencyCode: "XPT",
    currencyName: "Platinum Ounce",
    currencySymbol: undefined,
  },
  { currencyCode: "YER", currencyName: "Yemeni Rial", currencySymbol: "ر.ي.‏" },
  {
    currencyCode: "ZAR",
    currencyName: "South African Rand",
    currencySymbol: "R",
  },
  {
    currencyCode: "ZMW",
    currencyName: "Zambian Kwacha",
    currencySymbol: undefined,
  },
  {
    currencyCode: "ZWL",
    currencyName: "Zimbabwean Dollar",
    currencySymbol: undefined,
  },
];

export const currencyEuro: ICurrency = {
  currencyCode: "EUR",
  currencyName: "Euro",
  currencySymbol: "€",
};

const frequentCurrencies: ICurrency[] = [
  currencyEuro,
  {
    currencyCode: "GBP",
    currencyName: "British Pound Sterling",
    currencySymbol: "£",
  },
  {
    currencyCode: "USD",
    currencyName: "United States Dollar",
    currencySymbol: "$",
  },
];

export const allCurrencies: ICurrency[] = [
  ...frequentCurrencies,
  ...otherCurrencies,
];

export const currenciesDropdown: GroupBase<ICurrency>[] = [
  {
    label: "Frequent",
    options: frequentCurrencies,
  },
  {
    label: "All other",
    options: otherCurrencies,
  },
];
