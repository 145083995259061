import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { IErrorAPIRequest } from "../../../../errors/apiErrors";
import { processAuthRequestErrors } from "../../../../errors/authErrors";
import { useAppDispatch, useAppSelector } from "../../../../store/redux-hooks";
import { useChangePasswordMutation } from "../../../../store/slices/apiSlice";
import {
  defaultChangePasswordData,
  setChangePasswordForm,
  updateChangePasswordForm,
  updateChangePasswordFormErrors,
} from "../../../../store/slices/authSlice";
import {
  MAIN_PASSWORD_FIELD_PLACEHOLDER_TEXT,
  NAVIGATE_GO_BACK,
} from "../../../../util/constants/constants";
import { RoutePaths } from "../../../../routes/routes";

import useValidator from "../../../../hooks/useValidator";
import validateChangePasswordData from "../../../../util/validate/validateChangePasswordData";
import RequiredFieldBadge from "../../../common/RequiredFieldBadge";

export default () => {
  const changePasswordData = useAppSelector(
    (state) => state.auth.changePasswordData
  );
  const dispatch = useAppDispatch();

  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    password: false,
    repeatPassword: false,
  });

  const toggleShowPassword =
    (field: "currentPassword" | "password" | "repeatPassword") => () => {
      setShowPasswords({ ...showPasswords, [field]: !showPasswords[field] });
    };

  const navigate = useNavigate();

  const [requestErrors, setRequestErrors] = useState<IErrorAPIRequest>();
  const { validate, validateErrors } = useValidator(
    validateChangePasswordData,
    changePasswordData
  );

  const [
    changePasswordMutation,
    { isSuccess, isLoading, error: changePasswordError },
  ] = useChangePasswordMutation();

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processAuthRequestErrors(changePasswordError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateChangePasswordFormErrors(formValidationErrors));
  }, [changePasswordError, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      navigate(RoutePaths.LOGOUT);
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    // clean up form on unmount
    return () => {
      dispatch(setChangePasswordForm(defaultChangePasswordData()));
    };
  }, [dispatch]);

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col className="text-title fw-medium custom-text-title">
          Change Password
        </Col>
      </Row>
      <Form className="max-width-medium">
        <Row>
          <Col className="mb-4 custom-text-tiny">
            Picking a good password is important. Ideally it should be a long
            password using random letters, numbers and symbols. If you do need
            to write your password down to remember it, then keep it in a safe
            place like your wallet or a locked drawer, <strong>not </strong>
            stuck to the side of your computer :)
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label className="custom-form-label">
                Current Password
                <RequiredFieldBadge />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPasswords.currentPassword ? "text" : "password"}
                  value={changePasswordData.currentPassword}
                  onChange={({ target: { value } }) =>
                    dispatch(
                      updateChangePasswordForm({ currentPassword: value })
                    )
                  }
                  isInvalid={Boolean(
                    changePasswordData.errors?.currentPassword
                  )}
                />
                <Button
                  variant="outline-secondary"
                  id="toggle-current-password"
                  type="button"
                  onClick={toggleShowPassword("currentPassword")}
                  // https://github.com/twbs/bootstrap/issues/36049
                  className="border-radius-right-8px"
                >
                  {showPasswords.currentPassword ? (
                    <Eye size="1.7em" />
                  ) : (
                    <EyeSlash size="1.7em" />
                  )}
                </Button>
                <Form.Control.Feedback type="invalid">
                  {changePasswordData.errors?.currentPassword}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label className="custom-form-label">
                New Password
                <RequiredFieldBadge />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPasswords.password ? "text" : "password"}
                  value={changePasswordData.password}
                  placeholder={MAIN_PASSWORD_FIELD_PLACEHOLDER_TEXT}
                  onChange={({ target: { value } }) => {
                    dispatch(updateChangePasswordForm({ password: value }));
                  }}
                  onBlur={() => {
                    validate("password");
                  }}
                  isInvalid={Boolean(
                    changePasswordData.errors?.password ||
                      validateErrors.password
                  )}
                />
                <Button
                  variant="outline-secondary"
                  id="toggle-password"
                  type="button"
                  onClick={toggleShowPassword("password")}
                  // https://github.com/twbs/bootstrap/issues/36049
                  className="border-radius-right-8px"
                >
                  {showPasswords.password ? (
                    <Eye size="1.7em" />
                  ) : (
                    <EyeSlash size="1.7em" />
                  )}
                </Button>
                <Form.Control.Feedback type="invalid">
                  {changePasswordData.errors?.password ||
                    validateErrors.password}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Form.Group>
              {/* TODO: compare passwords and use isValid and isInvalid props*/}
              <Form.Label className="custom-form-label">
                Repeat New Password
                <RequiredFieldBadge />
              </Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPasswords.repeatPassword ? "text" : "password"}
                  value={changePasswordData.repeatPassword}
                  onChange={({ target: { value } }) => {
                    dispatch(
                      updateChangePasswordForm({ repeatPassword: value })
                    );
                  }}
                  onBlur={() => {
                    validate("repeatPassword");
                  }}
                  isValid={
                    Boolean(changePasswordData.repeatPassword) &&
                    changePasswordData.password ===
                      changePasswordData.repeatPassword
                  }
                  isInvalid={Boolean(
                    changePasswordData.errors?.repeatPassword ||
                      validateErrors.repeatPassword
                  )}
                />
                <Button
                  variant="outline-secondary"
                  id="toggle-repeat-password"
                  type="button"
                  onClick={toggleShowPassword("repeatPassword")}
                  // https://github.com/twbs/bootstrap/issues/36049
                  className="border-radius-right-8px"
                >
                  {showPasswords.repeatPassword ? (
                    <Eye size="1.7em" />
                  ) : (
                    <EyeSlash size="1.7em" />
                  )}
                </Button>
                <Form.Control.Feedback type="invalid">
                  {changePasswordData.errors?.repeatPassword ||
                    validateErrors.repeatPassword}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-end mb-3">
          <Col xs="auto">
            <Button
              variant="secondary"
              type="button"
              onClick={() => navigate(NAVIGATE_GO_BACK)}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              variant="primary"
              type="submit"
              disabled={isLoading}
              onClick={(event) => {
                event.preventDefault();
                validate();

                if (!validateChangePasswordData(changePasswordData).valid) {
                  return;
                }

                if (
                  changePasswordData.password ===
                    changePasswordData.repeatPassword &&
                  changePasswordData.repeatPassword
                ) {
                  changePasswordMutation({
                    currentPassword: changePasswordData.currentPassword,
                    newPassword: changePasswordData.password,
                  });
                } else {
                  setRequestErrors({
                    message: "Please fill the form fields.",
                  });
                }
              }}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Col>
        </Row>
        {requestErrors?.message && (
          <Row>
            <Alert variant="danger">{requestErrors?.message}</Alert>
          </Row>
        )}
        {isSuccess && !isLoading && (
          <Row>
            <Alert variant="success">
              You have successfully changed your password!
            </Alert>
          </Row>
        )}
      </Form>
    </Container>
  );
};
