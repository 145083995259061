//
export enum CompanyUserRole {
  ADMINISTRATOR = "ADMINISTRATOR",
  MEMBER = "MEMBER",
  GUEST = "GUEST",
}

export enum CompanyUserPermission {
  MANAGE_USERS = "MANAGE_USERS",
  BILLING_ACCESS = "BILLING_ACCESS",
}

//
export enum AccountsAndPayrollRole {
  FULL_ACCESS = "FULL_ACCESS",
  LIMITED_ACCESS = "LIMITED_ACCESS",
  VIEW_ACCESS = "VIEW_ACCESS",
  NO_ACCESS = "NO_ACCESS",
}

export enum AccountsAndPayrollPermission {
  SALES_ACCESS = "SALES_ACCESS",
  PURCHASES_ACCESS = "PURCHASES_ACCESS",
  PAYROLL_ACCESS = "PAYROLL_ACCESS",
}

//
export enum ProjectRole {
  FULL_ACCESS = "FULL_ACCESS",
  GUEST_ACCESS = "GUEST_ACCESS",
  NO_ACCESS = "NO_ACCESS",
}

export enum ProjectPermission {
  PROJECTS = "PROJECTS_ACCESS",
}

//
export enum ProposalRole {
  FULL_ACCESS = "FULL_ACCESS",
  NO_ACCESS = "NO_ACCESS",
}
