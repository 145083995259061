import { Table, Updater } from "@tanstack/react-table";
import { Button, Col, Container, InputGroup, Row } from "react-bootstrap";

import { DEFAULT_REACT_TABLE_PAGESIZE_OPTIONS } from "../../../util/constants/constants";
import { determinePaginationBoxValue } from "../../../util/functions";
import CustomSelect from "../Select";

const renderPaginationPageBar = ({
  pageIndex,
  pageCount,
  setPageIndex,
}: {
  pageIndex: number;
  pageCount: number;
  setPageIndex: (updater: Updater<number>) => void;
}) => {
  const page = pageIndex + 1;

  const box3 = determinePaginationBoxValue(3, pageCount, page);
  const box4 = determinePaginationBoxValue(4, pageCount, page);
  const box5 = determinePaginationBoxValue(5, pageCount, page);

  const box6 = pageCount === 6 ? pageCount : pageCount - 1;

  return (
    <>
      <Button
        onClick={() => setPageIndex(0)}
        variant={`${page === 1 ? "primary" : "outline-secondary"}`}
      >
        1
      </Button>
      {pageCount >= 2 &&
        (page > 4 && pageCount >= 8 ? (
          <Button variant="outline-secondary" disabled>
            ...
          </Button>
        ) : (
          <Button
            onClick={() => setPageIndex(1)}
            variant={`${page === 2 ? "primary" : "outline-secondary"}`}
          >
            2
          </Button>
        ))}
      {pageCount >= 3 && (
        <Button
          onClick={() => setPageIndex(box3 - 1)}
          variant={`${page === box3 ? "primary" : "outline-secondary"}`}
        >
          {box3}
        </Button>
      )}
      {pageCount >= 4 && (
        <Button
          onClick={() => setPageIndex(box4 - 1)}
          variant={`${page === box4 ? "primary" : "outline-secondary"}`}
        >
          {box4}
        </Button>
      )}
      {pageCount >= 5 && (
        <Button
          onClick={() => setPageIndex(box5 - 1)}
          variant={`${page === box5 ? "primary" : "outline-secondary"}`}
        >
          {box5}
        </Button>
      )}
      {pageCount >= 6 &&
        (page < pageCount - 3 && pageCount >= 8 ? (
          <Button variant="outline-secondary" disabled>
            ...
          </Button>
        ) : (
          <Button
            onClick={() => setPageIndex(box6 - 1)}
            variant={`${page === box6 ? "primary" : "outline-secondary"}`}
          >
            {box6}
          </Button>
        ))}
      {pageCount >= 7 && (
        <Button
          onClick={() => setPageIndex(pageCount - 1)}
          variant={`${page === pageCount ? "primary" : "outline-secondary"}`}
        >
          {pageCount}
        </Button>
      )}
    </>
  );
};

const ReactTablePagination = <T,>({
  table,
  pageIndex,
  pageSize,
  pageCount,
  loading,
}: {
  table: Table<T>;
  pageIndex: number;
  pageSize: number;
  pageCount: number;
  loading?: boolean;
}) => {
  return (
    <Container fluid className="react-table-pagination mt-4 px-0">
      <Row className=" align-items-center justify-content-between">
        <Col xs="auto" className="mb-2">
          <Row className="flex-nowrap align-items-center">
            <Col xs="auto" className="pe-0">
              <span>Show</span>
            </Col>
            <Col xs="auto" className="px-2">
              <CustomSelect
                placeholder="Show..."
                isDisabled={loading}
                options={DEFAULT_REACT_TABLE_PAGESIZE_OPTIONS}
                value={DEFAULT_REACT_TABLE_PAGESIZE_OPTIONS.find(
                  (option) => option.value === pageSize
                )}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={(selected: any) => {
                  table.setPageSize(
                    Number(
                      Array.isArray(selected)
                        ? selected[0].value
                        : selected?.value ?? 10
                    )
                  );
                }}
              />
            </Col>
            <Col xs="auto" className="ps-0">
              <span>entries</span>
            </Col>
          </Row>
        </Col>
        <Col xs="auto" className="mb-2">
          <InputGroup>
            <Button
              variant="outline-secondary"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage() || loading}
            >
              First
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage() || loading}
            >
              Previous
            </Button>
            {/* page number bar */}
            {renderPaginationPageBar({
              pageIndex,
              pageCount,
              setPageIndex: table.setPageIndex,
            })}
            <Button
              variant="outline-secondary"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage() || loading}
            >
              Next
            </Button>
            <Button
              variant="outline-secondary"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage() || loading}
            >
              Last
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default ReactTablePagination;
