import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPaymentCardFormData {
  cardholderName: string;
  //
  errors: {
    cardholderName?: string | null;
  };
}
export interface ICompanyBillingSliceState {
  changePaymentCard: IPaymentCardFormData;
  startSubscription: IPaymentCardFormData;
}

const defaultFormDataErrors = () => ({
  cardholderName: null,
});

export const defaultPaymentCardSliceFormData = () => ({
  cardholderName: "",
  //
  errors: defaultFormDataErrors(),
});

const initialState: ICompanyBillingSliceState = {
  changePaymentCard: defaultPaymentCardSliceFormData(),
  startSubscription: defaultPaymentCardSliceFormData(),
};

const companyBillingSlice = createSlice({
  name: "companyBilling",
  initialState,
  reducers: {
    changeChangePaymentCardForm: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action: PayloadAction<IPaymentCardFormData | { [key: string]: any }>
    ) => {
      state.changePaymentCard = {
        ...state.changePaymentCard,
        ...action.payload,
        // errors
        errors: {
          ...state.changePaymentCard.errors,
          // remove error from any property that was updated
          ...Object.keys(action.payload).reduce(
            (acc, curr) => ({ ...acc, [curr]: undefined }),
            {}
          ),
        },
      };
    },
    updateChangePaymentCardFormErrors: (state, action) => {
      state.changePaymentCard = {
        ...state.changePaymentCard,
        errors: action.payload,
      };
    },
    //
    changeStartSubscriptionCardForm: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action: PayloadAction<IPaymentCardFormData | { [key: string]: any }>
    ) => {
      state.startSubscription = {
        ...state.startSubscription,
        ...action.payload,
        // errors
        errors: {
          ...state.startSubscription.errors,
          // remove error from any property that was updated
          ...Object.keys(action.payload).reduce(
            (acc, curr) => ({ ...acc, [curr]: undefined }),
            {}
          ),
        },
      };
    },
    updateStartSubscriptionCardFormErrors: (state, action) => {
      state.startSubscription = {
        ...state.startSubscription,
        errors: action.payload,
      };
    },
    resetChangePaymentCardForm: (state) => {
      state.changePaymentCard = initialState.changePaymentCard;
    },
  },
});

export const {
  changeChangePaymentCardForm,
  updateChangePaymentCardFormErrors,
  changeStartSubscriptionCardForm,
  updateStartSubscriptionCardFormErrors,
  resetChangePaymentCardForm,
} = companyBillingSlice.actions;

export default companyBillingSlice.reducer;
