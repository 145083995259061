import { useEffect } from "react";
import { Row, Spinner, Table } from "react-bootstrap";
import { useAppSelector } from "../../../store/redux-hooks";
import { useLazyGetInvoicesQuery } from "../../../store/slices/apiSlice";
import { currencyDisplayByCode } from "../../../util/functions";
import {
  generateStripeInvoicePaymentStatusTagClassName,
  transformStripeInvoicePaymentStatus,
} from "../../../util/functions/stripeFunctions";

export default () => {
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);

  const [getCompanyInvoices, { data, isFetching }] = useLazyGetInvoicesQuery();

  useEffect(() => {
    if (loadedCompany?.id) {
      getCompanyInvoices({ urlArgs: { companyId: loadedCompany.id } });
    }
  }, [loadedCompany?.id, getCompanyInvoices]);

  return (
    <>
      {isFetching ? (
        // fetching invoices
        <Row className="justify-content-center">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </Row>
      ) : data?.data?.length ? (
        // invoices found, display them
        <Table borderless hover responsive>
          <thead>
            <tr>
              <th>Issued</th>
              <th>Status</th>
              <th>Amount</th>
              <th className="text-end">Payment Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((invoice) => (
              <tr key={invoice.id}>
                <td>{new Date(invoice.created * 1000).toLocaleDateString()}</td>
                <td>
                  {invoice.invoice_pdf ? (
                    <a
                      href={invoice.invoice_pdf}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download Invoice
                    </a>
                  ) : (
                    "Pending"
                  )}
                </td>
                <td>{`${currencyDisplayByCode(invoice.currency)}${
                  invoice.total / 100
                }`}</td>
                <td className="text-end">
                  <span
                    className={generateStripeInvoicePaymentStatusTagClassName(
                      invoice.status
                    )}
                  >
                    {transformStripeInvoicePaymentStatus(invoice.status)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        // invoices fetched successfully, but none exist
        <p className="mt-3">You don't have any issued invoices.</p>
      )}
    </>
  );
};
