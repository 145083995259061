import { createColumnHelper } from "@tanstack/react-table";
import { IGetCompanyUserBaseResponse } from "../../../store/slices/apiSlice";
import { generateRoleDisplayValue } from "../../../util/functions";

const columnHelper = createColumnHelper<IGetCompanyUserBaseResponse>();

export const usersTableColumns = [
  columnHelper.accessor("firstName", {
    header: "User",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("email", {
    header: "Email",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor(
    (row) => generateRoleDisplayValue(row.companies?.[0]?.user?.role),
    {
      header: "Type",
      id: "role",
      cell: (info) => info.getValue(),
    }
  ),
  columnHelper.accessor(
    (row) =>
      generateRoleDisplayValue(row.companies?.[0]?.accountsAndPayroll?.role),
    {
      header: "Accounts",
      enableSorting: false,
      cell: (info) => info.getValue(),
    }
  ),
  columnHelper.accessor(
    (row) => generateRoleDisplayValue(row.companies?.[0]?.projects?.role),
    {
      header: "Projects",
      enableSorting: false,
      cell: (info) => info.getValue(),
    }
  ),
  columnHelper.accessor(
    (row) => generateRoleDisplayValue(row.companies?.[0]?.proposal?.role),
    {
      header: "Proposals",
      enableSorting: false,
      cell: (info) => info.getValue(),
    }
  ),
];
