import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { ReactComponent as IconSearch } from "../../../assets/icons/search.svg";

import { RoutePaths } from "../../../routes/routes";
import { useAppSelector } from "../../../store/redux-hooks";
import { useLazyGetCompanyUsersQuery } from "../../../store/slices/apiSlice";
import {
  DEFAULT_SORT_AND_PAGINATION,
  IDefaultSortAndPagination,
  Order,
} from "../../../util/constants/constants";
import { CompanyUserRole } from "../../../util/constants/userForm/userFormConstants";
import AlertHandler from "../../common/AlertHandler";
import ReactTable from "../../common/table/ReactTable";
import UsersFilters, {
  DEFAULT_FILTERS,
  IGetUsersFilters,
} from "./UsersFilters";
import { usersTableColumns } from "./UsersTableColumns";

enum CompanyUsersTabs {
  ALL = "ALL",
  MEMBERS = "MEMBERS",
  GUESTS = "GUESTS",
}

const determineUserRolesFilter = (key: string | null) => {
  switch (key) {
    case CompanyUsersTabs.MEMBERS:
      return [CompanyUserRole.MEMBER];
    case CompanyUsersTabs.GUESTS:
      return [CompanyUserRole.GUEST];
    default:
      return [];
  }
};

export default () => {
  const [showFilters, setShowFilters] = useState(false);

  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);
  // const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [queryFilters, setQueryFilters] =
    useState<IGetUsersFilters>(DEFAULT_FILTERS);

  const [querySortAndPagination, setQuerySortAndPagination] =
    useState<IDefaultSortAndPagination>(DEFAULT_SORT_AND_PAGINATION);

  const [getCompanyUsers, { data, isFetching }] = useLazyGetCompanyUsersQuery();

  useEffect(() => {
    if (loadedCompany) {
      const filters = {
        ...(queryFilters.firstNameOrEmailContains && {
          firstNameOrEmailContains: queryFilters.firstNameOrEmailContains,
        }),
        ...(queryFilters.userRoles?.length && {
          userRoles: queryFilters.userRoles,
        }),
      };

      const params = {
        ...((filters?.firstNameOrEmailContains || filters?.userRoles) && {
          filters,
        }),
        ...(querySortAndPagination.pageIndex && {
          skip:
            querySortAndPagination.pageIndex * querySortAndPagination.pageSize,
        }),
        ...(querySortAndPagination.pageSize && {
          limit: querySortAndPagination.pageSize,
        }),
        ...(querySortAndPagination?.sorting?.[0] && {
          sort: [
            {
              column: querySortAndPagination.sorting[0].id,
              order: querySortAndPagination.sorting[0].desc
                ? Order.DESC
                : Order.ASC,
            },
          ],
        }),
      };
      getCompanyUsers({
        urlArgs: { companyId: loadedCompany.id },
        params,
      });
    }
  }, [loadedCompany, getCompanyUsers, querySortAndPagination, queryFilters]);

  const onChange = useCallback(
    ({ pageIndex, pageSize, sorting }: IDefaultSortAndPagination) => {
      setQuerySortAndPagination({ pageIndex, pageSize, sorting });
    },
    [setQuerySortAndPagination]
  );

  return (
    <Container fluid id="users">
      <Row className="mb-4">
        <Col className="text-heading fw-medium">
          <span>Users</span>
        </Col>
        <Col xs="auto">
          <Row className="h-100 align-items-center">
            <Col xs="auto">
              <Button
                variant="outline-dark"
                onClick={(e) => {
                  setShowFilters(!showFilters);
                }}
              >
                <IconSearch />
              </Button>
            </Col>
            <Col xs="auto">
              <Button onClick={() => navigate(RoutePaths.USERS_CREATE)}>
                Create User
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <AlertHandler currentRoute={RoutePaths.USERS} />
      {showFilters && (
        <Row>
          <Col>
            <UsersFilters
              queryFilters={queryFilters}
              setQueryFilters={setQueryFilters}
            />
          </Col>
        </Row>
      )}
      <Row className="mb-3">
        <Col>
          <Tabs
            defaultActiveKey={CompanyUsersTabs.ALL}
            transition={false}
            onSelect={(key) =>
              setQueryFilters((state) => ({
                ...state,
                userRoles: determineUserRolesFilter(key),
              }))
            }
          >
            <Tab eventKey={CompanyUsersTabs.ALL} title={`All`}>
              <></>
            </Tab>
            <Tab eventKey={CompanyUsersTabs.MEMBERS} title={`Members`}>
              <></>
            </Tab>
            <Tab eventKey={CompanyUsersTabs.GUESTS} title={`Guests`}>
              <></>
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactTable
            data={data?.result || []}
            totalRows={data?.total || 0}
            loading={isFetching}
            columns={usersTableColumns}
            filters={queryFilters}
            onChange={onChange}
            onRowClick={(data) => {
              navigate(RoutePaths.USER.replace(":id", data._id));
            }}
            enablePagination
            enableSorting
          />
        </Col>
      </Row>
    </Container>
  );
};
