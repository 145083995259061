import { useEffect, useLayoutEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { APIResponseExtraAction } from "../../../errors/apiErrors";

import { RoutePaths } from "../../../routes/routes";
import { useAppDispatch, useAppSelector } from "../../../store/redux-hooks";
import {
  ICompanyBaseSchema,
  useSelectCompanyMutation,
} from "../../../store/slices/apiSlice";
import {
  changeCompanyForm,
  ICompanyFormData,
  defaultCompanySliceFormData,
  setLoadedCompany,
} from "../../../store/slices/companySlice";
import {
  companyTypesDropdown,
  ICompanyType,
} from "../../../util/constants/companyTypes";
import {
  AUTH_COOKIE_NAME,
  NAVIGATE_GO_BACK,
  PROJECTS_APP_URL,
  ONBOARDING_PROPOSAL_URL,
} from "../../../util/constants/constants";
import {
  currenciesDropdown,
  ICurrency,
} from "../../../util/constants/currencies";
import { vatRegistrationTypesDropdown } from "../../../util/constants/vatRegistrationTypes";
import { transformCompanyResponseToLoadedCompany } from "../../../util/functions";
import RequiredFieldBadge from "../../common/RequiredFieldBadge";
import CustomSelect from "../../common/Select";
import useUpsertCompanyMutation from "./useUpsertCompanyMutation";
import cookies from "../../config/cookies";
import ReactGA from "../../../reactGA";

export class CompanyEditState {
  static readonly SIGN_UP_CREATE = new CompanyEditState(
    "SIGN_UP_CREATE",
    "Complete Sign Up"
  );
  static readonly CREATE = new CompanyEditState("CREATE", "Save");
  static readonly EDIT = new CompanyEditState("EDIT", "Save");

  // private to disallow creating other instances of this type
  private constructor(
    public readonly key: string,
    public readonly submitActionTextValue: string
  ) {}

  toString() {
    return this.key;
  }
}

const transformCompanyDataForAPIRequestBody = ({
  country,
  currency,
  companyType,
  isVatRegistered,
  name,
  addressLine1,
  addressLine2,
  companyNumber,
  phoneNumber,
  taxNumber,
  vatNumber,
  website,
}: ICompanyFormData): ICompanyBaseSchema => {
  return {
    country: country?.countryCode,
    homeCurrency: currency?.currencyCode,
    type: companyType?.value,
    isVatRegistered: isVatRegistered?.value,
    name,
    addressLine1,
    addressLine2,
    companyNumber,
    phoneNumber,
    taxNumber,
    vatNumber,
    website,
  };
};

export default () => {
  const companyData = useAppSelector((state) => state.company.formData);
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);
  const dispatch = useAppDispatch();

  const [editState, setEditState] = useState<CompanyEditState>();

  const location = useLocation();

  const navigate = useNavigate();

  const {
    upsertCompanyMutation,
    data,
    isLoading,
    isSuccess,
    requestErrors,
    setRequestErrors,
  } = useUpsertCompanyMutation(editState);

  const [selectCompany] = useSelectCompanyMutation();

  useEffect(() => {
    if (
      requestErrors?.extra?.action ===
      APIResponseExtraAction.UNAUTHORIZED_REDIRECT
    ) {
      navigate(RoutePaths.LOGIN, {
        state: {
          alert: {
            variant: "warning",
            content: "Session expired. Please login.",
          },
        },
      });
    }
  }, [requestErrors?.extra?.action, navigate]);

  useEffect(() => {
    if (isSuccess)  {
      switch (editState) {
        case CompanyEditState.CREATE:
          // fallsthrough
        case CompanyEditState.SIGN_UP_CREATE:
          ReactGA.event({
            action: "form_submission",
            label: `hub${
                editState === CompanyEditState.SIGN_UP_CREATE ? "-signup" : ""
            }-create-company-success`,
            category: `hub${
                editState === CompanyEditState.SIGN_UP_CREATE ? "-signup" : ""
            }-create-company`,
          });
          // set newly created company as currently selected and redirect user to home after login


          if (data?._id) {
            dispatch(
                setLoadedCompany(transformCompanyResponseToLoadedCompany(data))
            );
            selectCompany({
              body: {
                companyId: data._id as string,
              },
            })
                .unwrap()
                .then(() => {
                  setTimeout(() => {
                    if (editState === CompanyEditState.SIGN_UP_CREATE && ONBOARDING_PROPOSAL_URL.length) {
                      window.open(ONBOARDING_PROPOSAL_URL, '_blank');
                    }
                    const accessToken =
                        cookies.get(AUTH_COOKIE_NAME)?.accessToken;
                    window.location.href = new URL(
                        `?accessToken=${accessToken}`,
                        PROJECTS_APP_URL
                    ).toString();
                  }, 300);
                });
          }
          break;
        case CompanyEditState.EDIT:
          // currently loaded company was edited, update store with latest response data
          if (data?._id) {
            dispatch(
                setLoadedCompany(transformCompanyResponseToLoadedCompany(data))
            );
          }
          break;
        default:
          // do nothing
      }
    }
  }, [editState, isSuccess, navigate, data, dispatch, selectCompany]);

  useLayoutEffect(() => {
    switch (location.pathname) {
      case RoutePaths.COMPANY_CREATE:
        dispatch(changeCompanyForm(defaultCompanySliceFormData()));
        setEditState(CompanyEditState.CREATE);
        break;
      case RoutePaths.SIGNUP_COMPANY_CREATE:
        dispatch(changeCompanyForm(defaultCompanySliceFormData()));
        setEditState(CompanyEditState.SIGN_UP_CREATE);
        break;
      case RoutePaths.SETTINGS_COMPANY:
        dispatch(
          changeCompanyForm({
            ...loadedCompany,
          })
        );
        setEditState(CompanyEditState.EDIT);
        break;
      default:
        setEditState(CompanyEditState.EDIT);
    }
  }, [location, dispatch, loadedCompany]);

  return (
    <Form
      style={{ minHeight: "725px" }}
      className={`my-auto ${
        editState === CompanyEditState.SIGN_UP_CREATE
          ? "max-width-560px"
          : "max-width-medium"
      }`}
    >
      {editState === CompanyEditState.EDIT && isSuccess && !isLoading && (
        <Row>
          <Col>
            <Alert variant="success">
              Company information successfully updated!
            </Alert>
          </Col>
        </Row>
      )}
      {requestErrors?.message && (
        <Row>
          <Col>
            <Alert variant="danger">{requestErrors?.message}</Alert>
          </Col>
        </Row>
      )}
      {editState === CompanyEditState.SIGN_UP_CREATE && (
        <Row className="justify-content-start mb-4">
          <Col className="text-heading fw-medium">
            <span className="color-greyish">Sign Up: </span>
            <span>Company Details</span>
          </Col>
        </Row>
      )}
      {/* NOTE: commented since user is allowed to create Ireland companies only */}
      {/* <Row className="mb-3">
        <Form.Group>
          <Form.Label className="custom-form-label">
            Country
            <RequiredFieldBadge />
          </Form.Label>
          <CustomSelect<ICountry>
            name="countryDropdown"
            isDisabled={editState === CompanyEditState.EDIT}
            options={countriesDropdown}
            getOptionLabel={(option) => option.countryName}
            getOptionValue={(option) => option.countryCode}
            value={companyData.country}
            onChange={(selected) =>
              dispatch(changeCompanyForm({ country: selected }))
            }
            className={companyData.errors?.country ? "customselect-error" : ""}
          />
          <Form.Control
            type="hidden"
            isInvalid={Boolean(companyData.errors?.country)}
          />
          <Form.Control.Feedback type="invalid">
            {companyData.errors?.country}
          </Form.Control.Feedback>
        </Form.Group>
      </Row> */}
      <Row className="mb-4">
        <Col sm={12} md={6} className="mb-md-0 mb-4">
          {companyData.country?.countryCode === "IE" ? (
            <Form.Group>
              <Form.Label className="custom-form-label">
                I am a...
                <RequiredFieldBadge />
              </Form.Label>
              <CustomSelect<ICompanyType>
                key="companyType"
                name="companyTypeDropdown"
                isDisabled={editState === CompanyEditState.EDIT}
                options={companyTypesDropdown}
                value={companyData.companyType}
                onChange={(selected) =>
                  dispatch(changeCompanyForm({ companyType: selected }))
                }
                className={
                  companyData.errors?.companyType ? "customselect-error" : ""
                }
              />
              <Form.Control
                type="hidden"
                isInvalid={Boolean(companyData.errors?.companyType)}
                autoComplete="new-companyType"
              />
              <Form.Control.Feedback type="invalid">
                {companyData.errors?.companyType}
              </Form.Control.Feedback>
            </Form.Group>
          ) : (
            <Form.Group>
              <Form.Label className="custom-form-label">
                My currency is...
                <RequiredFieldBadge />
              </Form.Label>
              <CustomSelect<ICurrency>
                key="currency"
                name="currencyDropdown"
                isDisabled={editState === CompanyEditState.EDIT}
                options={currenciesDropdown}
                value={companyData.currency}
                getOptionLabel={(option) =>
                  option.currencyCode +
                  (option.currencySymbol
                    ? " (" + option.currencySymbol + ")"
                    : "")
                }
                getOptionValue={(option) => option.currencyCode}
                onChange={(selected) =>
                  dispatch(changeCompanyForm({ currency: selected }))
                }
                className={
                  companyData.errors?.currency ? "customselect-error" : ""
                }
              />
              <Form.Control
                type="hidden"
                isInvalid={Boolean(companyData.errors?.currency)}
              />
              <Form.Control.Feedback type="invalid">
                {companyData.errors?.currency}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Col>
        <Col sm={12} md={6}>
          <Form.Group>
            <Form.Label className="custom-form-label">
              My business is...
              <RequiredFieldBadge />
            </Form.Label>
            <CustomSelect
              name="vatRegistrationDropdown"
              isDisabled={editState === CompanyEditState.EDIT}
              options={vatRegistrationTypesDropdown}
              getOptionLabel={(option) =>
                option?.label +
                (companyData.country?.countryCode === "IE"
                  ? "VAT"
                  : "Sales Tax")
              }
              value={companyData.isVatRegistered}
              onChange={(selected) =>
                dispatch(changeCompanyForm({ isVatRegistered: selected }))
              }
              className={
                companyData.errors?.isVatRegistered ? "customselect-error" : ""
              }
            />
            <Form.Control
              type="hidden"
              isInvalid={Boolean(companyData.errors?.isVatRegistered)}
            />
            <Form.Control.Feedback type="invalid">
              {companyData.errors?.isVatRegistered}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Form.Group>
            <Form.Label className="custom-form-label">
              Company name
              <RequiredFieldBadge />
            </Form.Label>
            <Form.Control
              type="text"
              name="company-name"
              value={companyData.name || ""}
              onChange={({ target: { value } }) =>
                dispatch(changeCompanyForm({ name: value }))
              }
              isInvalid={Boolean(companyData.errors?.name)}
            />
            <Form.Control.Feedback type="invalid">
              {companyData.errors?.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <Form.Group className="mb-4">
            <Form.Label className="custom-form-label">
              Address line 1
            </Form.Label>
            <Form.Control
              type="text"
              name="address1"
              value={companyData.addressLine1 || ""}
              onChange={({ target: { value } }) =>
                dispatch(changeCompanyForm({ addressLine1: value }))
              }
              isInvalid={Boolean(companyData.errors?.addressLine1)}
            />
            <Form.Control.Feedback type="invalid">
              {companyData.errors?.addressLine1}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} lg={6}>
          <Form.Group className="mb-4">
            <Form.Label className="custom-form-label">
              Address line 2
            </Form.Label>
            <Form.Control
              type="text"
              name="address2"
              value={companyData.addressLine2 || ""}
              onChange={({ target: { value } }) =>
                dispatch(changeCompanyForm({ addressLine2: value }))
              }
              isInvalid={Boolean(companyData.errors?.addressLine2)}
            />
            <Form.Control.Feedback type="invalid">
              {companyData.errors?.addressLine2}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <Form.Group className="mb-4">
            <Form.Label className="custom-form-label">
              Phone number
              <RequiredFieldBadge />
            </Form.Label>
            <Form.Control
              type="tel"
              name="phoneNumber"
              value={companyData.phoneNumber || ""}
              onChange={({ target: { value } }) =>
                dispatch(changeCompanyForm({ phoneNumber: value }))
              }
              isInvalid={Boolean(companyData.errors?.phoneNumber)}
            />
            <Form.Control.Feedback type="invalid">
              {companyData.errors?.phoneNumber}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} lg={6}>
          <Form.Group className="mb-4">
            <Form.Label className="custom-form-label">Website</Form.Label>
            <Form.Control
              type="text"
              name="website"
              value={companyData.website || ""}
              onChange={({ target: { value } }) =>
                dispatch(changeCompanyForm({ website: value }))
              }
              isInvalid={Boolean(companyData.errors?.website)}
            />
            <Form.Control.Feedback type="invalid">
              {companyData.errors?.website}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      {editState === CompanyEditState.EDIT && (<Row className="mb-4">
        <Col xs={12} lg={6}>
          <Form.Group>
            <Form.Label className="custom-form-label">
              Company number
              <RequiredFieldBadge />
            </Form.Label>
            <Form.Control
              type="text"
              name="companyNumber"
              value={companyData.companyNumber || ""}
              onChange={({ target: { value } }) =>
                dispatch(changeCompanyForm({ companyNumber: value }))
              }
              isInvalid={Boolean(companyData.errors?.companyNumber)}
            />
            <Form.Control.Feedback type="invalid">
              {companyData.errors?.companyNumber}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} lg={6}>
          <Form.Group>
            <Form.Label className="custom-form-label">
              Tax number
              <RequiredFieldBadge />
            </Form.Label>
            <Form.Control
              type="text"
              name="taxNumber"
              value={companyData.taxNumber || ""}
              onChange={({ target: { value } }) =>
                dispatch(changeCompanyForm({ taxNumber: value }))
              }
              isInvalid={Boolean(companyData.errors?.taxNumber)}
            />
            <Form.Control.Feedback type="invalid">
              {companyData.errors?.taxNumber}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>)}
      {editState === CompanyEditState.EDIT && companyData.isVatRegistered?.value && (
        <Row className="mb-5">
          <Col xs={12} lg={6}>
            <Form.Group>
              <Form.Label className="custom-form-label">
                {companyData.country?.countryCode === "IE"
                  ? "VAT "
                  : "Sales Tax "}
                number
                <RequiredFieldBadge />
              </Form.Label>
              <Form.Control
                type="text"
                name="VATNumber"
                value={companyData.vatNumber || ""}
                onChange={({ target: { value } }) =>
                  dispatch(changeCompanyForm({ vatNumber: value }))
                }
                isInvalid={Boolean(companyData.errors?.vatNumber)}
              />
              <Form.Control.Feedback type="invalid">
                {(companyData.country?.countryCode === "IE"
                  ? "VAT "
                  : "Sales Tax ") + companyData.errors?.vatNumber}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      )}

      {/*
       * ACTIONS
       */}
      <Row
        className={
          "mb-4 " +
          (editState !== CompanyEditState.SIGN_UP_CREATE
            ? "justify-content-end"
            : "justify-content-center")
        }
      >
        {editState !== CompanyEditState.SIGN_UP_CREATE && (
          <Col xs="auto">
            <Button
              variant="secondary"
              type="button"
              onClick={(event) => {
                event.preventDefault();
                navigate(NAVIGATE_GO_BACK);
              }}
            >
              Cancel
            </Button>
          </Col>
        )}
        <Col xs="auto">
          <Button
            variant="primary"
            type="submit"
            disabled={isLoading}
            onClick={(event) => {
              event.preventDefault();

              // TODO: remove condition once editing is properly supported
              if (editState === CompanyEditState.EDIT && !companyData.id) {
                // this shouldn't happen
                setRequestErrors({
                  message: "Something went wrong. Please try again later.",
                });
              } else {
                upsertCompanyMutation({
                  body: transformCompanyDataForAPIRequestBody(companyData),
                  ...(editState === CompanyEditState.EDIT &&
                    companyData.id && {
                      urlArgs: { companyId: companyData.id },
                    }),
                });
              }
            }}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              editState?.submitActionTextValue
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
