import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReactGA from "../../../reactGA";
import { RoutePaths } from "../../../routes/routes";
import { useAppSelector } from "../../../store/redux-hooks";
import { useCancelSubscriptionMutation } from "../../../store/slices/apiSlice";

import { CANCEL_SUBSCRIPTION_CONFIRMATION_KEYWORD } from "../../../util/constants/constants";

const DEFAULT_INPUT_VALUE = "";

export default ({
  show,
  toggleModal,
  //
  subscriptionId,
}: {
  show: boolean;
  toggleModal: () => void;
  //
  subscriptionId?: string;
}) => {
  const [removeBoxValue, setRemoveBoxValue] = useState(DEFAULT_INPUT_VALUE);

  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);

  const navigate = useNavigate();

  const [
    cancelSubscriptionMutation,
    {
      isSuccess,
      isLoading,
      isUninitialized,
      reset: resetCancelSubscriptionMutation,
    },
  ] = useCancelSubscriptionMutation();

  useEffect(() => {
    if (!isUninitialized && isSuccess) {
      ReactGA.event({
        action: "form_submission",
        label: "hub-subscription-cancel-success",
        category: "hub-subscription-cancel",
      });
    }
  }, [isUninitialized, isSuccess]);

  useEffect(() => {
    // if modal closes, reset input value, mutation result and redirect user back to user list
    if (!show) {
      if (!isUninitialized) {
        navigate(RoutePaths.BILLING);
      }

      setRemoveBoxValue(DEFAULT_INPUT_VALUE);
      resetCancelSubscriptionMutation();
    }
  }, [show, resetCancelSubscriptionMutation, navigate, isUninitialized]);

  return (
    <Modal
      id="cancel-subscription-modal"
      show={show}
      onHide={toggleModal}
      centered
      className="custom-share-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Cancel Subscription</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          {isLoading ? (
            <Container>
              <Row className="justify-content-center">
                <Spinner animation="border" role="status" />
              </Row>
            </Container>
          ) : isSuccess ? (
            <Alert variant="success">You have successfully unsubscribed.</Alert>
          ) : (
            <Container fluid>
              <Row className="mb-3 mt-3">
                <Col>
                  Please confirm you wish to cancel subscription for company{" "}
                  <strong>{loadedCompany?.name || "Unknown"}</strong> by writing
                  <span className="text-highlight-lightgray">
                    {` ${CANCEL_SUBSCRIPTION_CONFIRMATION_KEYWORD} `}
                  </span>
                  in box below:
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={removeBoxValue}
                      placeholder={CANCEL_SUBSCRIPTION_CONFIRMATION_KEYWORD}
                      onChange={({ target: { value } }) =>
                        setRemoveBoxValue(value)
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row className="justify-content-end m-0">
            {!isSuccess && (
              <Col xs="auto">
                <Button
                  type="submit"
                  variant="primary"
                  onClick={(event) => {
                    event.preventDefault();

                    if (loadedCompany?.id && subscriptionId) {
                      cancelSubscriptionMutation({
                        urlArgs: { companyId: loadedCompany.id },
                        body: { subscriptionId: subscriptionId },
                      });
                    }
                  }}
                  disabled={
                    isLoading ||
                    removeBoxValue.toLowerCase() !==
                      CANCEL_SUBSCRIPTION_CONFIRMATION_KEYWORD.toLowerCase()
                  }
                >
                  Cancel Subscription
                </Button>
              </Col>
            )}
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
