import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { RoutePaths } from "../../../routes/routes";

import { useAppDispatch, useAppSelector } from "../../../store/redux-hooks";
import {
  updateSignUpForm,
  updateSignUpFormErrors,
} from "../../../store/slices/authSlice";
import { getNestedObject } from "../../../util/functions";
import { useSignUpMutation } from "../../../store/slices/apiSlice";
import { processAuthRequestErrors } from "../../../errors/authErrors";
import { IErrorAPIRequest } from "../../../errors/apiErrors";
import useAuthenticatedUserRedirect from "../../../hooks/useAuthenticatedUserRedirect";
import { Eye, EyeSlash } from "react-bootstrap-icons";

import useValidator from "../../../hooks/useValidator";
import validateSignUpData from "../../../util/validate/validateSignUpData";
import { MAIN_PASSWORD_FIELD_PLACEHOLDER_TEXT } from "../../../util/constants/constants";
import RequiredFieldBadge from "../../common/RequiredFieldBadge";
import CustomSelect from "../../common/Select";
import {
  countriesDropdown,
  countryIreland,
  ICountry,
} from "../../../util/constants/countries";
import ReactGA from "../../../reactGA";

export default () => {
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [showPasswords, setShowPasswords] = useState({
    password: false,
    repeatPassword: false,
  });

  const toggleShowPassword = (field: "password" | "repeatPassword") => () => {
    setShowPasswords({ ...showPasswords, [field]: !showPasswords[field] });
  };

  useAuthenticatedUserRedirect();

  const navigate = useNavigate();

  const location = useLocation();

  const [requestErrors, setRequestErrors] = useState<IErrorAPIRequest>();

  const { validateErrors, validate } = useValidator(
    validateSignUpData,
    authState.signUpData
  );

  const [registerQuery, { isSuccess, isLoading, error: loginError }] =
    useSignUpMutation();

  useEffect(() => {
    if (isSuccess) {
      ReactGA.event({
        action: "form_submission",
        label: "hub-signup-success",
        category: "hub-signup",
      });
      navigate(RoutePaths.SIGNUP_COMPANY_CREATE);
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processAuthRequestErrors(loginError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateSignUpFormErrors(formValidationErrors));
  }, [loginError, dispatch]);

  useEffect(() => {
    const emailFromLocationState = getNestedObject(location, [
      "state",
      "email",
    ]);

    emailFromLocationState &&
      !authState.signUpData.email &&
      !authState.signUpData.hasUserModifiedData &&
      dispatch(
        updateSignUpForm({
          email: emailFromLocationState,
        })
      );
  }, [
    dispatch,
    location,
    authState.signUpData.email,
    authState.signUpData.hasUserModifiedData,
  ]);

  return (
    <Form className="max-width-560px my-auto" style={{ minHeight: "725px" }}>
      <Row className="justify-content-start mb-4">
        <Col className="text-heading fw-medium">
          <span>Sign Up</span>
        </Col>
      </Row>
      <Row className="mb-4">
        <Form.Group>
          <Form.Label className="custom-form-label">
            Country
            <RequiredFieldBadge />
          </Form.Label>
          <CustomSelect<ICountry>
            name="countryDropdown"
            value={countryIreland}
            options={countriesDropdown}
            getOptionLabel={(option) => option.countryName}
            getOptionValue={(option) => option.countryCode}
            onChange={(selected) => {
              const selectedCountry = selected as ICountry;
              if (selectedCountry?.countryCode !== countryIreland.countryCode) {
                window.location.href =
                  process.env.REACT_APP_INTERNATIONAL_BULLET_APP_URL +
                  `/signup/create.page?country=${selectedCountry.countryCode}`;
              }
            }}
          />
        </Form.Group>
      </Row>
      <Row className="mb-4">
        <Form.Group>
          <Form.Label className="custom-form-label">
            First name
            <RequiredFieldBadge />
          </Form.Label>
          <Form.Control
            type="Label"
            tabIndex={1}
            value={authState.signUpData.firstName}
            onChange={({ target: { value } }) =>
              dispatch(updateSignUpForm({ firstName: value }))
            }
            onBlur={() => {
              validate("firstName");
            }}
            isInvalid={Boolean(
              authState.signUpData.errors?.firstName || validateErrors.firstName
            )}
          />
          <Form.Control.Feedback type="invalid">
            {authState.signUpData.errors?.firstName || validateErrors.firstName}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-4">
        <Form.Group>
          <Form.Label className="custom-form-label">
            Email address
            <RequiredFieldBadge />
          </Form.Label>
          <Form.Control
            type="email"
            tabIndex={1}
            value={authState.signUpData.email}
            onChange={({ target: { value } }) =>
              dispatch(updateSignUpForm({ email: value }))
            }
            onBlur={() => {
              validate("email");
            }}
            isInvalid={Boolean(
              authState.signUpData.errors?.email || validateErrors.email
            )}
          />
          <Form.Control.Feedback type="invalid">
            {authState.signUpData.errors?.email || validateErrors.email}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-4">
        <Form.Group>
          <Form.Label className="custom-form-label">
            Choose a password
            <RequiredFieldBadge />
          </Form.Label>
          <InputGroup>
            <Form.Control
              aria-describedby="basic-addon1"
              type={showPasswords.password ? "text" : "password"}
              placeholder={MAIN_PASSWORD_FIELD_PLACEHOLDER_TEXT}
              tabIndex={1}
              value={authState.signUpData.password}
              onChange={({ target: { value } }) =>
                dispatch(updateSignUpForm({ password: value }))
              }
              onBlur={() => {
                validate("password");
              }}
              isInvalid={Boolean(
                authState.signUpData.errors?.password || validateErrors.password
              )}
            />
            <Button
              variant="outline-secondary"
              id="toggle-password"
              type="button"
              onClick={toggleShowPassword("password")}
              // https://github.com/twbs/bootstrap/issues/36049
              className="border-radius-right-8px"
            >
              {showPasswords.password ? (
                <Eye size="1.7em" />
              ) : (
                <EyeSlash size="1.7em" />
              )}
            </Button>
            <Form.Control.Feedback type="invalid">
              {authState.signUpData.errors?.password || validateErrors.password}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row className="mb-4">
        <Form.Group>
          <Form.Label className="custom-form-label">
            Repeat password
            <RequiredFieldBadge />
          </Form.Label>
          <InputGroup>
            <Form.Control
              type={showPasswords.repeatPassword ? "text" : "password"}
              tabIndex={1}
              value={authState.signUpData.repeatPassword}
              onChange={({ target: { value } }) =>
                dispatch(updateSignUpForm({ repeatPassword: value }))
              }
              onBlur={() => {
                validate("repeatPassword");
              }}
              isValid={
                Boolean(authState.signUpData.repeatPassword) &&
                authState.signUpData.password ===
                  authState.signUpData.repeatPassword
              }
              isInvalid={Boolean(
                authState.signUpData.password !==
                  authState.signUpData.repeatPassword ||
                  authState.signUpData.errors?.repeatPassword ||
                  validateErrors.repeatPassword
              )}
            />
            <Button
              variant="outline-secondary"
              id="toggle-repeat-password"
              type="button"
              onClick={toggleShowPassword("repeatPassword")}
              // https://github.com/twbs/bootstrap/issues/36049
              className="border-radius-right-8px"
            >
              {showPasswords.repeatPassword ? (
                <Eye size="1.7em" />
              ) : (
                <EyeSlash size="1.7em" />
              )}
            </Button>
            <Form.Control.Feedback type="invalid">
              Passwords do not match
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      {/*
       * MAIN ACTION
       */}
      <Row className="mb-5">
        <Col xs="auto" className="mx-auto text-mini custom-text-mini">
          {"By clicking Get Started, you agree to our "}
          <a
            href="https://static.bullethq.com/terms-and-conditions"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
        </Col>
      </Row>
      <Row className="mb-3">
        <Button
          variant="dark"
          type="submit"
          className="w-auto mx-auto"
          tabIndex={3}
          disabled={isLoading}
          onClick={(event) => {
            event.preventDefault();
            validate();

            if (!validateSignUpData(authState.signUpData).valid) {
              return;
            }

            if (
              authState.signUpData.password ===
                authState.signUpData.repeatPassword &&
              authState.signUpData.repeatPassword
            ) {
              registerQuery({
                firstName: authState.signUpData.firstName,
                email: authState.signUpData.email,
                password: authState.signUpData.password,
              });
            } else {
              setRequestErrors({
                message: "Please fill the form fields.",
              });
            }
          }}
        >
          {isLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Get Started"
          )}
        </Button>
      </Row>
      {requestErrors?.message && (
        <Row>
          <Alert variant="danger">{requestErrors?.message}</Alert>
        </Row>
      )}
      {/*
       * DIVIDER
       */}
      <Row className="mb-1">
        <Col>
          <hr />
        </Col>
      </Row>
      <Row className="justify-content-center text-tiny custom-text-tiny">
        <Col xs="auto" className=" mx-auto">
          {`Already have an account? `}
          <Link
            to={RoutePaths.LOGIN}
            state={{ email: authState.signUpData.email }}
            replace
            tabIndex={5}
          >
            Login
          </Link>
        </Col>
      </Row>
    </Form>
  );
};
