import { useMemo } from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { RoutePaths } from "../../../routes/routes";
import { useAppSelector } from "../../../store/redux-hooks";
import {
  AccountsAndPayrollPermission,
  AccountsAndPayrollRole,
  CompanyUserPermission,
  CompanyUserRole,
  ProposalRole,
} from "../../../util/constants/userForm/userFormConstants";

export const determineHomeNavigationEventKey = (pathname: string) => {
  if (pathname.includes(RoutePaths.USERS)) {
    return RoutePaths.USERS;
  }

  if (pathname.includes(RoutePaths.BILLING)) {
    return RoutePaths.BILLING;
  }

  if (pathname.includes(RoutePaths.CLIENTS)) {
    return RoutePaths.CLIENTS;
  }

  return pathname;
};

export default () => {
  const loadedCompanyUserRolesAndPermissions = useAppSelector(
    (state) => state.company.loadedCompany?.companyUserRolesAndPermissions
  );

  const location = useLocation();

  const hasPermissionToCreateOrUpdateClients = useMemo(() => {
    const canAccessAccountsAndPayrollInvoices =
      loadedCompanyUserRolesAndPermissions?.accountsAndPayroll?.role ===
        AccountsAndPayrollRole.FULL_ACCESS ||
      (loadedCompanyUserRolesAndPermissions?.accountsAndPayroll?.role ===
        AccountsAndPayrollRole.LIMITED_ACCESS &&
        loadedCompanyUserRolesAndPermissions?.accountsAndPayroll?.permissions?.includes(
          AccountsAndPayrollPermission.SALES_ACCESS
        ));

    return (
      loadedCompanyUserRolesAndPermissions?.proposal?.role ===
        ProposalRole.FULL_ACCESS || canAccessAccountsAndPayrollInvoices
    );
  }, [loadedCompanyUserRolesAndPermissions]);

  return (
    <Nav
      activeKey={determineHomeNavigationEventKey(location.pathname)}
      className="flex-column pt-5"
    >
      {/* <Nav.Link eventKey={RoutePaths.HOME} as={Link} to={RoutePaths.HOME}>
        Home
      </Nav.Link> */}
      {loadedCompanyUserRolesAndPermissions?.user?.permissions?.includes(
        CompanyUserPermission.MANAGE_USERS
      ) && (
        <Nav.Link eventKey={RoutePaths.USERS} as={Link} to={RoutePaths.USERS}>
          Users
        </Nav.Link>
      )}
      {[CompanyUserRole.ADMINISTRATOR, CompanyUserRole.MEMBER].includes(
        loadedCompanyUserRolesAndPermissions?.user?.role as CompanyUserRole
      ) &&
        hasPermissionToCreateOrUpdateClients && (
          <Nav.Link
            eventKey={RoutePaths.CLIENTS}
            as={Link}
            to={RoutePaths.CLIENTS}
          >
            Clients
          </Nav.Link>
        )}
      {loadedCompanyUserRolesAndPermissions?.user?.permissions?.includes(
        CompanyUserPermission.BILLING_ACCESS
      ) && (
        <Nav.Link
          eventKey={RoutePaths.BILLING}
          as={Link}
          to={RoutePaths.BILLING}
        >
          Billing
        </Nav.Link>
      )}
      {/* <Nav.Link eventKey={RoutePaths.LIBRARY} as={Link} to={RoutePaths.LIBRARY}>
        Library
      </Nav.Link> */}
      <Nav.Link
        eventKey="never-selected"
        as={Link}
        to={RoutePaths.SETTINGS}
        tabIndex={0}
      >
        Settings
      </Nav.Link>
      {location.pathname.includes(RoutePaths.SETTINGS) ? (
        <div className="navigation-nested-display">
          <Nav.Link
            eventKey={RoutePaths.SETTINGS_USER}
            as={Link}
            to={RoutePaths.SETTINGS_USER}
          >
            My Settings
          </Nav.Link>
          {[CompanyUserRole.ADMINISTRATOR].includes(
            loadedCompanyUserRolesAndPermissions?.user?.role as CompanyUserRole
          ) && (
            <Nav.Link
              eventKey={RoutePaths.SETTINGS_COMPANY}
              as={Link}
              to={RoutePaths.SETTINGS_COMPANY}
            >
              Company Settings
            </Nav.Link>
          )}
        </div>
      ) : (
        <></>
      )}
    </Nav>
  );
};
