import React, { Suspense } from "react";
import { Outlet, Navigate } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";

import Login from "../components/main/auth/Login";
import ResetPassword from "../components/main/auth/ResetPassword";
import Billing from "../components/main/billing/Billing";
import Clients from "../components/main/clients/Clients";
// import Dashboard from "../components/main/home/Dashboard";
import Home from "../components/main/home/Home";
// import Library from "../components/main/library/Library";
import Settings from "../components/main/settings/Settings";
import TermsAndConditions from "../components/main/TermsAndConditions";
import Users from "../components/main/users/Users";
import NotFound from "../components/notfound/NotFound";
import SettingsCompany from "../components/main/settings/SettingsCompany";
import SettingsUser from "../components/main/settings/user/SettingsUser";
import UserForm from "../components/main/users/UserForm";
import EditBillingCard from "../components/main/billing/ChangePaymentCard";
import ClientForm from "../components/main/clients/ClientForm";
import CompanyForm from "../components/main/company/CompanyForm";
import SignUp from "../components/main/auth/SignUp";
import UserRegistrationForm from "../components/main/auth/UserRegistrationForm";
import CompanyCreate from "../components/main/company/CompanyCreate";
import RecoverAccount from "../components/main/auth/RecoverAccount";
import CompanySelection from "../components/main/auth/CompanySelection";
import StartSubscription from "../components/main/billing/stripe/StartSubscription";
import SetupInvitedAccount from "../components/main/auth/SetupInvitedAccount";
import Logout from "../components/main/auth/Logout";
import InternalServerError from "../components/internalServerError/InternalServerError";
import ServiceUnavailableError from "../components/serviceUnavailableError/ServiceUnavailableError";
// import StripeInit from "../config/StripeInit";

const StripeInit = React.lazy(() => import("../config/StripeInit"));

export interface IApplicationRoute {
  // path?: string | undefined;
  path?: RoutePaths;
  index?: boolean;
  element: React.ReactNode | null;
  nestedRoutes?: IApplicationRoute[];
}

export enum RoutePaths {
  HOME = "/",
  LOGIN = "/login",
  LOGOUT = "/logout",
  SIGNUP = "/signup",
  SIGNUP_COMPANY_CREATE = "/signup/company-create",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
  COMPANY_SELECT = "/select-company",
  // reset password
  RESET_PASSWORD = "/reset-password",
  RECOVER_ACCOUNT = "/recover-account",
  // invited user
  SETUP_INVITED_ACCOUNT = "/setup-account",
  // users
  USERS = "/users",
  USERS_CREATE = "/users/create",
  USER = "/users/:id",
  // clients
  CLIENTS = "/clients",
  CLIENTS_CREATE = "/clients/create",
  CLIENT = "/clients/:id",
  // billing
  BILLING = "/billing",
  BILLING_CARD = "/billing/change-card",
  BILLING_START_SUBSCRIPTION = "/billing/start-subscription",
  //
  LIBRARY = "/library",
  SETTINGS = "/settings",
  SETTINGS_COMPANY = "/settings/company",
  SETTINGS_USER = "/settings/user",
  // company
  COMPANY_CREATE = "/company/create",
  //
  INTERNAL_SERVER_ERROR = "/500",
  SERVICE_ANAVAILABLE_ERROR = "/503",
  // for routes that don't exist
  NOT_FOUND = "*",
}

export const applicationRoutes: IApplicationRoute[] = [
  {
    path: RoutePaths.HOME,
    element: (
      <ProtectedRoute>
        <Home />
      </ProtectedRoute>
    ),
    nestedRoutes: [
      {
        index: true,
        element: <Navigate to={RoutePaths.USERS} />,
      },
      {
        path: RoutePaths.USERS,
        element: <Outlet />,
        nestedRoutes: [
          {
            index: true,
            element: <Users />,
          },
          {
            path: RoutePaths.USER,
            element: <UserForm />,
          },
          {
            path: RoutePaths.USERS_CREATE,
            element: <UserForm />,
          },
        ],
      },
      {
        path: RoutePaths.CLIENTS,
        element: <Outlet />,
        nestedRoutes: [
          {
            index: true,
            element: <Clients />,
          },
          {
            path: RoutePaths.CLIENT,
            element: <ClientForm />,
          },
          {
            path: RoutePaths.CLIENTS_CREATE,
            element: <ClientForm />,
          },
        ],
      },
      {
        path: RoutePaths.BILLING,
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <StripeInit>
              <Outlet />
            </StripeInit>
          </Suspense>
        ),
        nestedRoutes: [
          {
            index: true,
            element: <Billing />,
          },
          {
            path: RoutePaths.BILLING_CARD,
            element: <EditBillingCard />,
          },
          {
            path: RoutePaths.BILLING_START_SUBSCRIPTION,
            element: <StartSubscription />,
          },
        ],
      },
      // {
      //   path: RoutePaths.LIBRARY,
      //   element: <Library />,
      // },
      {
        path: RoutePaths.COMPANY_CREATE,
        element: <CompanyCreate />,
      },
      {
        path: RoutePaths.SETTINGS,
        element: <Settings />,
        nestedRoutes: [
          {
            path: RoutePaths.SETTINGS_COMPANY,
            element: <SettingsCompany />,
          },
          {
            path: RoutePaths.SETTINGS_USER,
            element: <SettingsUser />,
          },
        ],
      },
    ],
  },
  {
    path: RoutePaths.LOGIN,
    element: <Login />,
  },
  {
    path: RoutePaths.LOGOUT,
    element: <Logout />,
  },
  {
    path: RoutePaths.COMPANY_SELECT,
    element: <CompanySelection />,
  },
  {
    path: RoutePaths.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: RoutePaths.RECOVER_ACCOUNT,
    element: <RecoverAccount />,
  },
  {
    path: RoutePaths.SETUP_INVITED_ACCOUNT,
    element: <SetupInvitedAccount />,
  },
  {
    path: RoutePaths.SIGNUP,
    element: <SignUp />,
    nestedRoutes: [
      {
        index: true,
        element: <UserRegistrationForm />,
      },
      {
        path: RoutePaths.SIGNUP_COMPANY_CREATE,
        element: <CompanyForm />,
      },
    ],
  },
  {
    path: RoutePaths.TERMS_AND_CONDITIONS,
    element: <TermsAndConditions />,
  },
  {
    path: RoutePaths.INTERNAL_SERVER_ERROR,
    element: <InternalServerError />,
  },
  {
    path: RoutePaths.SERVICE_ANAVAILABLE_ERROR,
    element: <ServiceUnavailableError />,
  },
  {
    path: RoutePaths.NOT_FOUND,
    element: <NotFound />,
  },
];
