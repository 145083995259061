// dedicated errors within the response
export interface IErrorData {
  statusCode?: number | string;
  message?: string | string[];
  error?: string;
}

export enum APIResponseExtraAction {
  UNAUTHORIZED_REDIRECT = "UNAUTHORIZED_REDIRECT",
}

// errors on request itself, or general error in response
export interface IErrorAPIRequest {
  message?: string;
  extra?: {
    action: APIResponseExtraAction.UNAUTHORIZED_REDIRECT;
  };
}
