import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { IErrorData, IErrorAPIRequest } from "./apiErrors";

export const processCompanyClientRequestErrors = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  // eslint-disable-next-line prefer-const
  let formValidationErrors = {};
  let apiRequestErrors: IErrorAPIRequest = {};

  if (error && "status" in error) {
    switch (error.status) {
      case "CUSTOM_ERROR":
      // fallsthrough
      case "PARSING_ERROR":
      // fallsthrough
      case "FETCH_ERROR":
        apiRequestErrors = {
          ...apiRequestErrors,
          message: "Something went wrong. Please try again later.",
        };
        break;
      default:
      // status is number, handle that in next IF statement
    }
  }

  if (error && "data" in error) {
    const data = error.data as IErrorData;

    if (Array.isArray(data?.message)) {
      data.message.forEach((errorMessage: string) => {
        /**
         * Order errors by priority
         * First one will be triggered and displayed.
         * Put specific errors on top, and generic errors on bottom
         */
        switch (errorMessage) {
          // formValidationErrors = {
          //   ...formValidationErrors,
          //   addressLine1: "Address line 1 is required",
          // };
          case "name should not be empty":
          case "name must be a string":
            formValidationErrors = {
              ...formValidationErrors,
              name: "Client Full Name is required",
            };
            break;
          case "companyName should not be empty":
          case "companyName must be a string":
            formValidationErrors = {
              ...formValidationErrors,
              companyName: "Business Name is required",
            };
            break;
          case "email should not be empty":
          case "email must be a string":
            formValidationErrors = {
              ...formValidationErrors,
              email: "Email is required",
            };
            break;
          case "email must be an email":
            formValidationErrors = {
              ...formValidationErrors,
              email: "Email is invalid",
            };
            break;
          case "phoneNumber should not be empty":
          case "phoneNumber must be a string":
            formValidationErrors = {
              ...formValidationErrors,
              phoneNumber: "Phone number is required",
            };
            break;
          case "vatNumber should not be empty":
          case "vatNumber must be a string":
            formValidationErrors = {
              ...formValidationErrors,
              vatNumber: "VAT number is required",
            };
            break;
          case "country should not be empty":
          case "country must be a string":
            formValidationErrors = {
              ...formValidationErrors,
              country: "Country is required",
            };
            break;
          case "currency should not be empty":
          case "currency must be a string":
            formValidationErrors = {
              ...formValidationErrors,
              currency: "Currency is required",
            };
            break;
          default:
            if (
              errorMessage.startsWith("email must match") &&
              errorMessage.endsWith("regular expression")
            ) {
              formValidationErrors = {
                ...formValidationErrors,
                email: "Email is invalid",
              };
              break;
            }
        }
      });
    } else {
      // it's a string
      switch (data.message) {
        case "Invalid credentials. Please try again.":
          apiRequestErrors = {
            ...apiRequestErrors,
            message: "Invalid credentials. Please login again.",
          };
          break;
        case "Client is already assigned to this company.":
          apiRequestErrors = {
            ...apiRequestErrors,
            message: "Client with this name and email already exists",
          };
          break;
        case "COMPANY_CLIENT_EMAIL_ALREADY_ASSIGNED":
          formValidationErrors = {
            ...formValidationErrors,
            email: "Email is already assigned to a client",
          };
          break;
        case "COMPANY_CLIENT_NAME_ALREADY_ASSIGNED":
          formValidationErrors = {
            ...formValidationErrors,
            name: "You already have a client with this name",
          };
          break;
        default:
          /**
           * Can be message like:
           *   "Cannot POST /api/something-that-doesnt-exist"
           *   "Internal server error"
           */
          apiRequestErrors = {
            ...apiRequestErrors,
            message: "Something went wrong. Please try again later.",
          };
      }
    }
  }

  return { formValidationErrors, authRequestErrors: apiRequestErrors };
};
