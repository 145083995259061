import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AccountsAndPayrollPermission,
  AccountsAndPayrollRole,
  CompanyUserPermission,
  CompanyUserRole,
  ProjectPermission,
  ProjectRole,
  ProposalRole,
} from "../../util/constants/userForm/userFormConstants";

/**
 * This function controls all interactions between
 *  various form elements
 *
 * Sets default values whenever a controlling property (such as
 *  user role) changes, as well as disabled state(s) of
 *  related roles and permissions.
 */
const setRoleAndPermissionsValues = (
  payload: Partial<ICompanyUserSliceData>,
  currentData: ICompanyUserSliceData,
  isFormEdit = true
): Partial<ICompanyUserSliceData> => {
  const latestUserRole = payload?.userRole?.role || currentData.userRole.role;

  // user role change
  if (
    payload?.userRole?.role &&
    (isFormEdit ? currentData.userRole.role !== payload?.userRole?.role : true)
  ) {
    switch (payload.userRole.role) {
      case CompanyUserRole.ADMINISTRATOR:
        return {
          userRole: {
            role: CompanyUserRole.ADMINISTRATOR,
            permissions: [
              CompanyUserPermission.MANAGE_USERS,
              CompanyUserPermission.BILLING_ACCESS,
            ],
            ...(!isFormEdit && payload.userRole),
            disabledPermissions: true,
          },
          accountsAndPayroll: {
            role: AccountsAndPayrollRole.FULL_ACCESS,
            permissions: [
              AccountsAndPayrollPermission.PAYROLL_ACCESS,
              AccountsAndPayrollPermission.PURCHASES_ACCESS,
              AccountsAndPayrollPermission.SALES_ACCESS,
            ],
            ...(!isFormEdit && payload.accountsAndPayroll),
            disabledRoles: true,
            disabledPermissions: true,
          },
          projects: {
            role: ProjectRole.FULL_ACCESS,
            permissions: [ProjectPermission.PROJECTS],
            ...(!isFormEdit && payload.projects),
            disabledRoles: true,
            disabledPermissions: true,
          },
          proposal: {
            role: ProposalRole.FULL_ACCESS,
            ...(!isFormEdit && payload.proposal),
            disabledRoles: true,
          },
        };
      case CompanyUserRole.MEMBER:
        return {
          userRole: {
            role: CompanyUserRole.MEMBER,
            permissions: [],
            ...(!isFormEdit && payload.userRole),
            disabledPermissions: false,
          },
          accountsAndPayroll: {
            role: AccountsAndPayrollRole.FULL_ACCESS,
            permissions: [
              AccountsAndPayrollPermission.PAYROLL_ACCESS,
              AccountsAndPayrollPermission.PURCHASES_ACCESS,
              AccountsAndPayrollPermission.SALES_ACCESS,
            ],
            ...(!isFormEdit && payload.accountsAndPayroll),
            disabledRoles: [AccountsAndPayrollRole.VIEW_ACCESS],
            disabledPermissions: true,
            // limited accounts access allows changing permissions
            ...(!isFormEdit &&
              payload.accountsAndPayroll?.role ===
                AccountsAndPayrollRole.LIMITED_ACCESS && {
                disabledPermissions: false,
              }),
          },
          projects: {
            role: ProjectRole.FULL_ACCESS,
            permissions: [ProjectPermission.PROJECTS],
            ...(!isFormEdit && payload.projects),
            disabledRoles: false,
            disabledPermissions: true,
            // limited accounts access allows changing permissions
            ...(!isFormEdit &&
              payload.projects?.role === ProjectRole.GUEST_ACCESS && {
                disabledPermissions: false,
              }),
          },
          proposal: {
            role: ProposalRole.FULL_ACCESS,
            ...(!isFormEdit && payload.proposal),
            disabledRoles: false,
          },
        };
      case CompanyUserRole.GUEST:
        return {
          userRole: {
            role: CompanyUserRole.GUEST,
            permissions: [],
            ...(!isFormEdit && payload.userRole),
            disabledPermissions: true,
          },
          accountsAndPayroll: {
            role: AccountsAndPayrollRole.VIEW_ACCESS,
            permissions: [],
            ...(!isFormEdit && payload.accountsAndPayroll),
            disabledRoles: [
              AccountsAndPayrollRole.FULL_ACCESS,
              AccountsAndPayrollRole.LIMITED_ACCESS,
            ],
            disabledPermissions: true,
          },
          projects: {
            role: ProjectRole.GUEST_ACCESS,
            permissions: [],
            ...(!isFormEdit && payload.projects),
            disabledRoles: [ProjectRole.FULL_ACCESS],
            disabledPermissions: true,
            // limited accounts access allows changing permissions
            ...(!isFormEdit &&
              payload.projects?.role === ProjectRole.NO_ACCESS && {
                disabledPermissions: true,
              }),
          },
          proposal: {
            role: ProposalRole.NO_ACCESS,
            ...(!isFormEdit && payload.proposal),
            disabledRoles: true,
          },
        };
      default:
    }
  }

  // accounts and payroll role change
  if (
    payload?.accountsAndPayroll?.role &&
    (isFormEdit
      ? currentData.accountsAndPayroll.role !==
        payload?.accountsAndPayroll?.role
      : true)
  ) {
    switch (payload.accountsAndPayroll.role) {
      case AccountsAndPayrollRole.FULL_ACCESS:
        return {
          accountsAndPayroll: {
            role: AccountsAndPayrollRole.FULL_ACCESS,
            permissions: [
              AccountsAndPayrollPermission.PAYROLL_ACCESS,
              AccountsAndPayrollPermission.PURCHASES_ACCESS,
              AccountsAndPayrollPermission.SALES_ACCESS,
            ],
            ...(!isFormEdit && payload.accountsAndPayroll),
            disabledRoles: [AccountsAndPayrollRole.VIEW_ACCESS],
            disabledPermissions: true,
          },
        };
      case AccountsAndPayrollRole.LIMITED_ACCESS:
        return {
          accountsAndPayroll: {
            role: AccountsAndPayrollRole.LIMITED_ACCESS,
            permissions: [],
            ...(!isFormEdit && payload.accountsAndPayroll),
            disabledRoles: [AccountsAndPayrollRole.VIEW_ACCESS],
            disabledPermissions: false,
          },
        };
      case AccountsAndPayrollRole.VIEW_ACCESS:
      // fallsthrough
      case AccountsAndPayrollRole.NO_ACCESS:
        return {
          accountsAndPayroll: {
            role: payload.accountsAndPayroll.role,
            permissions: [],
            ...(!isFormEdit && payload.accountsAndPayroll),
            disabledRoles:
              currentData.userRole.role === CompanyUserRole.MEMBER
                ? [AccountsAndPayrollRole.VIEW_ACCESS]
                : [
                    AccountsAndPayrollRole.FULL_ACCESS,
                    AccountsAndPayrollRole.LIMITED_ACCESS,
                  ],
            disabledPermissions: true,
          },
        };
      default:
    }
  }

  // projects role change
  if (
    payload?.projects?.role &&
    (isFormEdit ? currentData.projects.role !== payload?.projects?.role : true)
  ) {
    switch (payload.projects.role) {
      case ProjectRole.FULL_ACCESS:
        return {
          projects: {
            role: ProjectRole.FULL_ACCESS,
            permissions: [ProjectPermission.PROJECTS],
            ...(!isFormEdit && payload.projects),
            disabledRoles: false,
            disabledPermissions: true,
          },
        };
      case ProjectRole.GUEST_ACCESS:
        return {
          projects: {
            role: ProjectRole.GUEST_ACCESS,
            permissions: [],
            ...(!isFormEdit && payload.projects),
            ...(latestUserRole === CompanyUserRole.GUEST
              ? {
                  disabledRoles: [ProjectRole.FULL_ACCESS],
                  disabledPermissions: true,
                }
              : { disabledRoles: false, disabledPermissions: false }),
          },
        };
      case ProjectRole.NO_ACCESS:
        return {
          projects: {
            role: ProjectRole.NO_ACCESS,
            permissions: [],
            ...(!isFormEdit && payload.projects),
            ...(latestUserRole === CompanyUserRole.GUEST
              ? {
                  disabledRoles: [ProjectRole.FULL_ACCESS],
                  disabledPermissions: true,
                }
              : { disabledRoles: false, disabledPermissions: true }),
          },
        };
      default:
    }
  }

  return {};
};

export interface ICompanyUserSliceData {
  userId?: string | null;
  // user
  firstName: string | null;
  email: string | null;
  // user role
  userRole: {
    role: CompanyUserRole | null;
    permissions: CompanyUserPermission[];
    disabledPermissions: boolean;
  };
  // accounts and payroll
  accountsAndPayroll: {
    role: AccountsAndPayrollRole | null;
    permissions: AccountsAndPayrollPermission[];
    disabledRoles: boolean | AccountsAndPayrollRole[];
    disabledPermissions: boolean;
  };
  // projects
  projects: {
    role: ProjectRole | null;
    permissions: ProjectPermission[];
    disabledRoles: boolean | ProjectRole[];
    disabledPermissions: boolean;
  };
  // proposal
  proposal: {
    role: ProposalRole | null;
    disabledRoles: boolean | ProposalRole[];
  };
  //
  errors: {
    firstName: string | null;
    email: string | null;
    userRole: string | null;
  };
}
export interface ICompanyUserSliceState {
  formData: ICompanyUserSliceData;
}

const defaultFormDataErrors = () => ({
  firstName: null,
  email: null,
  userRole: null,
});

export const defaultCompanyUsersSliceFormData = () => ({
  userId: null,
  // user
  firstName: null,
  email: null,
  // user role
  userRole: {
    role: null,
    permissions: [],
    disabledPermissions: false,
  },
  // user role
  accountsAndPayroll: {
    role: null,
    permissions: [],
    disabledRoles: false,
    disabledPermissions: false,
  },
  // user role
  projects: {
    role: null,
    permissions: [],
    disabledRoles: false,
    disabledPermissions: false,
  },
  // user role
  proposal: {
    role: null,
    disabledRoles: false,
  },
  //
  errors: defaultFormDataErrors(),
});

const initialState: ICompanyUserSliceState = {
  formData: defaultCompanyUsersSliceFormData(),
};

const companyUsersSlice = createSlice({
  name: "companyUsers",
  initialState,
  reducers: {
    setCompanyUserForm: (state, action) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
        ...setRoleAndPermissionsValues(action.payload, state.formData, false),
        // errors
        errors: defaultFormDataErrors(),
      };
    },
    changeCompanyUserForm: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action: PayloadAction<ICompanyUserSliceData | { [key: string]: any }>
    ) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
        ...setRoleAndPermissionsValues(action.payload, state.formData),
        // errors
        errors: {
          ...state.formData.errors,
          // remove error from any property that was updated
          ...Object.keys(action.payload).reduce(
            (acc, curr) => ({ ...acc, [curr]: undefined }),
            {}
          ),
        },
      };
    },
    updateCompanyUserFormErrors: (state, action) => {
      state.formData = {
        ...state.formData,
        errors: action.payload,
      };
    },
  },
});

export const {
  setCompanyUserForm,
  changeCompanyUserForm,
  updateCompanyUserFormErrors,
} = companyUsersSlice.actions;

export default companyUsersSlice.reducer;
