import { Col, Container, Row } from "react-bootstrap";
import CompanyForm from "./CompanyForm";

export default () => {
  return (
    <Container fluid id="create-company">
      <Row className="mb-4">
        <Col className="text-heading fw-medium">Create Company</Col>
      </Row>
      <Row>
        <CompanyForm />
      </Row>
    </Container>
  );
};
