import { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useAppSelector } from "../../../../store/redux-hooks";
import {
  useLazyGetCompanyUserQuery,
  useSetNotificationsSoundMutation,
  useToggleChatMessageUnreadEmailNotificationsMutation,
} from "../../../../store/slices/apiSlice";

const DEFAULT_CHAT_MESSAGE_UNREAD_EMAIL_NOTIFICATION_ENABLED_VALUE = true;

export default () => {
  const [notificationToogle, setNotificationToogle] = useState(true);
  const [
    chatMessageUnreadEmailNotificationEnabled,
    setChatMessageUnreadEmailNotificationEnabled,
  ] = useState(DEFAULT_CHAT_MESSAGE_UNREAD_EMAIL_NOTIFICATION_ENABLED_VALUE);

  const user = useAppSelector((state) => state.auth.user);
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);

  const [getCompanyUser, { data: getUserData }] = useLazyGetCompanyUserQuery();

  useEffect(() => {
    if (user?.id && loadedCompany?.id) {
      getCompanyUser({
        urlArgs: { companyId: loadedCompany.id, userId: user.id },
      });
    }
  }, [getCompanyUser, loadedCompany?.id, user?.id]);

  useEffect(() => {
    if (getUserData) {
      if (getUserData.useSoundNotification !== undefined) {
        setNotificationToogle(getUserData.useSoundNotification);
      }

      setChatMessageUnreadEmailNotificationEnabled(
        getUserData.chatMessageUnreadEmailNotificationEnabled ??
          DEFAULT_CHAT_MESSAGE_UNREAD_EMAIL_NOTIFICATION_ENABLED_VALUE
      );
    }
  }, [getUserData]);

  const [setNotificationSoundMutation] = useSetNotificationsSoundMutation();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotificationSoundMutation({
      body: { useSoundNotification: event.target.checked },
      // TODO: why is this here? userId should be extracted from token, not trusted to be correct user ID from frontend
      urlArgs: { userId: user?.id },
    });
    setNotificationToogle(!notificationToogle);
  };

  const [toggleChatMessageUnreadEmailNotification] =
    useToggleChatMessageUnreadEmailNotificationsMutation();

  function onChagechatMessageUnreadEmailNotificationEnableds(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    toggleChatMessageUnreadEmailNotification({
      body: { chatMessageUnreadEmailNotificationEnabled: event.target.checked },
    });
    setChatMessageUnreadEmailNotificationEnabled(event.target.checked);
  }

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col className="text-title fw-medium custom-text-title">
          Notifications
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={notificationToogle}
              onChange={onChange}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            >
              Turn {notificationToogle ? "OFF" : "ON"} Sound Notifications
            </label>
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Form.Group id="chat-msg-unread-email-notifications">
          <Form.Check
            type="switch"
            id="chat-msg-unread-email-notifications-input"
            label="Send email notifications about unread chat messages"
            checked={chatMessageUnreadEmailNotificationEnabled}
            onChange={onChagechatMessageUnreadEmailNotificationEnableds}
            //
            feedback="Get notified about messages or mentions unread for more than an hour"
            isValid={true}
          />
        </Form.Group>
      </Row>
    </Container>
  );
};
