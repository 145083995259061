import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";

import { RoutePaths } from "../../../routes/routes";

import { useAppDispatch, useAppSelector } from "../../../store/redux-hooks";
import {
  updateSetupInvitedAccountFormErrors,
  updateSetupInvitedAccountForm,
} from "../../../store/slices/authSlice";
import { processAuthRequestErrors } from "../../../errors/authErrors";
import { useSetupAccountMutation } from "../../../store/slices/apiSlice";
import { IErrorAPIRequest } from "../../../errors/apiErrors";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { MAIN_PASSWORD_FIELD_PLACEHOLDER_TEXT } from "../../../util/constants/constants";
import RequiredFieldBadge from "../../common/RequiredFieldBadge";

export default () => {
  const setupInvitedAccount = useAppSelector(
    (state) => state.auth.setupInvitedAccountData
  );
  const dispatch = useAppDispatch();

  const [showPasswords, setShowPasswords] = useState({
    password: false,
    repeatPassword: false,
  });

  const toggleShowPassword = (field: "password" | "repeatPassword") => () => {
    setShowPasswords({ ...showPasswords, [field]: !showPasswords[field] });
  };

  const navigate = useNavigate();

  const [urlSearchParams] = useSearchParams();
  const queryStringInvitationClaim = urlSearchParams.get("invitationClaim");

  // TODO: check if claim is expired or missing? redirect, show error message?
  // useEffect(() => {
  // }, [ queryStringInvitationClaim]);

  const [requestErrors, setRequestErrors] = useState<IErrorAPIRequest>();

  const [
    setupInvitedAccountMutation,
    { isSuccess, error: setupInvitedAccountError, isLoading },
  ] = useSetupAccountMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate(RoutePaths.HOME + "?firstLogin=true");
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processAuthRequestErrors(setupInvitedAccountError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateSetupInvitedAccountFormErrors(formValidationErrors));
  }, [setupInvitedAccountError, dispatch]);

  return (
    <Container
      fluid
      className="height-100 px-0 authForms"
      id="setupInvitedAccountForm"
    >
      <Row className="max-width-560px mx-auto my-auto align-items-center height-100">
        <Card body border="light" className="px-0 px-sm-5 py-5">
          <Form>
            <Col>
              <Row className="justify-content-center mb-4">
                <h2 className="w-auto text-center">
                  Set Password for Your Account
                </h2>
              </Row>
              <Row>
                <Form.Group
                  className="mb-4"
                  controlId="recoverAccountNewPassword"
                >
                  <Form.Label>
                    Choose a password
                    <RequiredFieldBadge />
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPasswords.password ? "text" : "password"}
                      placeholder={MAIN_PASSWORD_FIELD_PLACEHOLDER_TEXT}
                      value={setupInvitedAccount.password}
                      onChange={({ target: { value } }) =>
                        dispatch(
                          updateSetupInvitedAccountForm({ password: value })
                        )
                      }
                      isInvalid={Boolean(setupInvitedAccount.errors?.password)}
                    />
                    <Button
                      variant="outline-secondary"
                      id="toggle-password"
                      type="button"
                      onClick={toggleShowPassword("password")}
                      // https://github.com/twbs/bootstrap/issues/36049
                      className="border-radius-right-8px"
                    >
                      {showPasswords.password ? (
                        <Eye size="1.7em" />
                      ) : (
                        <EyeSlash size="1.7em" />
                      )}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      {setupInvitedAccount.errors?.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  className="mb-5"
                  controlId="recoverAccountRepeatPassword"
                >
                  <Form.Label>
                    Repeat password
                    <RequiredFieldBadge />
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPasswords.repeatPassword ? "text" : "password"}
                      value={setupInvitedAccount.repeatPassword}
                      onChange={({ target: { value } }) =>
                        dispatch(
                          updateSetupInvitedAccountForm({
                            repeatPassword: value,
                          })
                        )
                      }
                      isValid={
                        Boolean(setupInvitedAccount.repeatPassword) &&
                        setupInvitedAccount.password ===
                          setupInvitedAccount.repeatPassword
                      }
                      isInvalid={
                        setupInvitedAccount.password !==
                        setupInvitedAccount.repeatPassword
                      }
                    />
                    <Button
                      variant="outline-secondary"
                      id="toggle-repeat-password"
                      type="button"
                      onClick={toggleShowPassword("repeatPassword")}
                      // https://github.com/twbs/bootstrap/issues/36049
                      className="border-radius-right-8px"
                    >
                      {showPasswords.repeatPassword ? (
                        <Eye size="1.7em" />
                      ) : (
                        <EyeSlash size="1.7em" />
                      )}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      Passwords do not match
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row
                className={
                  "justify-content-center " +
                  (requestErrors?.message ? "mb-3" : "mb-5")
                }
              >
                <Button
                  variant="dark"
                  type="submit"
                  className="w-auto"
                  tabIndex={3}
                  disabled={isLoading}
                  onClick={(event) => {
                    event.preventDefault();

                    if (
                      setupInvitedAccount.password ===
                        setupInvitedAccount.repeatPassword &&
                      setupInvitedAccount.repeatPassword
                    ) {
                      setupInvitedAccountMutation({
                        // TODO: rework this?
                        invitationClaim: queryStringInvitationClaim || "",
                        password: setupInvitedAccount.password,
                      });
                    } else {
                      setRequestErrors({
                        message: "Please fill the form fields.",
                      });
                    }
                  }}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Set Password"
                  )}
                </Button>
              </Row>
              {requestErrors?.message && (
                <Row>
                  <Alert variant="danger">{requestErrors?.message}</Alert>
                </Row>
              )}
            </Col>
          </Form>
        </Card>
      </Row>
    </Container>
  );
};
