import { useEffect } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import useModalShow from "../../../hooks/useModalShow";
import ReactGA from "../../../reactGA";
import { RoutePaths } from "../../../routes/routes";
import { useAppDispatch, useAppSelector } from "../../../store/redux-hooks";
import {
  ICompanyUserBaseSchema,
  useLazyGetCompanyUserQuery,
} from "../../../store/slices/apiSlice";
import {
  changeCompanyUserForm,
  defaultCompanyUsersSliceFormData,
  ICompanyUserSliceData,
  setCompanyUserForm,
} from "../../../store/slices/companyUsersSlice";
import { addAlert } from "../../../store/slices/notificationSlice";
import {
  AccountsAndPayrollPermission,
  AccountsAndPayrollRole,
  CompanyUserPermission,
  CompanyUserRole,
  ProjectPermission,
  ProjectRole,
  ProposalRole,
} from "../../../util/constants/userForm/userFormConstants";
import { toggleElementInArray } from "../../../util/functions";
import RequiredFieldBadge from "../../common/RequiredFieldBadge";
import DeleteUserModal from "./DeleteUserModal";
import useUpsertCompanyUserMutation from "./useUpsertCompanyUserMutation";

const isDisabled = ({
  disabledValues,
  toCompareWith,
  isUserCompanyOwner,
  isUserRoleSelected,
}: {
  disabledValues: boolean | string[];
  toCompareWith?: string;
  isUserCompanyOwner: boolean;
  isUserRoleSelected: boolean;
}) => {
  // if (!disabledValues) {
  //   // default to disabling if improper data received
  //   return true;
  // }

  if (isUserCompanyOwner || !isUserRoleSelected) {
    return true;
  }

  if (typeof disabledValues === "boolean") {
    return disabledValues;
  }

  return Boolean(toCompareWith && disabledValues.includes(toCompareWith));
};

const transformCompanyUserDataForAPIRequestBody = ({
  companyId,
  data,
}: {
  companyId: string;
  data: ICompanyUserSliceData;
}): ICompanyUserBaseSchema => {
  return {
    companyId,
    firstName: data.firstName,
    email: data.email,
    user: {
      role: data.userRole.role,
      permissions: data.userRole.permissions,
    },
    accountsAndPayroll: {
      role: data.accountsAndPayroll.role,
      permissions: data.accountsAndPayroll.permissions,
    },
    projects: {
      role: data.projects.role,
      permissions: data.projects.permissions,
    },
    proposal: {
      role: data.proposal.role,
    },
  } as ICompanyUserBaseSchema;
};

export default () => {
  const companyUserData = useAppSelector(
    (state) => state.companyUsers.formData
  );
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const params = useParams();

  const isNewUser = !params?.id;

  const isUserCompanyOwner =
    loadedCompany?.companyOwnerId === companyUserData.userId &&
    companyUserData.userId !== "undefined";

  const isUserRoleSelected = Boolean(companyUserData.userRole.role);

  const { show, toggleModal } = useModalShow();

  const {
    upsertCompanyUserMutation,
    isLoading,
    isSuccess,
    requestErrors,
    // setRequestErrors,
  } = useUpsertCompanyUserMutation(!isNewUser);

  const [
    getCompanyUser,
    {
      data: getCompanyUserData,
      // isFetching: isFetchingGetCompanyUser
    },
  ] = useLazyGetCompanyUserQuery();

  useEffect(() => {
    if (params?.id && loadedCompany?.id) {
      // we're in users/:id, load this user
      getCompanyUser({
        urlArgs: { userId: params.id, companyId: loadedCompany.id },
      });
    }
  }, [params, loadedCompany?.id, getCompanyUser]);

  useEffect(() => {
    // set getUserData to form data
    if (getCompanyUserData) {
      dispatch(
        setCompanyUserForm({
          userId: getCompanyUserData._id,
          firstName: getCompanyUserData.firstName,
          email: getCompanyUserData.email,
          userRole: getCompanyUserData.companies?.[0]?.user,
          accountsAndPayroll:
            getCompanyUserData.companies?.[0]?.accountsAndPayroll,
          projects: getCompanyUserData.companies?.[0]?.projects,
          proposal: getCompanyUserData.companies?.[0]?.proposal,
        })
      );
    }
  }, [getCompanyUserData, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      ReactGA.event({
        action: "form_submission",
        label: "hub-create-user-success",
        category: "hub-create-user",
      });

      dispatch(
        addAlert({
          variant: "success",
          forRoute: RoutePaths.USERS,
          message: `Company user successfully ${
            isNewUser ? "added" : "updated"
          }!`,
        })
      );

      navigate(RoutePaths.USERS);
    }
  }, [isSuccess, isNewUser, dispatch, navigate]);

  useEffect(() => {
    // clean up form on unmount
    return () => {
      dispatch(setCompanyUserForm(defaultCompanyUsersSliceFormData()));
    };
  }, [dispatch]);

  return (
    <Container fluid id="user-form">
      <Row className="mb-4">
        <Col className="text-heading fw-medium">
          <span className="color-greyish">Users: </span>
          <span>{isNewUser ? "Create" : "Update"} User</span>
        </Col>
      </Row>
      <Form className="max-width-medium">
        <Row>
          <Col>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  Name
                  <RequiredFieldBadge />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={companyUserData.firstName || ""}
                  disabled={!isNewUser}
                  onChange={({ target: { value } }) => {
                    dispatch(
                      changeCompanyUserForm({
                        firstName: value,
                      })
                    );
                  }}
                  isInvalid={Boolean(companyUserData.errors?.firstName)}
                />
                <Form.Control.Feedback type="invalid">
                  {companyUserData.errors?.firstName}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  Email
                  <RequiredFieldBadge />
                </Form.Label>
                <Form.Control
                  type="email"
                  value={companyUserData.email || ""}
                  disabled={!isNewUser}
                  onChange={({ target: { value } }) => {
                    dispatch(
                      changeCompanyUserForm({
                        email: value,
                      })
                    );
                  }}
                  isInvalid={Boolean(companyUserData.errors?.email)}
                />
                <Form.Control.Feedback type="invalid">
                  {companyUserData.errors?.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <div className="mb-1 fw-medium">
                  <Form.Label>
                    User role
                    <RequiredFieldBadge />
                  </Form.Label>
                </div>
                <div>
                  <Form.Check
                    inline
                    type="radio"
                    name="user-role"
                    label="Administrator"
                    id="user-role-admin"
                    disabled={isUserCompanyOwner}
                    checked={
                      companyUserData.userRole.role ===
                      CompanyUserRole.ADMINISTRATOR
                    }
                    value={CompanyUserRole.ADMINISTRATOR}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          userRole: {
                            ...companyUserData.userRole,
                            role: value,
                          },
                        })
                      );
                    }}
                    isInvalid={Boolean(companyUserData.errors?.userRole)}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="user-role"
                    label="Member"
                    id="user-role-member"
                    disabled={isUserCompanyOwner}
                    checked={
                      companyUserData.userRole.role === CompanyUserRole.MEMBER
                    }
                    value={CompanyUserRole.MEMBER}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          userRole: {
                            ...companyUserData.userRole,
                            role: value,
                          },
                        })
                      );
                    }}
                    isInvalid={Boolean(companyUserData.errors?.userRole)}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="user-role"
                    label="Guest - free user"
                    id="user-role-guest"
                    disabled={isUserCompanyOwner}
                    checked={
                      companyUserData.userRole.role === CompanyUserRole.GUEST
                    }
                    value={CompanyUserRole.GUEST}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          userRole: {
                            ...companyUserData.userRole,
                            role: value,
                          },
                        })
                      );
                    }}
                    isInvalid={Boolean(companyUserData.errors?.userRole)}
                  />
                </div>
                {companyUserData.errors?.userRole && (
                  <>
                    <div className="is-invalid"></div>
                    <div className="invalid-feedback mb-3 mt-0">
                      {companyUserData.errors?.userRole}
                    </div>
                  </>
                )}
                <div>
                  {/* MANAGE_USERS is now hidden  */}
                  {/* <Form.Check
                    type="checkbox"
                    id="user-role-manage-users"
                    label="Manage users"
                    disabled={isDisabled({
                      disabledValues:
                        companyUserData.userRole.disabledPermissions,
                      isUserCompanyOwner,
                    })}
                    checked={
                      companyUserData.userRole.permissions?.includes(
                        CompanyUserPermission.MANAGE_USERS
                      ) || false
                    }
                    onChange={() => {
                      dispatch(
                        changeCompanyUserForm({
                          userRole: {
                            ...companyUserData.userRole,
                            permissions: toggleElementInArray(
                              companyUserData.userRole.permissions,
                              CompanyUserPermission.MANAGE_USERS
                            ),
                          },
                        })
                      );
                    }}
                  /> */}
                  <Form.Check
                    type="checkbox"
                    id="user-role-access-billing"
                    label="Access to Billing"
                    disabled={isDisabled({
                      disabledValues:
                        companyUserData.userRole.disabledPermissions,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.userRole.permissions?.includes(
                        CompanyUserPermission.BILLING_ACCESS
                      ) || false
                    }
                    onChange={() => {
                      dispatch(
                        changeCompanyUserForm({
                          userRole: {
                            ...companyUserData.userRole,
                            permissions: toggleElementInArray(
                              companyUserData.userRole.permissions,
                              CompanyUserPermission.BILLING_ACCESS
                            ),
                          },
                        })
                      );
                    }}
                  />
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <div className="mb-1 fw-medium">
                  <Form.Label>Accounts & Payroll</Form.Label>
                </div>
                <div className="mb-1">
                  <Form.Check
                    inline
                    type="radio"
                    name="accpay"
                    label="Full access"
                    id="accpay-full-access"
                    disabled={isDisabled({
                      disabledValues:
                        companyUserData.accountsAndPayroll.disabledRoles,
                      toCompareWith: AccountsAndPayrollRole.FULL_ACCESS,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.accountsAndPayroll.role ===
                      AccountsAndPayrollRole.FULL_ACCESS
                    }
                    value={AccountsAndPayrollRole.FULL_ACCESS}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          accountsAndPayroll: {
                            ...companyUserData.accountsAndPayroll,
                            role: value,
                          },
                        })
                      );
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="accpay"
                    label="Limited access"
                    id="accpay-limited-access"
                    disabled={isDisabled({
                      disabledValues:
                        companyUserData.accountsAndPayroll.disabledRoles,
                      toCompareWith: AccountsAndPayrollRole.LIMITED_ACCESS,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.accountsAndPayroll.role ===
                      AccountsAndPayrollRole.LIMITED_ACCESS
                    }
                    value={AccountsAndPayrollRole.LIMITED_ACCESS}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          ...companyUserData.accountsAndPayroll,
                          accountsAndPayroll: { role: value },
                        })
                      );
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="accpay"
                    label="View/comment"
                    id="accpay-view-comment"
                    disabled={isDisabled({
                      disabledValues:
                        companyUserData.accountsAndPayroll.disabledRoles,
                      toCompareWith: AccountsAndPayrollRole.VIEW_ACCESS,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.accountsAndPayroll.role ===
                      AccountsAndPayrollRole.VIEW_ACCESS
                    }
                    value={AccountsAndPayrollRole.VIEW_ACCESS}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          ...companyUserData.accountsAndPayroll,
                          accountsAndPayroll: { role: value },
                        })
                      );
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="accpay"
                    label="No Access"
                    id="accpay-no-access"
                    style={{
                      marginRight: 0,
                    }}
                    disabled={isDisabled({
                      disabledValues:
                        companyUserData.accountsAndPayroll.disabledRoles,
                      toCompareWith: AccountsAndPayrollRole.NO_ACCESS,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.accountsAndPayroll.role ===
                      AccountsAndPayrollRole.NO_ACCESS
                    }
                    value={AccountsAndPayrollRole.NO_ACCESS}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          ...companyUserData.accountsAndPayroll,
                          accountsAndPayroll: { role: value },
                        })
                      );
                    }}
                  />
                </div>
                <div>
                  <Form.Check
                    type="checkbox"
                    id="accpay-sales"
                    label="Sales: Access to Invoices & Money In"
                    disabled={isDisabled({
                      disabledValues:
                        companyUserData.accountsAndPayroll.disabledPermissions,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.accountsAndPayroll.permissions?.includes(
                        AccountsAndPayrollPermission.SALES_ACCESS
                      ) ||
                      companyUserData.accountsAndPayroll.role ===
                        AccountsAndPayrollRole.VIEW_ACCESS ||
                      false
                    }
                    onChange={() => {
                      dispatch(
                        changeCompanyUserForm({
                          accountsAndPayroll: {
                            ...companyUserData.accountsAndPayroll,
                            permissions: toggleElementInArray(
                              companyUserData.accountsAndPayroll.permissions,
                              AccountsAndPayrollPermission.SALES_ACCESS
                            ),
                          },
                        })
                      );
                    }}
                  />
                  <Form.Check
                    type="checkbox"
                    id="accpay-purchases"
                    label="Purchases: Access to Money Out & Receipts"
                    disabled={isDisabled({
                      disabledValues:
                        companyUserData.accountsAndPayroll.disabledPermissions,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.accountsAndPayroll.permissions?.includes(
                        AccountsAndPayrollPermission.PURCHASES_ACCESS
                      ) ||
                      companyUserData.accountsAndPayroll.role ===
                        AccountsAndPayrollRole.VIEW_ACCESS ||
                      false
                    }
                    onChange={() => {
                      dispatch(
                        changeCompanyUserForm({
                          accountsAndPayroll: {
                            ...companyUserData.accountsAndPayroll,
                            permissions: toggleElementInArray(
                              companyUserData.accountsAndPayroll.permissions,
                              AccountsAndPayrollPermission.PURCHASES_ACCESS
                            ),
                          },
                        })
                      );
                    }}
                  />
                  <Form.Check
                    type="checkbox"
                    id="accpay-payroll"
                    label="Payroll: Access to Payroll"
                    disabled={isDisabled({
                      disabledValues:
                        companyUserData.accountsAndPayroll.disabledPermissions,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.accountsAndPayroll.permissions?.includes(
                        AccountsAndPayrollPermission.PAYROLL_ACCESS
                      ) || false
                    }
                    onChange={() => {
                      dispatch(
                        changeCompanyUserForm({
                          accountsAndPayroll: {
                            ...companyUserData.accountsAndPayroll,
                            permissions: toggleElementInArray(
                              companyUserData.accountsAndPayroll.permissions,
                              AccountsAndPayrollPermission.PAYROLL_ACCESS
                            ),
                          },
                        })
                      );
                    }}
                  />
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <div className="mb-1 fw-medium">
                  <Form.Label>Projects & Channels</Form.Label>
                </div>
                <div className="mb-1">
                  <Form.Check
                    inline
                    type="radio"
                    name="projects"
                    label="Full access"
                    id="projects-full-access"
                    disabled={isDisabled({
                      disabledValues: companyUserData.projects.disabledRoles,
                      toCompareWith: ProjectRole.FULL_ACCESS,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.projects.role === ProjectRole.FULL_ACCESS
                    }
                    value={ProjectRole.FULL_ACCESS}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          projects: {
                            ...companyUserData.projects,
                            role: value,
                          },
                        })
                      );
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="projects"
                    label="Guest access"
                    id="projects-guest-access"
                    disabled={isDisabled({
                      disabledValues: companyUserData.projects.disabledRoles,
                      toCompareWith: ProjectRole.GUEST_ACCESS,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.projects.role === ProjectRole.GUEST_ACCESS
                    }
                    value={ProjectRole.GUEST_ACCESS}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          projects: {
                            ...companyUserData.projects,
                            role: value,
                          },
                        })
                      );
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="projects"
                    label="No Access"
                    id="projects-no-access"
                    disabled={isDisabled({
                      disabledValues: companyUserData.projects.disabledRoles,
                      toCompareWith: ProjectRole.NO_ACCESS,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.projects.role === ProjectRole.NO_ACCESS
                    }
                    value={ProjectRole.NO_ACCESS}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          projects: {
                            ...companyUserData.projects,
                            role: value,
                          },
                        })
                      );
                    }}
                  />
                </div>
                <div>
                  <Form.Check
                    type="checkbox"
                    id="projects-profitability"
                    label="Access to Projects Profitability"
                    disabled={isDisabled({
                      disabledValues:
                        companyUserData.projects.disabledPermissions,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.projects.permissions?.includes(
                        ProjectPermission.PROJECTS
                      ) || false
                    }
                    onChange={() => {
                      dispatch(
                        changeCompanyUserForm({
                          projects: {
                            ...companyUserData.projects,
                            permissions: toggleElementInArray(
                              companyUserData.projects.permissions,
                              ProjectPermission.PROJECTS
                            ),
                          },
                        })
                      );
                    }}
                  />
                </div>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-5">
                <div className="mb-1 fw-medium">
                  <Form.Label>Proposals</Form.Label>
                </div>
                <div className="mb-1">
                  <Form.Check
                    inline
                    type="radio"
                    name="proposals"
                    label="Full access"
                    id="proposals-full-access"
                    disabled={isDisabled({
                      disabledValues: companyUserData.proposal.disabledRoles,
                      toCompareWith: ProposalRole.FULL_ACCESS,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.proposal.role === ProposalRole.FULL_ACCESS
                    }
                    value={ProposalRole.FULL_ACCESS}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          proposal: {
                            ...companyUserData.proposal,
                            role: value,
                          },
                        })
                      );
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    name="proposals"
                    label="No Access"
                    id="proposals-no-access"
                    disabled={isDisabled({
                      disabledValues: companyUserData.proposal.disabledRoles,
                      toCompareWith: ProposalRole.NO_ACCESS,
                      isUserCompanyOwner,
                      isUserRoleSelected,
                    })}
                    checked={
                      companyUserData.proposal.role === ProposalRole.NO_ACCESS
                    }
                    value={ProposalRole.NO_ACCESS}
                    onChange={({ target: { value } }) => {
                      dispatch(
                        changeCompanyUserForm({
                          proposal: {
                            ...companyUserData.proposal,
                            role: value,
                          },
                        })
                      );
                    }}
                  />
                </div>
              </Form.Group>
            </Row>
            {/* 
            ACTIONS
             */}
            <Row className="justify-content-between mb-3">
              <Col xs="auto">
                {!isNewUser && (
                  <Button
                    variant="secondary"
                    type="button"
                    disabled={isUserCompanyOwner}
                    onClick={(event) => {
                      event.preventDefault();
                      toggleModal();
                    }}
                  >
                    Remove user
                  </Button>
                )}
              </Col>
              <Col xs="auto">
                <Row>
                  <Col xs="auto">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={(event) => {
                        event.preventDefault();

                        navigate(RoutePaths.USERS);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isLoading || isUserCompanyOwner}
                      onClick={(event) => {
                        event.preventDefault();

                        if (loadedCompany?.id) {
                          upsertCompanyUserMutation({
                            body: transformCompanyUserDataForAPIRequestBody({
                              companyId: loadedCompany?.id,
                              data: companyUserData,
                            }),
                            urlArgs: { userId: companyUserData.userId || "" },
                          });
                        }
                      }}
                    >
                      {isLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            {requestErrors?.message && (
              <Row>
                <Alert variant="danger">{requestErrors?.message}</Alert>
              </Row>
            )}
            {isUserCompanyOwner && (
              <Row>
                <Alert variant="info">
                  This user is company owner and cannot be modified.
                </Alert>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
      <DeleteUserModal show={show} toggleModal={toggleModal} />
    </Container>
  );
};
