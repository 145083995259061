import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { IErrorData, IErrorAPIRequest } from "./apiErrors";

export const processCompanyUserRequestErrors = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  // eslint-disable-next-line prefer-const
  let formValidationErrors = {};
  let apiRequestErrors: IErrorAPIRequest = {};

  if (error && "status" in error) {
    switch (error.status) {
      case "CUSTOM_ERROR":
      // fallsthrough
      case "PARSING_ERROR":
      // fallsthrough
      case "FETCH_ERROR":
        apiRequestErrors = {
          ...apiRequestErrors,
          message: "Something went wrong. Please try again later.",
        };
        break;
      default:
      // status is number, handle that in next IF statement
    }
  }

  if (error && "data" in error) {
    const data = error.data as IErrorData;

    if (Array.isArray(data?.message)) {
      data.message.forEach((errorMessage: string) => {
        /**
         * Order errors by priority
         * First one will be triggered and displayed.
         * Put specific errors on top, and generic errors on bottom
         */
        switch (errorMessage) {
          // formValidationErrors = {
          //   ...formValidationErrors,
          //   addressLine1: "Address line 1 is required",
          // };
          /**
           * company id
           */
          case "companyId is invalid":
            apiRequestErrors = {
              ...apiRequestErrors,
              message: "Something went wrong. Please try again later.",
            };
            break;
          /**
           * first name (sign up / register)
           */
          case "firstName must be shorter than or equal to 100 characters":
            formValidationErrors = {
              ...formValidationErrors,
              firstName: "First name cannot be longer than 100 characters",
            };
            break;
          case "firstName must be longer than or equal to 2 characters":
            formValidationErrors = {
              ...formValidationErrors,
              firstName: "First name cannot be shorter than 2 characters",
            };
            break;
          case "firstName must be a string":
            formValidationErrors = {
              ...formValidationErrors,
              firstName: "Invalid value",
            };
            break;
          case "firstName should not be empty":
            formValidationErrors = {
              ...formValidationErrors,
              firstName: "First name is required",
            };
            break;
          case "user.role must be a valid enum value":
            formValidationErrors = {
              ...formValidationErrors,
              userRole: "User role is required",
            };
            break;
          default:
            if (
              errorMessage.startsWith("email must match") &&
              errorMessage.endsWith("regular expression")
            ) {
              formValidationErrors = {
                ...formValidationErrors,
                email: "Email is invalid",
              };
              break;
            }
        }
      });
    } else {
      // it's a string
      switch (data.message) {
        case "Invalid credentials. Please try again.":
          apiRequestErrors = {
            ...apiRequestErrors,
            message: "Invalid credentials. Please login again.",
          };
          break;
        case "User is already assigned to this company.":
          apiRequestErrors = {
            ...apiRequestErrors,
            message: "User is already assigned to this company.",
          };
          break;
        default:
          /**
           * Can be message like:
           *   "Cannot POST /api/something-that-doesnt-exist"
           *   "Internal server error"
           */
          apiRequestErrors = {
            ...apiRequestErrors,
            message: "Something went wrong. Please try again later.",
          };
      }
    }
  }

  return { formValidationErrors, authRequestErrors: apiRequestErrors };
};
