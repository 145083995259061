import React, { useEffect, useState } from "react";
import { IErrorAPIRequest } from "../../../errors/apiErrors";
import { processCompanyUserRequestErrors } from "../../../errors/companyUserErrors";
import { useAppDispatch } from "../../../store/redux-hooks";
import {
  ICreateOrUpdateCompanyUserRequest,
  useCreateCompanyUserMutation,
  useUpdateCompanyUserMutation,
} from "../../../store/slices/apiSlice";
import { updateCompanyUserFormErrors } from "../../../store/slices/companyUsersSlice";

interface UpsertCompanyUserMutation {
  upsertCompanyUserMutation: (
    obj: ICreateOrUpdateCompanyUserRequest
  ) => unknown;
  isSuccess: boolean;
  isLoading: boolean;
  requestErrors?: IErrorAPIRequest;
  setRequestErrors: React.Dispatch<
    React.SetStateAction<IErrorAPIRequest | undefined>
  >;
}

const useUpsertCompanyUserMutation = (
  isFormEdit: boolean
): UpsertCompanyUserMutation => {
  const dispatch = useAppDispatch();

  const [requestErrors, setRequestErrors] = useState<IErrorAPIRequest>();

  const [
    createCompany,
    {
      isSuccess: createCompanyUserIsSuccess,
      isLoading: createCompanyUserIsLoading,
      error: companyUserCreateError,
    },
  ] = useCreateCompanyUserMutation();

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processCompanyUserRequestErrors(companyUserCreateError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateCompanyUserFormErrors(formValidationErrors));
  }, [companyUserCreateError, dispatch]);

  const [
    updateCompany,
    {
      isSuccess: updateCompanyUserIsSuccess,
      isLoading: updateCompanyUserIsLoading,
      error: companyUserUpdateError,
    },
  ] = useUpdateCompanyUserMutation();

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processCompanyUserRequestErrors(companyUserUpdateError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateCompanyUserFormErrors(formValidationErrors));
  }, [companyUserUpdateError, dispatch]);

  if (!isFormEdit) {
    return {
      upsertCompanyUserMutation: (obj) => createCompany(obj),
      isSuccess: createCompanyUserIsSuccess,
      isLoading: createCompanyUserIsLoading,
      requestErrors,
      setRequestErrors,
    };
  } else {
    return {
      upsertCompanyUserMutation: ({
        body: { email, firstName, ...rest },
        urlArgs,
      }) => updateCompany({ urlArgs, body: rest }),
      isSuccess: updateCompanyUserIsSuccess,
      isLoading: updateCompanyUserIsLoading,
      requestErrors,
      setRequestErrors,
    };
  }
};

export default useUpsertCompanyUserMutation;
