import {
  Alert,
  Card,
  Col,
  Container,
  ListGroup,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { ReactComponent as IconLogo } from "../../../assets/icons/logo.svg";

import { RoutePaths } from "../../../routes/routes";

import { useAppDispatch, useAppSelector } from "../../../store/redux-hooks";
import { useCallback, useEffect } from "react";
import {
  useGetCompaniesQuery,
  useSelectCompanyMutation,
} from "../../../store/slices/apiSlice";
import { setLoadedCompany } from "../../../store/slices/companySlice";
import useSelectingCompanyRedirect from "../../../hooks/useSelectingCompanyRedirect";
import { transformCompanyResponseToLoadedCompany } from "../../../util/functions";
import { SELECT_COMPANY_RESPONSE_MESSAGE_SUCCESS } from "../../../util/constants/constants";

export default () => {
  const userAuthData = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  useSelectingCompanyRedirect();

  const { data: companies, isFetching } = useGetCompaniesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [
    selectCompanyMutation,
    {
      data: selectCompanyData,
      isLoading: isLoadingSelectCompany,
      isError,
      originalArgs,
    },
  ] = useSelectCompanyMutation();

  const selectCompany = useCallback(
    (id?: string) => () => {
      if (id) {
        // send selection to backend
        selectCompanyMutation({ body: { companyId: id } });
      }
      // if (id) {
      //   const company = companies?.result?.find(
      //     (company) => company._id === id
      //   );

      //   if (company?._id) {
      //     // send selection to backend
      //     selectCompanyMutation({ body: { companyId: company._id } });
      //   }
      // }
    },
    [selectCompanyMutation]
    // [companies?.result, selectCompanyMutation]
  );

  // process successful company selection
  useEffect(() => {
    if (
      selectCompanyData?.message === SELECT_COMPANY_RESPONSE_MESSAGE_SUCCESS
    ) {
      const company = companies?.result?.find(
        (company) => company._id === originalArgs?.body.companyId
      );

      if (company?._id) {
        // set loaded company in store
        dispatch(
          setLoadedCompany(transformCompanyResponseToLoadedCompany(company))
        );
      }
    }
  }, [
    companies?.result,
    originalArgs?.body.companyId,
    selectCompanyData,
    dispatch,
  ]);

  // if user has selectedCompanyId in JWT token, automatically load that company
  useEffect(() => {
    if (userAuthData?.selectedCompanyId && companies?.result) {
      const company = companies?.result?.find(
        (company) => company._id === userAuthData?.selectedCompanyId
      );

      if (company?._id) {
        // set loaded company in store
        dispatch(
          setLoadedCompany(transformCompanyResponseToLoadedCompany(company))
        );
      }
    }
  }, [companies?.result, userAuthData?.selectedCompanyId, dispatch]);

  // if user has no companies, send them to second sign up step
  useEffect(() => {
    if (companies?.result?.length === 0) {
      navigate(RoutePaths.SIGNUP_COMPANY_CREATE);
    }
  }, [companies?.result?.length, navigate]);

  // if it's just 1 company, auto-login to that one¸
  useEffect(() => {
    if (companies?.result?.length === 1) {
      selectCompany(companies.result?.[0]?._id)();
    }
  }, [companies?.result, selectCompany]);

  return (
    <Container fluid className="height-100 px-0 authForms" id="selectCompany">
      <Row className="max-width-medium mx-auto my-auto align-items-center height-100">
        <Card body border="light" className="px-0 px-sm-5 py-5">
          <Col>
            {
              // if there's only one result, keep spinner as we'll redirect user momentarily
              (isFetching && companies?.result?.length !== 1) ||
              isLoadingSelectCompany ? (
                <Row className="justify-content-center">
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                </Row>
              ) : (
                <>
                  <Row className="justify-content-center mb-4">
                    <h1 className="w-auto">Select Company</h1>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs={8}>
                      <ListGroup>
                        {companies?.result?.map((company, index) => (
                          <ListGroup.Item
                            key={index}
                            action
                            onClick={selectCompany(company._id)}
                          >
                            <Row className="p-2">
                              <Col xs="auto">
                                <IconLogo
                                  className="select-company-logo"
                                  width="16"
                                  height="24"
                                />
                              </Col>
                              <OverlayTrigger
                                // TODO: preparation, use something like this if we want tooltip only for long names
                                // show={
                                //   (company.name?.length || 1) < 20
                                //     ? false
                                //     : undefined
                                // }
                                rootClose
                                placement="bottom"
                                trigger={["hover", "focus"]}
                                overlay={
                                  <Tooltip>
                                    <span>{company.name}</span>
                                  </Tooltip>
                                }
                              >
                                <Col
                                  className={`fw-bold text-truncate select-company-name-display`}
                                >
                                  {company.name}
                                </Col>
                              </OverlayTrigger>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </Col>
                  </Row>
                </>
              )
            }
            {isError && (
              <Row className="mt-4">
                <Alert variant="danger">
                  Something went wrong. Please try again later.
                </Alert>
              </Row>
            )}
          </Col>
        </Card>
      </Row>
    </Container>
  );
};
