import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";

import { RoutePaths } from "../../../routes/routes";

import { useAppDispatch, useAppSelector } from "../../../store/redux-hooks";
import {
  updateRecoverAccountFormErrors,
  updateRecoverAccountForm,
} from "../../../store/slices/authSlice";
import { processAuthRequestErrors } from "../../../errors/authErrors";
import { useRecoverAccountMutation } from "../../../store/slices/apiSlice";
import { IErrorAPIRequest } from "../../../errors/apiErrors";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { MAIN_PASSWORD_FIELD_PLACEHOLDER_TEXT } from "../../../util/constants/constants";
import RequiredFieldBadge from "../../common/RequiredFieldBadge";

export default () => {
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [showPasswords, setShowPasswords] = useState({
    password: false,
    repeatPassword: false,
  });

  const toggleShowPassword = (field: "password" | "repeatPassword") => () => {
    setShowPasswords({ ...showPasswords, [field]: !showPasswords[field] });
  };

  const navigate = useNavigate();

  const [urlSearchParams] = useSearchParams();
  const queryStringRecoveryClaim = urlSearchParams.get("recoveryClaim");

  // TODO: check if claim is expired or missing? redirect, show error message?
  // useEffect(() => {
  // }, [ queryStringRecoveryClaim]);

  const [requestErrors, setRequestErrors] = useState<IErrorAPIRequest>();

  const [
    resetPasswordQuery,
    { isSuccess, error: recoverAccountError, isLoading },
  ] = useRecoverAccountMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate(RoutePaths.HOME);
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    const { formValidationErrors, authRequestErrors } =
      processAuthRequestErrors(recoverAccountError);

    authRequestErrors && setRequestErrors(authRequestErrors);
    formValidationErrors &&
      dispatch(updateRecoverAccountFormErrors(formValidationErrors));
  }, [recoverAccountError, dispatch]);

  return (
    <Container
      fluid
      className="height-100 px-0 authForms"
      id="resetPasswordForm"
    >
      <Row className="max-width-560px mx-auto my-auto align-items-center height-100">
        <Card body border="light" className="px-0 px-sm-5 py-5">
          <Form>
            <Col>
              <Row className="justify-content-center mb-4">
                <h1 className="w-auto">Set New Password</h1>
              </Row>
              <Row>
                <Form.Group
                  className="mb-4"
                  controlId="recoverAccountNewPassword"
                >
                  <Form.Label>
                    Choose a password
                    <RequiredFieldBadge />
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPasswords.password ? "text" : "password"}
                      placeholder={MAIN_PASSWORD_FIELD_PLACEHOLDER_TEXT}
                      value={authState.recoverAccountData.password}
                      onChange={({ target: { value } }) =>
                        dispatch(updateRecoverAccountForm({ password: value }))
                      }
                      isInvalid={Boolean(
                        authState.recoverAccountData.errors?.password
                      )}
                    />
                    <Button
                      variant="outline-secondary"
                      id="toggle-password"
                      type="button"
                      onClick={toggleShowPassword("password")}
                      // https://github.com/twbs/bootstrap/issues/36049
                      className="border-radius-right-8px"
                    >
                      {showPasswords.password ? (
                        <Eye size="1.7em" />
                      ) : (
                        <EyeSlash size="1.7em" />
                      )}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      {authState.recoverAccountData.errors?.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  className="mb-5"
                  controlId="recoverAccountRepeatPassword"
                >
                  <Form.Label>
                    Repeat password
                    <RequiredFieldBadge />
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPasswords.repeatPassword ? "text" : "password"}
                      value={authState.recoverAccountData.repeatPassword}
                      onChange={({ target: { value } }) =>
                        dispatch(
                          updateRecoverAccountForm({ repeatPassword: value })
                        )
                      }
                      isValid={
                        Boolean(authState.recoverAccountData.repeatPassword) &&
                        authState.recoverAccountData.password ===
                          authState.recoverAccountData.repeatPassword
                      }
                      isInvalid={
                        authState.recoverAccountData.password !==
                        authState.recoverAccountData.repeatPassword
                      }
                    />
                    <Button
                      variant="outline-secondary"
                      id="toggle-repeat-password"
                      type="button"
                      onClick={toggleShowPassword("repeatPassword")}
                      // https://github.com/twbs/bootstrap/issues/36049
                      className="border-radius-right-8px"
                    >
                      {showPasswords.repeatPassword ? (
                        <Eye size="1.7em" />
                      ) : (
                        <EyeSlash size="1.7em" />
                      )}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      Passwords do not match
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
              <Row
                className={
                  "justify-content-center " +
                  (requestErrors?.message ? "mb-3" : "mb-5")
                }
              >
                <Button
                  variant="dark"
                  type="submit"
                  className="w-auto"
                  tabIndex={3}
                  disabled={isLoading}
                  onClick={(event) => {
                    event.preventDefault();

                    if (
                      authState.recoverAccountData.password ===
                        authState.recoverAccountData.repeatPassword &&
                      authState.recoverAccountData.repeatPassword
                    ) {
                      resetPasswordQuery({
                        // TODO: rework this?
                        recoveryClaim: queryStringRecoveryClaim || "",
                        password: authState.recoverAccountData.password,
                      });
                    } else {
                      setRequestErrors({
                        message: "Please fill the form fields.",
                      });
                    }
                  }}
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Set New Password"
                  )}
                </Button>
              </Row>
              {requestErrors?.message && (
                <Row>
                  <Alert variant="danger">{requestErrors?.message}</Alert>
                </Row>
              )}
            </Col>
          </Form>
        </Card>
      </Row>
    </Container>
  );
};
