import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  companyTypeLimitedCompany,
  ICompanyType,
} from "../../util/constants/companyTypes";
import { countryIreland, ICountry } from "../../util/constants/countries";
import { currencyEuro, ICurrency } from "../../util/constants/currencies";
import {
  IVatRegistrationType,
  vatRegistrationTypeTrue,
} from "../../util/constants/vatRegistrationTypes";
import { ICompanyUserRolesAndPermissions } from "./apiSlice";

export interface ICompanyBaseSchema {
  country: ICountry | null;
  companyType: ICompanyType | null;
  currency: ICurrency | null;
  isVatRegistered: IVatRegistrationType | null;
  //
  name: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  phoneNumber: string | null;
  companyNumber: string | null;
  vatNumber: string | null;
  taxNumber: string | null;
  website: string | null;
}

export interface ILoadedCompanySchema {
  id: string;
  country?: ICountry | null;
  companyType?: ICompanyType | null;
  currency?: ICurrency | null;
  isVatRegistered?: IVatRegistrationType | null;
  //
  name: string;
  addressLine1?: string | null;
  addressLine2?: string | null;
  phoneNumber?: string | null;
  companyNumber?: string | null;
  vatNumber?: string | null;
  taxNumber?: string | null;
  website?: string | null;
  //
  createdAt?: Date | null;
  updatedAt?: Date | null;
  trialExpirationDate?: Date | null;
  companyOwnerId?: string | null;
  //
  companyUserRolesAndPermissions?: ICompanyUserRolesAndPermissions;
  loadingCompanyUserRolesAndPermissions: boolean;
  subscriptionAmount?: string | number;
  subscriptionChargedUsersCount?: string | number;
}

export interface ICompanyFormData extends ICompanyBaseSchema {
  id?: string | null;
  //
  errors: {
    country: string | null;
    companyType: string | null;
    currency: string | null;
    isVatRegistered: string | null;
    //
    name: string | null;
    addressLine1: string | null;
    addressLine2: string | null;
    phoneNumber: string | null;
    companyNumber: string | null;
    vatNumber: string | null;
    taxNumber: string | null;
    website: string | null;
  };
}
export interface ICompanySliceState {
  loadedCompany?: ILoadedCompanySchema | null;
  formData: ICompanyFormData;
}

const companySliceInitialStateFormDataErrors = {
  country: null,
  companyType: null,
  currency: null,
  isVatRegistered: null,
  //
  name: null,
  addressLine1: null,
  addressLine2: null,
  phoneNumber: null,
  companyNumber: null,
  vatNumber: null,
  taxNumber: null,
  website: null,
};

export const defaultCompanySliceFormData = () => ({
  id: null,
  country: countryIreland,
  companyType: null,
  currency: null,
  isVatRegistered: vatRegistrationTypeTrue,
  name: null,
  addressLine1: null,
  addressLine2: null,
  phoneNumber: null,
  companyNumber: null,
  vatNumber: null,
  taxNumber: null,
  website: null,
  errors: { ...companySliceInitialStateFormDataErrors },
});

const initialState: ICompanySliceState = {
  // formType:,
  loadedCompany: null,
  formData: defaultCompanySliceFormData(),
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setLoadedCompany: (
      state,
      action: PayloadAction<Omit<
        ILoadedCompanySchema,
        "loadingCompanyUserRolesAndPermissions"
      > | null>
    ) => {
      state.loadedCompany = action.payload
        ? {
            ...state.loadedCompany,
            // if company changed, override roles and permissions, they'll need to be retrieved again
            ...(state.loadedCompany?.id !== action.payload.id
              ? {
                  companyUserRolesAndPermissions: undefined,
                  loadingCompanyUserRolesAndPermissions: true,
                }
              : { loadingCompanyUserRolesAndPermissions: false }),
            ...action.payload,
          }
        : null;
    },
    setLoadedCompanyUserRolesAndPermissions: (
      state,
      action: PayloadAction<ICompanyUserRolesAndPermissions | undefined>
    ) => {
      state.loadedCompany = state.loadedCompany
        ? {
            ...state.loadedCompany,
            companyUserRolesAndPermissions: action.payload,
            loadingCompanyUserRolesAndPermissions: false,
          }
        : null;
    },
    changeCompanyForm: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action: PayloadAction<Partial<ICompanyFormData | { [key: string]: any }>>
    ) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
        /**
         * Note on '!action.payload.id &&' part:
         *  These overrides for currency and companyType should apply only when
         *  creating a new company, and not when editing existing entities.
         *
         * Note2:
         *  If overriding any CHANGEABLE value, be mindful to account for different
         *  scenarios between creating and editing (including loading data into the form!)
         */
        // if country changed to IE, set currency to euro and company type default values
        ...(!action.payload.id &&
          action.payload?.country?.countryCode === "IE" && {
            currency: currencyEuro,
            companyType: companyTypeLimitedCompany,
          }),
        /**
         * if country changed, but WAS ireland thus far, reset currency and company type
         *  UNLESS user re-selected Ireland (in which case don't reset anything)
         */
        ...(!action.payload.id &&
          state.formData.country?.countryCode === "IE" &&
          state.formData.country?.countryCode !==
            action.payload?.country?.countryCode &&
          action.payload?.country?.countryCode && {
            currency: null,
            companyType: null,
          }),
        // errors
        errors: {
          ...state.formData.errors,
          // remove error from any property that was updated
          ...Object.keys(action.payload).reduce(
            (acc, curr) => ({ ...acc, [curr]: undefined }),
            {}
          ),
        },
      };
    },
    updateCompanyFormErrors: (state, action) => {
      state.formData = {
        ...state.formData,
        errors: action.payload,
      };
    },
  },
});

export const {
  setLoadedCompany,
  changeCompanyForm,
  updateCompanyFormErrors,
  setLoadedCompanyUserRolesAndPermissions,
} = companySlice.actions;

export default companySlice.reducer;
