export const transformStripeInvoicePaymentStatus = (status: string) => {
  switch (status) {
    case "draft":
      return "DRAFT";
    case "open":
      return "DUE";
    case "paid":
      return "PAID";
    case "void":
      return "void";
    case "uncollectible":
      return "Unknown";
    default:
      return "Unknown";
  }
};

export const generateStripeInvoicePaymentStatusTagClassName = (
  status: string
) => {
  switch (status) {
    case "draft":
      return "tag-greyish";
    case "open":
      return "tag-faded-orange";
    case "paid":
      return "tag-seafoam-blue";
    case "void":
      return "tag-greyish";
    case "uncollectible":
      return "tag-brick-orange";
    default:
      return "tag-brick-orange";
  }
};
