import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Variant } from "react-bootstrap/esm/types";

import { NotificationAlertRoutes } from "../../util/constants/types/notificationConstants";

// export interface INotificationToast {
//   id: string;
//   delay?: number;
//   message?: string;
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   extra?: any;
// }

export interface INotificationAlert {
  id: string;
  forRoute: NotificationAlertRoutes;
  variant?: Variant;
  message?: string;
  //
  createdAt: number;
  /**
   * uniqueKey - used to prevent duplicate entries, if an entry with a
   * specific key already exists, new alert with the same key will be ignored
   */
  uniqueKey?: string;
}

interface NotificationSliceState {
  //   notifications: INotificationToast[];
  alerts: INotificationAlert[];
}

const initialState: NotificationSliceState = {
  //   notifications: [],
  alerts: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    // addNotification: (
    //   state,
    //   action: PayloadAction<Omit<INotificationToast, "id">>
    // ) => {
    //   state.notifications.push({
    //     ...action.payload,
    //     id: String(Math.round(Math.random() * (9999999 - 1) + 1)).padStart(
    //       7,
    //       "0"
    //     ),
    //   });
    // },
    // removeNotification: (
    //   state,
    //   action: PayloadAction<INotificationToast["id"]>
    // ) => {
    //   state.notifications.splice(
    //     state.notifications.findIndex(
    //       (notification) => notification.id === action.payload
    //     ),
    //     1
    //   );
    // },
    /**
     * ALERTS
     */
    addAlert: (
      state,
      action: PayloadAction<Omit<INotificationAlert, "id" | "createdAt">>
    ) => {
      if (
        state.alerts.findIndex(
          (alert) =>
            action.payload.uniqueKey &&
            alert.uniqueKey === action.payload.uniqueKey
        ) === -1
      ) {
        state.alerts.push({
          ...action.payload,
          createdAt: Date.now(),
          id: String(Math.round(Math.random() * (9999999 - 1) + 1)).padStart(
            7,
            "0"
          ),
        });
      }
    },
    removeAlertsForRoute: (
      state,
      action: PayloadAction<INotificationAlert["forRoute"]>
    ) => {
      state.alerts = state.alerts.filter(
        (alert) =>
          // if alert was created less than 100 miliseconds ago, don't remove it
          Date.now() - alert.createdAt < 100 ||
          alert.forRoute !== action.payload
      );
    },
    removeAlert: (state, action: PayloadAction<INotificationAlert["id"]>) => {
      const index = state.alerts.findIndex(
        (alert) => alert.id === action.payload
      );

      if (index !== -1) {
        state.alerts.splice(index, 1);
      }
    },
    resetNotifications: () => initialState,
  },
});

export const {
  //   addNotification,
  //   removeNotification,
  addAlert,
  removeAlertsForRoute,
  removeAlert,
  resetNotifications,
} = notificationSlice.actions;

export default notificationSlice.reducer;
