import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../routes/routes";

export default () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === RoutePaths.SETTINGS) {
      navigate(RoutePaths.SETTINGS_COMPANY, { replace: true });
    }
  }, [navigate, location]);

  return (
    <>
      <Outlet />
    </>
  );
};
