import { GroupBase } from "react-select";

export interface ICountry {
  countryCode: string;
  countryName: string;
}

const otherCountries: ICountry[] = [
  {
    countryCode: "AF",
    countryName: "Afghanistan",
  },
  {
    countryCode: "AX",
    countryName: "Åland Islands",
  },
  {
    countryCode: "AL",
    countryName: "Albania",
  },
  {
    countryCode: "DZ",
    countryName: "Algeria",
  },
  {
    countryCode: "AS",
    countryName: "American Samoa",
  },
  {
    countryCode: "AD",
    countryName: "Andorra",
  },
  {
    countryCode: "AO",
    countryName: "Angola",
  },
  {
    countryCode: "AI",
    countryName: "Anguilla",
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
  },
  {
    countryCode: "AG",
    countryName: "Antigua And Barbuda",
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
  },
  {
    countryCode: "AM",
    countryName: "Armenia",
  },
  {
    countryCode: "AW",
    countryName: "Aruba",
  },
  {
    countryCode: "AU",
    countryName: "Australia",
  },
  {
    countryCode: "AT",
    countryName: "Austria",
  },
  {
    countryCode: "AZ",
    countryName: "Azerbaijan",
  },
  {
    countryCode: "BS",
    countryName: "Bahamas",
  },
  {
    countryCode: "BH",
    countryName: "Bahrain",
  },
  {
    countryCode: "BD",
    countryName: "Bangladesh",
  },
  {
    countryCode: "BB",
    countryName: "Barbados",
  },
  {
    countryCode: "BY",
    countryName: "Belarus",
  },
  {
    countryCode: "BE",
    countryName: "Belgium",
  },
  {
    countryCode: "BZ",
    countryName: "Belize",
  },
  {
    countryCode: "BJ",
    countryName: "Benin",
  },
  {
    countryCode: "BM",
    countryName: "Bermuda",
  },
  {
    countryCode: "BT",
    countryName: "Bhutan",
  },
  {
    countryCode: "BO",
    countryName: "Bolivia, Plurinational State Of",
  },
  {
    countryCode: "BQ",
    countryName: "Bonaire, Sint Eustatius And Saba",
  },
  {
    countryCode: "BA",
    countryName: "Bosnia And Herzegovina",
  },
  {
    countryCode: "BW",
    countryName: "Botswana",
  },
  {
    countryCode: "BV",
    countryName: "Bouvet Island",
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
  },
  {
    countryCode: "IO",
    countryName: "British Indian Ocean Territory",
  },
  {
    countryCode: "BN",
    countryName: "Brunei Darussalam",
  },
  {
    countryCode: "BG",
    countryName: "Bulgaria",
  },
  {
    countryCode: "BF",
    countryName: "Burkina Faso",
  },
  {
    countryCode: "BI",
    countryName: "Burundi",
  },
  {
    countryCode: "KH",
    countryName: "Cambodia",
  },
  {
    countryCode: "CM",
    countryName: "Cameroon",
  },
  {
    countryCode: "CA",
    countryName: "Canada",
  },
  {
    countryCode: "CV",
    countryName: "Cape Verde",
  },
  {
    countryCode: "KY",
    countryName: "Cayman Islands",
  },
  {
    countryCode: "CF",
    countryName: "Central African Republic",
  },
  {
    countryCode: "TD",
    countryName: "Chad",
  },
  {
    countryCode: "CL",
    countryName: "Chile",
  },
  {
    countryCode: "CN",
    countryName: "China",
  },
  {
    countryCode: "CX",
    countryName: "Christmas Island",
  },
  {
    countryCode: "CC",
    countryName: "Cocos (keeling) Islands",
  },
  {
    countryCode: "CO",
    countryName: "Colombia",
  },
  {
    countryCode: "KM",
    countryName: "Comoros",
  },
  {
    countryCode: "CG",
    countryName: "Congo",
  },
  {
    countryCode: "CD",
    countryName: "Congo, The Democratic Republic Of The",
  },
  {
    countryCode: "CK",
    countryName: "Cook Islands",
  },
  {
    countryCode: "CR",
    countryName: "Costa Rica",
  },
  {
    countryCode: "CI",
    countryName: "Côte D'ivoire",
  },
  {
    countryCode: "HR",
    countryName: "Croatia",
  },
  {
    countryCode: "CU",
    countryName: "Cuba",
  },
  {
    countryCode: "CW",
    countryName: "Curaçao",
  },
  {
    countryCode: "CY",
    countryName: "Cyprus",
  },
  {
    countryCode: "CZ",
    countryName: "Czech Republic",
  },
  {
    countryCode: "DK",
    countryName: "Denmark",
  },
  {
    countryCode: "DJ",
    countryName: "Djibouti",
  },
  {
    countryCode: "DM",
    countryName: "Dominica",
  },
  {
    countryCode: "DO",
    countryName: "Dominican Republic",
  },
  {
    countryCode: "EC",
    countryName: "Ecuador",
  },
  {
    countryCode: "EG",
    countryName: "Egypt",
  },
  {
    countryCode: "SV",
    countryName: "El Salvador",
  },
  {
    countryCode: "GQ",
    countryName: "Equatorial Guinea",
  },
  {
    countryCode: "ER",
    countryName: "Eritrea",
  },
  {
    countryCode: "EE",
    countryName: "Estonia",
  },
  {
    countryCode: "ET",
    countryName: "Ethiopia",
  },
  {
    countryCode: "FK",
    countryName: "Falkland Islands (malvinas)",
  },
  {
    countryCode: "FO",
    countryName: "Faroe Islands",
  },
  {
    countryCode: "FJ",
    countryName: "Fiji",
  },
  {
    countryCode: "FI",
    countryName: "Finland",
  },
  {
    countryCode: "FR",
    countryName: "France",
  },
  {
    countryCode: "GF",
    countryName: "French Guiana",
  },
  {
    countryCode: "PF",
    countryName: "French Polynesia",
  },
  {
    countryCode: "TF",
    countryName: "French Southern Territories",
  },
  {
    countryCode: "GA",
    countryName: "Gabon",
  },
  {
    countryCode: "GM",
    countryName: "Gambia",
  },
  {
    countryCode: "GE",
    countryName: "Georgia",
  },
  {
    countryCode: "DE",
    countryName: "Germany",
  },
  {
    countryCode: "GH",
    countryName: "Ghana",
  },
  {
    countryCode: "GI",
    countryName: "Gibraltar",
  },
  {
    countryCode: "GR",
    countryName: "Greece",
  },
  {
    countryCode: "GL",
    countryName: "Greenland",
  },
  {
    countryCode: "GD",
    countryName: "Grenada",
  },
  {
    countryCode: "GP",
    countryName: "Guadeloupe",
  },
  {
    countryCode: "GU",
    countryName: "Guam",
  },
  {
    countryCode: "GT",
    countryName: "Guatemala",
  },
  {
    countryCode: "GG",
    countryName: "Guernsey",
  },
  {
    countryCode: "GN",
    countryName: "Guinea",
  },
  {
    countryCode: "GW",
    countryName: "Guinea-bissau",
  },
  {
    countryCode: "GY",
    countryName: "Guyana",
  },
  {
    countryCode: "HT",
    countryName: "Haiti",
  },
  {
    countryCode: "HM",
    countryName: "Heard Island And Mcdonald Islands",
  },
  {
    countryCode: "VA",
    countryName: "Holy See (vatican City State)",
  },
  {
    countryCode: "HN",
    countryName: "Honduras",
  },
  {
    countryCode: "HK",
    countryName: "Hong Kong",
  },
  {
    countryCode: "HU",
    countryName: "Hungary",
  },
  {
    countryCode: "IS",
    countryName: "Iceland",
  },
  {
    countryCode: "IN",
    countryName: "India",
  },
  {
    countryCode: "ID",
    countryName: "Indonesia",
  },
  {
    countryCode: "IR",
    countryName: "Iran, Islamic Republic Of",
  },
  {
    countryCode: "IQ",
    countryName: "Iraq",
  },
  //   ireland,
  {
    countryCode: "IM",
    countryName: "Isle Of Man",
  },
  {
    countryCode: "IL",
    countryName: "Israel",
  },
  {
    countryCode: "IT",
    countryName: "Italy",
  },
  {
    countryCode: "JM",
    countryName: "Jamaica",
  },
  {
    countryCode: "JP",
    countryName: "Japan",
  },
  {
    countryCode: "JE",
    countryName: "Jersey",
  },
  {
    countryCode: "JO",
    countryName: "Jordan",
  },
  {
    countryCode: "KZ",
    countryName: "Kazakhstan",
  },
  {
    countryCode: "KE",
    countryName: "Kenya",
  },
  {
    countryCode: "KI",
    countryName: "Kiribati",
  },
  {
    countryCode: "KP",
    countryName: "Korea, Democratic People's Republic Of",
  },
  {
    countryCode: "KR",
    countryName: "Korea, Republic Of",
  },
  {
    countryCode: "KW",
    countryName: "Kuwait",
  },
  {
    countryCode: "KG",
    countryName: "Kyrgyzstan",
  },
  {
    countryCode: "LA",
    countryName: "Lao People's Democratic Republic",
  },
  {
    countryCode: "LV",
    countryName: "Latvia",
  },
  {
    countryCode: "LB",
    countryName: "Lebanon",
  },
  {
    countryCode: "LS",
    countryName: "Lesotho",
  },
  {
    countryCode: "LR",
    countryName: "Liberia",
  },
  {
    countryCode: "LY",
    countryName: "Libya",
  },
  {
    countryCode: "LI",
    countryName: "Liechtenstein",
  },
  {
    countryCode: "LT",
    countryName: "Lithuania",
  },
  {
    countryCode: "LU",
    countryName: "Luxembourg",
  },
  {
    countryCode: "MO",
    countryName: "Macao",
  },
  {
    countryCode: "MK",
    countryName: "Macedonia, The Former Yugoslav Republic Of",
  },
  {
    countryCode: "MG",
    countryName: "Madagascar",
  },
  {
    countryCode: "MW",
    countryName: "Malawi",
  },
  {
    countryCode: "MY",
    countryName: "Malaysia",
  },
  {
    countryCode: "MV",
    countryName: "Maldives",
  },
  {
    countryCode: "ML",
    countryName: "Mali",
  },
  {
    countryCode: "MT",
    countryName: "Malta",
  },
  {
    countryCode: "MH",
    countryName: "Marshall Islands",
  },
  {
    countryCode: "MQ",
    countryName: "Martinique",
  },
  {
    countryCode: "MR",
    countryName: "Mauritania",
  },
  {
    countryCode: "MU",
    countryName: "Mauritius",
  },
  {
    countryCode: "YT",
    countryName: "Mayotte",
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
  },
  {
    countryCode: "FM",
    countryName: "Micronesia, Federated States Of",
  },
  {
    countryCode: "MD",
    countryName: "Moldova, Republic Of",
  },
  {
    countryCode: "MC",
    countryName: "Monaco",
  },
  {
    countryCode: "MN",
    countryName: "Mongolia",
  },
  {
    countryCode: "ME",
    countryName: "Montenegro",
  },
  {
    countryCode: "MS",
    countryName: "Montserrat",
  },
  {
    countryCode: "MA",
    countryName: "Morocco",
  },
  {
    countryCode: "MZ",
    countryName: "Mozambique",
  },
  {
    countryCode: "MM",
    countryName: "Myanmar",
  },
  {
    countryCode: "NA",
    countryName: "Namibia",
  },
  {
    countryCode: "NR",
    countryName: "Nauru",
  },
  {
    countryCode: "NP",
    countryName: "Nepal",
  },
  {
    countryCode: "NL",
    countryName: "Netherlands",
  },
  {
    countryCode: "NC",
    countryName: "New Caledonia",
  },
  {
    countryCode: "NZ",
    countryName: "New Zealand",
  },
  {
    countryCode: "NI",
    countryName: "Nicaragua",
  },
  {
    countryCode: "NE",
    countryName: "Niger",
  },
  {
    countryCode: "NG",
    countryName: "Nigeria",
  },
  {
    countryCode: "NU",
    countryName: "Niue",
  },
  {
    countryCode: "NF",
    countryName: "Norfolk Island",
  },
  {
    countryCode: "MP",
    countryName: "Northern Mariana Islands",
  },
  {
    countryCode: "NO",
    countryName: "Norway",
  },
  {
    countryCode: "OM",
    countryName: "Oman",
  },
  {
    countryCode: "PK",
    countryName: "Pakistan",
  },
  {
    countryCode: "PW",
    countryName: "Palau",
  },
  {
    countryCode: "PS",
    countryName: "Palestine, State Of",
  },
  {
    countryCode: "PA",
    countryName: "Panama",
  },
  {
    countryCode: "PG",
    countryName: "Papua New Guinea",
  },
  {
    countryCode: "PY",
    countryName: "Paraguay",
  },
  {
    countryCode: "PE",
    countryName: "Peru",
  },
  {
    countryCode: "PH",
    countryName: "Philippines",
  },
  {
    countryCode: "PN",
    countryName: "Pitcairn",
  },
  {
    countryCode: "PL",
    countryName: "Poland",
  },
  {
    countryCode: "PT",
    countryName: "Portugal",
  },
  {
    countryCode: "PR",
    countryName: "Puerto Rico",
  },
  {
    countryCode: "QA",
    countryName: "Qatar",
  },
  {
    countryCode: "RE",
    countryName: "Réunion",
  },
  {
    countryCode: "RO",
    countryName: "Romania",
  },
  {
    countryCode: "RU",
    countryName: "Russian Federation",
  },
  {
    countryCode: "RW",
    countryName: "Rwanda",
  },
  {
    countryCode: "BL",
    countryName: "Saint Barthélemy",
  },
  {
    countryCode: "SH",
    countryName: "Saint Helena, Ascension And Tristan Da Cunha",
  },
  {
    countryCode: "KN",
    countryName: "Saint Kitts And Nevis",
  },
  {
    countryCode: "LC",
    countryName: "Saint Lucia",
  },
  {
    countryCode: "MF",
    countryName: "Saint Martin (french Part)",
  },
  {
    countryCode: "PM",
    countryName: "Saint Pierre And Miquelon",
  },
  {
    countryCode: "VC",
    countryName: "Saint Vincent And The Grenadines",
  },
  {
    countryCode: "WS",
    countryName: "Samoa",
  },
  {
    countryCode: "SM",
    countryName: "San Marino",
  },
  {
    countryCode: "ST",
    countryName: "Sao Tome And Principe",
  },
  {
    countryCode: "SA",
    countryName: "Saudi Arabia",
  },
  {
    countryCode: "SN",
    countryName: "Senegal",
  },
  {
    countryCode: "RS",
    countryName: "Serbia",
  },
  {
    countryCode: "SC",
    countryName: "Seychelles",
  },
  {
    countryCode: "SL",
    countryName: "Sierra Leone",
  },
  {
    countryCode: "SG",
    countryName: "Singapore",
  },
  {
    countryCode: "SX",
    countryName: "Sint Maarten (dutch Part)",
  },
  {
    countryCode: "SK",
    countryName: "Slovakia",
  },
  {
    countryCode: "SI",
    countryName: "Slovenia",
  },
  {
    countryCode: "SB",
    countryName: "Solomon Islands",
  },
  {
    countryCode: "SO",
    countryName: "Somalia",
  },
  {
    countryCode: "ZA",
    countryName: "South Africa",
  },
  {
    countryCode: "GS",
    countryName: "South Georgia And The South Sandwich Islands",
  },
  {
    countryCode: "SS",
    countryName: "South Sudan",
  },
  {
    countryCode: "ES",
    countryName: "Spain",
  },
  {
    countryCode: "LK",
    countryName: "Sri Lanka",
  },
  {
    countryCode: "SD",
    countryName: "Sudan",
  },
  {
    countryCode: "SR",
    countryName: "Suriname",
  },
  {
    countryCode: "SJ",
    countryName: "Svalbard And Jan Mayen",
  },
  {
    countryCode: "SZ",
    countryName: "Swaziland",
  },
  {
    countryCode: "SE",
    countryName: "Sweden",
  },
  {
    countryCode: "CH",
    countryName: "Switzerland",
  },
  {
    countryCode: "SY",
    countryName: "Syrian Arab Republic",
  },
  {
    countryCode: "TW",
    countryName: "Taiwan, Province Of China",
  },
  {
    countryCode: "TJ",
    countryName: "Tajikistan",
  },
  {
    countryCode: "TZ",
    countryName: "Tanzania, United Republic Of",
  },
  {
    countryCode: "TH",
    countryName: "Thailand",
  },
  {
    countryCode: "TL",
    countryName: "Timor-leste",
  },
  {
    countryCode: "TG",
    countryName: "Togo",
  },
  {
    countryCode: "TK",
    countryName: "Tokelau",
  },
  {
    countryCode: "TO",
    countryName: "Tonga",
  },
  {
    countryCode: "TT",
    countryName: "Trinidad And Tobago",
  },
  {
    countryCode: "TN",
    countryName: "Tunisia",
  },
  {
    countryCode: "TR",
    countryName: "Turkey",
  },
  {
    countryCode: "TM",
    countryName: "Turkmenistan",
  },
  {
    countryCode: "TC",
    countryName: "Turks And Caicos Islands",
  },
  {
    countryCode: "TV",
    countryName: "Tuvalu",
  },
  {
    countryCode: "UG",
    countryName: "Uganda",
  },
  {
    countryCode: "UA",
    countryName: "Ukraine",
  },
  {
    countryCode: "AE",
    countryName: "United Arab Emirates",
  },
  //   unitedKingdom,
  //   unitedStates,
  {
    countryCode: "UM",
    countryName: "United States Minor Outlying Islands",
  },
  {
    countryCode: "UY",
    countryName: "Uruguay",
  },
  {
    countryCode: "UZ",
    countryName: "Uzbekistan",
  },
  {
    countryCode: "VU",
    countryName: "Vanuatu",
  },
  {
    countryCode: "VE",
    countryName: "Venezuela, Bolivarian Republic Of",
  },
  {
    countryCode: "VN",
    countryName: "Viet Nam",
  },
  {
    countryCode: "VG",
    countryName: "Virgin Islands, British",
  },
  {
    countryCode: "VI",
    countryName: "Virgin Islands, U.s.",
  },
  {
    countryCode: "WF",
    countryName: "Wallis And Futuna",
  },
  {
    countryCode: "EH",
    countryName: "Western Sahara",
  },
  {
    countryCode: "YE",
    countryName: "Yemen",
  },
  {
    countryCode: "ZM",
    countryName: "Zambia",
  },
  {
    countryCode: "ZW",
    countryName: "Zimbabwe",
  },
];

export const countryIreland = {
  countryCode: "IE",
  countryName: "Ireland",
};

const frequentCountries: ICountry[] = [
  countryIreland,
  {
    countryCode: "GB",
    countryName: "United Kingdom",
  },
  {
    countryCode: "US",
    countryName: "United States",
  },
];

export const allCountries: ICountry[] = [
  ...frequentCountries,
  ...otherCountries,
];

export const countriesDropdown: GroupBase<ICountry>[] = [
  {
    label: "Frequent",
    options: frequentCountries,
  },
  {
    label: "All other",
    options: otherCountries,
  },
];
