import {
  AnyAction,
  combineReducers,
  configureStore,
  createAction,
} from "@reduxjs/toolkit";
import { RootStateOrAny } from "react-redux";

import { apiSlice } from "./slices/apiSlice";

import authSlice from "./slices/authSlice";
import companyBillingSlice from "./slices/companyBillingSlice";
import companyClientsSlice from "./slices/companyClientsSlice";
import companySlice from "./slices/companySlice";
import companyUsersSlice from "./slices/companyUsersSlice";
import notificationSlice from "./slices/notificationSlice";

import { apiErrorHandler } from "./middleware/apiErrorHandler";

const RESET_STORE = "RESET_STORE";

export const resetStore = createAction(RESET_STORE);

const combinedReducer = combineReducers({
  auth: authSlice,
  company: companySlice,
  companyUsers: companyUsersSlice,
  companyClients: companyClientsSlice,
  companyBilling: companyBillingSlice,
  notification: notificationSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const rootReducer = (state: RootStateOrAny, action: AnyAction) => {
  // Original Dan Abramov: https://stackoverflow.com/a/35641992/2873538
  // Redux toolkit update: https://stackoverflow.com/a/61943631
  if (action.type === RESET_STORE) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, apiErrorHandler),
  // only enable dev tools in development environment
  devTools: process.env?.NODE_ENV === "development",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
