import { useEffect } from "react";
import {
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
  Spinner,
} from "react-bootstrap";
import { Power } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as IconSettings } from "../../assets/icons/settings.svg";

import { RoutePaths } from "../../routes/routes";
import { useAppDispatch, useAppSelector } from "../../store/redux-hooks";
import {
  useLazyGetCompaniesQuery,
  useSelectCompanyMutation,
} from "../../store/slices/apiSlice";
import { setLoadedCompany } from "../../store/slices/companySlice";
import { resetNotifications } from "../../store/slices/notificationSlice";
import { PROJECTS_APP_URL, SELECT_COMPANY_RESPONSE_MESSAGE_SUCCESS } from "../../util/constants/constants";
import {
  AccountsAndPayrollRole,
  ProjectRole,
  ProposalRole,
} from "../../util/constants/userForm/userFormConstants";
import { transformCompanyResponseToLoadedCompany } from "../../util/functions";
import { showGlobalLoader } from "../common/GlobalLoader";

export default () => {
  const user = useAppSelector((state) => state.auth.user);
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [getCompanies, { data: companies }] = useLazyGetCompaniesQuery();

  useEffect(() => {
    // note: this is intentionally not from useLocation hook, as to not cause unnecessary re-runs of useEffect
    if (user?.id && window.location.pathname !== RoutePaths.LOGOUT) {
      getCompanies(undefined, true);
    }
  }, [user?.id, getCompanies]);

  const [
    selectCompanyMutation,
    {
      data: selectCompanyData,
      isLoading: isLoadingSelectCompany,
      // isError, // TODO: process error, display as toast?
      originalArgs,
    },
  ] = useSelectCompanyMutation();

  // process successful company selection
  useEffect(() => {
    if (
      selectCompanyData?.message === SELECT_COMPANY_RESPONSE_MESSAGE_SUCCESS
    ) {
      const company = companies?.result?.find(
        (company) => company._id === originalArgs?.body.companyId
      );

      if (company?._id) {
        // remove all notifications (they're for current (soon previous) company)
        dispatch(resetNotifications());
        // set loaded company in store
        dispatch(
          setLoadedCompany(transformCompanyResponseToLoadedCompany(company))
        );
      }
    }
  }, [
    companies?.result,
    originalArgs?.body.companyId,
    selectCompanyData,
    dispatch,
  ]);

  const onNavigationLinkClicked = () => {
    setTimeout(() => {
      showGlobalLoader();
    }, 1000);
  };

  const logout = () => {
    window.location.href =
      window.location.origin + "/logout?returnTo=" + window.location.href;
  };

  const handleLogoClick = () => {
    if (!user) {
      window.location.href = process.env
        .REACT_APP_MARKETING_WEBSITE_URL as string;
    }
  };

  return (
    <Navbar id="header-navbar" fixed="top" className="navbar" expand="sm">
      <Container className="mx-0 mw-100 px-4">
        <Navbar.Brand>
          <Link to={RoutePaths.HOME} onClick={handleLogoClick}>
            <img src="/logo.png" className="app-logo" alt="logo" />
          </Link>
        </Navbar.Brand>
        {user?.id && (
          <>
            <Navbar.Toggle aria-controls="header-collapse" />
            <Navbar.Collapse id="header-collapse">
              {loadedCompany?.id && (
                <>
                  <Nav navbarScroll className="w-100">
                    <NavDropdown
                      title={<span className="fs-5">Hub</span>}
                      className="me-auto d-flex align-items-center"
                    >
                      <NavDropdown.Item disabled>Hub</NavDropdown.Item>
                      {loadedCompany.companyUserRolesAndPermissions?.proposal
                        ?.role !== ProposalRole.NO_ACCESS && (
                        <NavDropdown.Item
                          href={process.env.REACT_APP_PROPOSALS_APP_URL || ""}
                          onClick={onNavigationLinkClicked}
                        >
                          Proposals
                        </NavDropdown.Item>
                      )}
                      {loadedCompany.companyUserRolesAndPermissions
                        ?.accountsAndPayroll?.role !==
                        AccountsAndPayrollRole.NO_ACCESS && (
                        <NavDropdown.Item
                          href={process.env.REACT_APP_ACCOUNTS_APP_URL || ""}
                          onClick={onNavigationLinkClicked}
                        >
                          Accounts & Payroll
                        </NavDropdown.Item>
                      )}
                      {loadedCompany.companyUserRolesAndPermissions?.projects
                        ?.role !== ProjectRole.NO_ACCESS && (
                        <NavDropdown.Item
                          href={PROJECTS_APP_URL}
                          onClick={onNavigationLinkClicked}
                        >
                          Projects & Channels
                        </NavDropdown.Item>
                      )}
                    </NavDropdown>
                    {isLoadingSelectCompany ? (
                      <NavItem>
                        <div className="d-flex align-items-center h-100 px-4">
                          <Spinner
                            size="sm"
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                          />
                        </div>
                      </NavItem>
                    ) : (
                      <NavDropdown
                        title={
                          <span className="d-inline-block text-truncate">
                            {loadedCompany?.name}
                          </span>
                        }
                        className="me-3 d-flex align-items-center nav-item-dropdown-truncated nav-item-dropdown-full-page"
                        align="end"
                      >
                        {companies?.result?.map((company) => (
                          <NavDropdown.Item
                            className="d-flex"
                            key={company._id}
                            disabled={company._id === loadedCompany.id}
                            onClick={() => {
                              if (company._id) {
                                // send company selection to backend
                                selectCompanyMutation({
                                  body: { companyId: company._id },
                                });
                                navigate(RoutePaths.HOME);
                              }
                            }}
                          >
                            <>
                              <span className="d-inline-block text-truncate company-name">
                                {company.name}
                              </span>
                              <span className="d-inline-block">
                                {company._id === loadedCompany.id
                                  ? " (Selected)"
                                  : ""}
                              </span>
                            </>
                          </NavDropdown.Item>
                        ))}
                        <NavDropdown.Divider />
                        <NavDropdown.Item
                          as={Link}
                          to={RoutePaths.COMPANY_CREATE}
                        >
                          Create Company
                        </NavDropdown.Item>
                      </NavDropdown>
                    )}
                    <Nav.Item
                      as={Link}
                      to={RoutePaths.SETTINGS_USER}
                      className="icon-button"
                    >
                      <IconSettings width="1.714285em" height="1.714285em" />
                    </Nav.Item>
                  </Nav>
                </>
              )}
              <Button
                variant="link"
                className="ms-auto icon-button"
                onClick={() => logout()}
              >
                <Power color="black" size="1.714285em" />
              </Button>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
};
