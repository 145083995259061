import { useEffect } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useModalShow from "../../../hooks/useModalShow";

import { RoutePaths } from "../../../routes/routes";
import { useAppSelector } from "../../../store/redux-hooks";
import { useLazyGetSubscriptionQuery } from "../../../store/slices/apiSlice";
import { STRIPE_SUBSCRIPTION_STATUS } from "../../../util/constants/constants";
import BillingInvoices from "./BillingInvoicesTable";
import CancelSubscriptionModal from "./CancelSubscriptionModal";

import PaymentCardDetails from "./PaymentCardDetails";
import SubscriptionAmount from "./SubscriptionAmount";
import TrialPeriod from "./TrialPeriod";
import TrialRemaining from "./TrialRemaining";

export default () => {
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);

  const navigate = useNavigate();

  const { show, toggleModal } = useModalShow();

  const [getSubscriptionData, { data, isFetching, isUninitialized }] =
    useLazyGetSubscriptionQuery();

  useEffect(() => {
    if (loadedCompany?.id) {
      getSubscriptionData({
        urlArgs: {
          companyId: loadedCompany.id,
          priceId: process.env.REACT_APP_STRIPE_PRICE_ID || "",
        },
      });
    }
  }, [loadedCompany?.id, getSubscriptionData]);

  useEffect(() => {
    // if show modal is false, and first request was sent, recheck subscription status (in case it was cancelled)
    if (!show && !isUninitialized) {
      if (loadedCompany?.id) {
        getSubscriptionData({
          urlArgs: {
            companyId: loadedCompany.id,
            priceId: process.env.REACT_APP_STRIPE_PRICE_ID || "",
          },
        });
      }
    }
  }, [show, getSubscriptionData, loadedCompany?.id, isUninitialized]);

  return (
    <Container fluid id="billing">
      <Row className="mb-4">
        <Col className="text-heading fw-medium">Billing</Col>
      </Row>
      {isFetching ? (
        <Row className="justify-content-center">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </Row>
      ) : data?.message === "Customer not subscribed" ? (
        <Row className="mb-5 justify-content-around">
          <Col className="max-width-medium">
            <TrialPeriod />
          </Col>
        </Row>
      ) : (
        <>
          <Row className="mb-4">
            <Col>
              <SubscriptionAmount />
            </Col>
          </Row>
          {data?.status === "trialing" ? (
            <Row className="mb-4">
              <Col xs="auto" className="fw-medium">
                Free Trial: &nbsp;
                <TrialRemaining />
              </Col>
            </Row>
          ) : null}
          <Row className="mb-5 justify-content-between">
            <Col xs={12} md="auto">
              <PaymentCardDetails />
            </Col>
            <Col xs="auto" className="mt-4 mt-md-0">
              <Row className="flex-column justify-content-between height-100">
                <Col xs="auto" className="d-flex justify-content-end mb-2">
                  <Button onClick={() => navigate(RoutePaths.BILLING_CARD)}>
                    Change Card
                  </Button>
                </Col>
                {data && (
                  <Col xs="auto">
                    {[
                      STRIPE_SUBSCRIPTION_STATUS.ACTIVE,
                      STRIPE_SUBSCRIPTION_STATUS.TRIALING,
                    ].includes(data.status) && data.cancel_at_period_end ? (
                      `Subscription cancelled - expiring at the end of the ${
                        data.status === STRIPE_SUBSCRIPTION_STATUS.TRIALING
                          ? "trial"
                          : "billing"
                      } period.`
                    ) : (
                      <Button
                        variant="danger"
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          toggleModal();
                        }}
                      >
                        Cancel Subscription
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </>
      )}
      <Row className="flex-column">
        <Col className="text-heading fw-medium">Invoices</Col>
        <Col>
          <BillingInvoices />
        </Col>
      </Row>
      <CancelSubscriptionModal
        show={show}
        toggleModal={toggleModal}
        subscriptionId={data?.id}
      />
    </Container>
  );
};
