import { Col, Row } from "react-bootstrap";

export default () => {
  return (
    <Row
      id="internal-server-content"
      className="flex-column align-items-center justify-content-center h-100"
    >
      <Col xs="auto">
        <img alt="internal server error" src="/_images/internalServer.png" />
      </Col>
      <Col xs="auto">
        <span className="title text-heading fw-medium">
          System is under maintenance.
        </span>
      </Col>
      <Col xs="auto">
        <p className="description info text-paragraph fw-normal text-center">
          Migration in progress. We’ll come back shortly.
        </p>
      </Col>
    </Row>
  );
};
