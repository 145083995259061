import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RoutePaths } from "../../../routes/routes";
import { useAppDispatch, useAppSelector } from "../../../store/redux-hooks";
import { useRemoveCompanyUserMutation } from "../../../store/slices/apiSlice";
import { addAlert } from "../../../store/slices/notificationSlice";

import { REMOVE_USER_FROM_COMPANY_CONFIRMATION_KEYWORD } from "../../../util/constants/constants";

const DEFAULT_INPUT_VALUE = "";

export default ({
  show,
  toggleModal,
}: {
  show: boolean;
  toggleModal: () => void;
}) => {
  const [removeBoxValue, setRemoveBoxValue] = useState(DEFAULT_INPUT_VALUE);

  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);
  const companyUserData = useAppSelector(
    (state) => state.companyUsers.formData
  );
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [
    removeCompanyUser,
    {
      isSuccess,
      isLoading,
      isUninitialized,
      reset: resetRemoveCompanyUserMutation,
    },
  ] = useRemoveCompanyUserMutation();

  useEffect(() => {
    // if modal closes, reset input value, mutation result and redirect user back to user list
    if (!show) {
      if (!isUninitialized) {
        navigate(RoutePaths.USERS);
      }

      setRemoveBoxValue(DEFAULT_INPUT_VALUE);
      resetRemoveCompanyUserMutation();
    }
  }, [show, resetRemoveCompanyUserMutation, navigate, isUninitialized]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        addAlert({
          variant: "success",
          forRoute: RoutePaths.USERS,
          message: `User successfully removed from company.`,
        })
      );
      navigate(RoutePaths.USERS);
    }
  }, [isSuccess, dispatch, navigate]);

  return (
    <Modal
      id="delete-user-modal"
      show={show}
      onHide={toggleModal}
      centered
      className="custom-share-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Remove user from company</Modal.Title>
      </Modal.Header>
      <Form>
        <Modal.Body>
          <Container fluid>
            <Row className="mb-3 mt-3">
              <Col>
                Please confirm you wish to fully remove this user by writing
                <span className="text-highlight-lightgray">
                  {` ${REMOVE_USER_FROM_COMPANY_CONFIRMATION_KEYWORD} `}
                </span>
                in box below:
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={removeBoxValue}
                    placeholder={REMOVE_USER_FROM_COMPANY_CONFIRMATION_KEYWORD}
                    onChange={({ target: { value } }) =>
                      setRemoveBoxValue(value)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Row className="justify-content-end m-0">
            {!isSuccess && (
              <Col xs="auto">
                <Button
                  type="submit"
                  variant="primary"
                  onClick={(event) => {
                    event.preventDefault();

                    if (loadedCompany?.id && companyUserData.userId) {
                      removeCompanyUser({
                        urlArgs: { userId: companyUserData.userId },
                        body: { companyId: loadedCompany.id },
                      });
                    }
                  }}
                  disabled={
                    isLoading ||
                    removeBoxValue.toLowerCase() !==
                      REMOVE_USER_FROM_COMPANY_CONFIRMATION_KEYWORD.toLowerCase()
                  }
                >
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "  Remove User"
                  )}
                </Button>
              </Col>
            )}
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
