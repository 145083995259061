import { Col, Container, Row } from "react-bootstrap";

import CompanyForm from "../company/CompanyForm";

export default () => {
  return (
    <Container fluid id="settings-company">
      <Row className="mb-4">
        <Col className="text-heading fw-medium">Company Settings</Col>
      </Row>
      <Row>
        <CompanyForm />
      </Row>
    </Container>
  );
};
