import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";

import { RoutePaths } from "../../routes/routes";

// https://redux-toolkit.js.org/rtk-query/usage/error-handling#handling-errors-at-a-macro-level
export const apiErrorHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      // internal server error
      if (action?.payload?.status === 500) {
        window.location.href = RoutePaths.INTERNAL_SERVER_ERROR;
      } else if (action?.payload?.status === 503) {
        window.location.href = RoutePaths.SERVICE_ANAVAILABLE_ERROR;
      }
    }

    return next(action);
  };
