import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { SyntheticEvent, useState } from "react";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/redux-hooks";
import {
  useAddCreditCardMutation,
  useLazyGetCreditCardsDataQuery,
} from "../../../store/slices/apiSlice";
import {
  changeChangePaymentCardForm,
  updateChangePaymentCardFormErrors,
  resetChangePaymentCardForm,
} from "../../../store/slices/companyBillingSlice";
import {
  NAVIGATE_GO_BACK,
  StripePaymentCardDeclineCode,
  StripePaymentCardDeclineCodeMessage,
} from "../../../util/constants/constants";
import RequiredFieldBadge from "../../common/RequiredFieldBadge";

import PaymentCardDetails from "./PaymentCardDetails";

export default () => {
  const stripe = useStripe();
  const elements = useElements();

  const changePaymentCard = useAppSelector(
    (state) => state.companyBilling.changePaymentCard
  );
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [isProcessing, setIsProcessing] = useState(false);

  const [addCreditCardMutation] = useAddCreditCardMutation();
  const [getCreditCardsData] = useLazyGetCreditCardsDataQuery();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleSubmit = async (e: SyntheticEvent) => {
    try {
      e.preventDefault();

      // reset states
      setIsProcessing(true);
      setErrorMessage(null);
      setSuccessMessage(null);

      // validate name
      if (!changePaymentCard.cardholderName) {
        dispatch(
          updateChangePaymentCardFormErrors({
            cardholderName: "Please provide cardholder name",
          })
        );
      }

      if (!stripe || !elements) {
        throw new Error("Loading Stripe, please try again in few seconds...");
      }

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        throw new Error("Loading Stripe, please try again in few seconds...");
      }

      // add credit card as a payment method
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { name: changePaymentCard.cardholderName },
      });

      if (result?.error) {
        throw result?.error;
      }

      if (loadedCompany?.id && result?.paymentMethod?.id) {
        // add this card (as payment method) to this company
        const attachedCreditCard = await addCreditCardMutation({
          urlArgs: { companyId: loadedCompany.id },
          body: { paymentMethodId: result.paymentMethod?.id },
        }).unwrap();

        if (
          attachedCreditCard.setupIntent &&
          ("decline_code" in attachedCreditCard.setupIntent ||
            "code" in attachedCreditCard.setupIntent)
        ) {
          // choose decline_code if it's available (since it's more detailed), code otherwise
          const detailedCode =
            attachedCreditCard.setupIntent?.decline_code ||
            attachedCreditCard.setupIntent?.code;

          switch (detailedCode) {
            case StripePaymentCardDeclineCode.AUTHENTICATION_REQUIRED:
              throw new Error(
                StripePaymentCardDeclineCodeMessage.AUTHENTICATION_REQUIRED
              );
            //
            case StripePaymentCardDeclineCode.APPROVE_WITH_ID:
              throw new Error(
                StripePaymentCardDeclineCodeMessage.APPROVE_WITH_ID
              );
            //
            case StripePaymentCardDeclineCode.CALL_ISSUER:
            case StripePaymentCardDeclineCode.ISSUER_NOT_AVAILABLE:
            case StripePaymentCardDeclineCode.GENERIC_DECLINE:
            case StripePaymentCardDeclineCode.NO_ACTION_TAKEN:
            case StripePaymentCardDeclineCode.NOT_PERMITTED:
            case StripePaymentCardDeclineCode.REVOCATION_OF_ALL_AUTHORIZATIONS:
            case StripePaymentCardDeclineCode.REVOCATION_OF_AUTHORIZATION:
            case StripePaymentCardDeclineCode.SECURITY_VIOLATION:
            case StripePaymentCardDeclineCode.SERVICE_NOT_ALLOWED:
            case StripePaymentCardDeclineCode.STOP_PAYMENT_ORDER:
            case StripePaymentCardDeclineCode.TRANSACTION_NOT_ALLOWED:
            // fallsthrough
            case StripePaymentCardDeclineCode.FRAUDULENT:
            case StripePaymentCardDeclineCode.LOST_CARD:
            case StripePaymentCardDeclineCode.PICKUP_CARD:
            case StripePaymentCardDeclineCode.STOLEN_CARD:
            case StripePaymentCardDeclineCode.RESTRICTED_CARD:
              throw new Error(
                StripePaymentCardDeclineCodeMessage.CARD_DECLINED
              );
            //
            case StripePaymentCardDeclineCode.CARD_NOT_SUPPORTED:
              throw new Error(
                StripePaymentCardDeclineCodeMessage.CARD_NOT_SUPPORTED
              );
            //
            case StripePaymentCardDeclineCode.CARD_VELOCITY_EXCEEDED:
            case StripePaymentCardDeclineCode.INSUFFICIENT_FUNDS:
            case StripePaymentCardDeclineCode.WITHDRAWAL_COUNT_LIMIT_EXCEEDED:
              throw new Error(
                StripePaymentCardDeclineCodeMessage.INSUFFICIENT_FUNDS
              );
            //
            case StripePaymentCardDeclineCode.CURRENCY_NOT_SUPPORTED:
              throw new Error(
                StripePaymentCardDeclineCodeMessage.CURRENCY_NOT_SUPPORTED
              );
            //
            case StripePaymentCardDeclineCode.DUPLICATE_TRANSACTION:
              throw new Error(
                StripePaymentCardDeclineCodeMessage.DUPLICATE_TRANSACTION
              );
            //
            case StripePaymentCardDeclineCode.EXPIRED_CARD:
              throw new Error(StripePaymentCardDeclineCodeMessage.EXPIRED_CARD);
            //
            case StripePaymentCardDeclineCode.PROCESSING_ERROR:
            case StripePaymentCardDeclineCode.REENTER_TRANSACTION:
            case StripePaymentCardDeclineCode.TRY_AGAIN_LATER:
              throw new Error(
                StripePaymentCardDeclineCodeMessage.PAYMENT_UNSUCCESSFUL
              );
            //
            case StripePaymentCardDeclineCode.INCORRECT_NUMBER:
            case StripePaymentCardDeclineCode.INCORRECT_CVC:
            case StripePaymentCardDeclineCode.INCORRECT_ZIP:
            case StripePaymentCardDeclineCode.INCORRECT_PIN:
            case StripePaymentCardDeclineCode.INVALID_EXPIRY_MONTH:
            case StripePaymentCardDeclineCode.INVALID_EXPIRY_YEAR:
            case StripePaymentCardDeclineCode.INVALID_NUMBER:
            case StripePaymentCardDeclineCode.INVALID_ACCOUNT:
            case StripePaymentCardDeclineCode.NEW_ACCOUNT_INFORMATION_AVAILABLE:
              throw new Error(
                StripePaymentCardDeclineCodeMessage.INCORRECT_CARD_DATA
              );

            default:
          }
        }

        // update card details displayed
        getCreditCardsData({
          urlArgs: { companyId: loadedCompany.id },
        });

        // reset form
        dispatch(resetChangePaymentCardForm());
        cardElement.clear();
      } else {
        throw new Error();
      }

      setSuccessMessage(
        "Credit card successfully changed. You will be billed on this card for future invoices."
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e?.message) {
        setErrorMessage(e?.message);
      } else {
        setErrorMessage("Something went wrong, please try again later.");
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div id="changePaymentCard" className="max-width-medium">
      <Row className="mb-4">
        <Col className="text-heading fw-medium">
          <span className="color-greyish">Billing: </span>
          <span>Change billing card</span>
        </Col>
      </Row>
      {errorMessage && (
        <Row>
          <Col>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}
      {successMessage && (
        <Row>
          <Col>
            <Alert variant="success">{successMessage}</Alert>
          </Col>
        </Row>
      )}
      <Row className="mb-5">
        <Col>
          <PaymentCardDetails />
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>
                Name on card
                <RequiredFieldBadge />
              </Form.Label>
              <Form.Control
                type="text"
                value={changePaymentCard.cardholderName}
                onChange={({ target: { value } }) => {
                  dispatch(
                    changeChangePaymentCardForm({ cardholderName: value })
                  );
                }}
                isInvalid={Boolean(changePaymentCard.errors.cardholderName)}
              />
              <Form.Control.Feedback type="invalid">
                {changePaymentCard.errors.cardholderName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-3 mb-5">
          <Col>
            <CardElement />
          </Col>
        </Row>
        <Row className="mb-4 justify-content-end">
          <Col xs="auto">
            <Button
              variant="secondary"
              disabled={isProcessing}
              onClick={() => {
                navigate(NAVIGATE_GO_BACK);
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="auto">
            <Button type="submit" disabled={isProcessing}>
              {isProcessing ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Save"
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
