import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import HomeNavigation from "./HomeNavigation";

export default () => {
  return (
    <Container fluid>
      <Row>
        <Col id="home-navigation">
          <HomeNavigation />
        </Col>
        <Col id="home-content" className="p-3 p-md-5">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};
