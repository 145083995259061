import { useEffect } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useAppSelector } from "../../../store/redux-hooks";
import { useLazyGetCreditCardsDataQuery } from "../../../store/slices/apiSlice";

export default () => {
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);

  const [getCreditCardsData, { data, isFetching }] =
    useLazyGetCreditCardsDataQuery();

  // sort cards by creation date if there are multiple results, and consider the latest one as default
  const defaultCreditCard =
    data?.data
      ?.map((card) => card) // map so that we get new array reference since original is immutable
      .sort((cardOne, cardTwo) => cardOne.created - cardTwo.created)?.[
      data?.data.length - 1
    ] || null;

  useEffect(() => {
    if (loadedCompany?.id) {
      getCreditCardsData({
        urlArgs: { companyId: loadedCompany.id },
      });
    }
  }, [loadedCompany?.id, getCreditCardsData]);

  return (
    <Container fluid id="paymentCardDetails" className="px-0">
      <Row className="mb-3">
        <Col className="text-subheader">Current Payment Card</Col>
      </Row>
      {isFetching ? (
        <Row className="justify-content-center">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </Row>
      ) : (
        <Row>
          <Col xs="auto">
            <Row className="flex-column fw-medium">
              <Col className="text-start">Type:</Col>
              <Col className="text-start">Cardholder name:</Col>
              <Col className="text-start">Last four digits:</Col>
              <Col className="text-start">Expiry:</Col>
            </Row>
          </Col>
          <Col>
            <Row className="flex-column">
              <Col>{defaultCreditCard?.card.brand}</Col>
              <Col>{defaultCreditCard?.billing_details.name}</Col>
              <Col>{defaultCreditCard?.card.last4}</Col>
              <Col>
                {defaultCreditCard?.card.exp_month}/
                {defaultCreditCard?.card.exp_year}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  );
};
