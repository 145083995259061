import { useEffect } from "react";
import { Alert, Col, Row } from "react-bootstrap";

import { useAppDispatch, useAppSelector } from "../../store/redux-hooks";
import {
  removeAlert,
  removeAlertsForRoute,
} from "../../store/slices/notificationSlice";
import { NotificationAlertRoutes } from "../../util/constants/types/notificationConstants";

interface IAlertHandlerProps {
  currentRoute: NotificationAlertRoutes;
}

const AlertHandler = ({ currentRoute }: IAlertHandlerProps) => {
  const alerts = useAppSelector((state) => state.notification.alerts);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      /**
       * Note: in development mode this will clear alerts instantly because of double render
       *    caused by StrictMode!
       */
      dispatch(removeAlertsForRoute(currentRoute));
    };
  }, [currentRoute, dispatch]);

  return (
    <Row className="flex-column">
      {alerts
        .filter((alert) => alert.forRoute === currentRoute)
        .map(({ id, variant = "success", message }) => (
          <Col key={id}>
            <Alert
              variant={variant}
              dismissible
              onClose={() => {
                dispatch(removeAlert(id));
              }}
            >
              {message}
            </Alert>
          </Col>
        ))}
    </Row>
  );
};

export default AlertHandler;
