import { useEffect } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  // , Spinner
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { RoutePaths } from "../../../routes/routes";
import { useAppDispatch, useAppSelector } from "../../../store/redux-hooks";
import {
  ICreateOrUpdateCompanyClientRequestBodySchema,
  useLazyGetCompanyClientQuery,
  // useRemoveCompanyClientMutation,
} from "../../../store/slices/apiSlice";
import {
  changeCompanyClientForm,
  defaultCompanyClientsSliceFormData,
  ICompanyClientSliceData,
  setCompanyClientForm,
} from "../../../store/slices/companyClientsSlice";
import { addAlert } from "../../../store/slices/notificationSlice";
import {
  allCountries,
  countriesDropdown,
  ICountry,
} from "../../../util/constants/countries";
import {
  allCurrencies,
  currenciesDropdown,
  ICurrency,
} from "../../../util/constants/currencies";
import RequiredFieldBadge from "../../common/RequiredFieldBadge";
import CustomSelect from "../../common/Select";
import useUpsertCompanyClientMutation from "./useUpsertCompanyClientMutation";
import { clientHide, clientVisibilityDropdown, IClientVisibility } from "../../../util/constants/clientVisibility";
import { components, OptionProps } from "react-select";

const transformCompanyClientDataForAPIRequestBody = ({
  data,
}: {
  data: ICompanyClientSliceData;
}): ICreateOrUpdateCompanyClientRequestBodySchema => {
  return {
    name: data.name,
    companyName: data.companyName,
    email: data.email?.trim()?.toLowerCase(),
    phoneNumber: data.phoneNumber,
    vatNumber: data.vatNumber,
    addressLine1: data.addressLine1,
    addressLine2: data.addressLine2,
    country: data.country?.countryCode,
    currency: data.currency?.currencyCode,
    hidden: data.hidden?.value,
  } as ICreateOrUpdateCompanyClientRequestBodySchema;
};

export default () => {
  const companyClientData = useAppSelector(
    (state) => state.companyClients.formData
  );
  const loadedCompany = useAppSelector((state) => state.company.loadedCompany);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const params = useParams();

  const isNewClient = !params?.id;

  const {
    upsertCompanyClientMutation,
    isLoading: isUpsertCompanyClientLoading,
    isSuccess: isUpsertCompanyClientSuccess,
    requestErrors,
    // setRequestErrors,
  } = useUpsertCompanyClientMutation(!isNewClient);

  // const [
  //   removeCompanyClient,
  //   {
  //     isSuccess: isRemoveCompanyClientSuccess,
  //     isLoading: isRemoveCompanyClientLoading,
  //   },
  // ] = useRemoveCompanyClientMutation();

  useEffect(() => {
    if (isUpsertCompanyClientSuccess) {
      dispatch(
        addAlert({
          variant: "success",
          forRoute: RoutePaths.CLIENTS,
          message: `Company client successfully ${
            isNewClient ? "added" : "updated"
          }!`,
        })
      );
      navigate(RoutePaths.CLIENTS);
    }
  }, [isUpsertCompanyClientSuccess, isNewClient, dispatch, navigate]);

  // useEffect(() => {
  //   if (isRemoveCompanyClientSuccess) {
  //     dispatch(
  //       addAlert({
  //         variant: "success",
  //         forRoute: RoutePaths.CLIENTS,
  //         message: `Client successfully removed from company.`,
  //       })
  //     );
  //     navigate(RoutePaths.CLIENTS);
  //   }
  // }, [isRemoveCompanyClientSuccess, dispatch, navigate]);

  const [
    getCompanyClient,
    {
      data: getCompanyClientData,
      // isFetching: isFetchingGetCompanyClient
    },
  ] = useLazyGetCompanyClientQuery();

  useEffect(() => {
    if (params?.id && loadedCompany?.id) {
      // we're in Clients/:id, load this Client
      getCompanyClient({
        urlArgs: { clientUuid: params.id, companyId: loadedCompany.id },
      });
    }
  }, [params, loadedCompany?.id, getCompanyClient]);

  useEffect(() => {
    // set getClientData to form data
    if (getCompanyClientData) {
      dispatch(
        setCompanyClientForm({
          clientUuid: getCompanyClientData.uuid,
          name: getCompanyClientData.name,
          companyName: getCompanyClientData.companyName,
          email: getCompanyClientData.email,
          phoneNumber: getCompanyClientData.phoneNumber,
          vatNumber: getCompanyClientData.vatNumber,
          addressLine1: getCompanyClientData.addressLine1,
          addressLine2: getCompanyClientData.addressLine2,
          country: allCountries.find(
            (country) => country.countryCode === getCompanyClientData.country
          ),
          currency: allCurrencies.find(
            (currency) =>
              currency.currencyCode === getCompanyClientData.currency
          ),
          hidden: clientVisibilityDropdown.find(
              (option) => option.value === getCompanyClientData.hidden
          ),
        })
      );
    }
  }, [getCompanyClientData, dispatch]);

  useEffect(() => {
    // clean up form on unmount
    return () => {
      dispatch(setCompanyClientForm(defaultCompanyClientsSliceFormData()));
    };
  }, [dispatch]);

  const CustomVisibilityOption = (props: OptionProps<IClientVisibility>) => {
    if (props.data.value === clientHide.value) {
      return (<components.Option {...props} children={<>{props.label} <span className="color-greyish">(Hidden)</span></>}/>);
    }
    return (<components.Option {...props} />);
  };

  return (
    <Container fluid id="client-form">
      <Row className="mb-4">
        <Col className="text-heading fw-medium">
          <span className="color-greyish">Clients: </span>
          <span>{isNewClient ? "Create" : "Update"} Client</span>
        </Col>
      </Row>
      <Form className="max-width-medium">
        <Row>
          <Col>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  Client Full Name
                  <RequiredFieldBadge />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={companyClientData.name || ""}
                  onChange={({ target: { value } }) =>
                    dispatch(changeCompanyClientForm({ name: value }))
                  }
                  isInvalid={Boolean(companyClientData.errors?.name)}
                />
                <Form.Control.Feedback type="invalid">
                  {companyClientData.errors?.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>
                  Business Name
                  <RequiredFieldBadge />
                </Form.Label>
                <Form.Control
                  type="text"
                  value={companyClientData.companyName || ""}
                  onChange={({ target: { value } }) =>
                    dispatch(changeCompanyClientForm({ companyName: value }))
                  }
                  isInvalid={Boolean(companyClientData.errors?.companyName)}
                />
                <Form.Control.Feedback type="invalid">
                  {companyClientData.errors?.companyName}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Address line 1</Form.Label>
                  <Form.Control
                    type="text"
                    value={companyClientData.addressLine1 || ""}
                    onChange={({ target: { value } }) =>
                      dispatch(changeCompanyClientForm({ addressLine1: value }))
                    }
                    isInvalid={Boolean(companyClientData.errors?.addressLine1)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {companyClientData.errors?.addressLine1}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Address line 2</Form.Label>
                  <Form.Control
                    type="text"
                    value={companyClientData.addressLine2 || ""}
                    onChange={({ target: { value } }) =>
                      dispatch(changeCompanyClientForm({ addressLine2: value }))
                    }
                    isInvalid={Boolean(companyClientData.errors?.addressLine2)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {companyClientData.errors?.addressLine2}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={companyClientData.email || ""}
                  onChange={({ target: { value } }) =>
                    dispatch(changeCompanyClientForm({ email: value }))
                  }
                  isInvalid={Boolean(companyClientData.errors?.email)}
                />
                <Form.Control.Feedback type="invalid">
                  {companyClientData.errors?.email}
                </Form.Control.Feedback>
                <Form.Text>This email will receive invoices</Form.Text>
              </Form.Group>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Phone number</Form.Label>
                  <Form.Control
                    type="tel"
                    value={companyClientData.phoneNumber || ""}
                    onChange={({ target: { value } }) =>
                      dispatch(changeCompanyClientForm({ phoneNumber: value }))
                    }
                    isInvalid={Boolean(companyClientData.errors?.phoneNumber)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {companyClientData.errors?.phoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group className="mb-3">
                  <Form.Label>VAT number</Form.Label>
                  <Form.Control
                    type="text"
                    value={companyClientData.vatNumber || ""}
                    onChange={({ target: { value } }) =>
                      dispatch(changeCompanyClientForm({ vatNumber: value }))
                    }
                    isInvalid={Boolean(companyClientData.errors?.vatNumber)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {companyClientData.errors?.vatNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Country</Form.Label>
                  <CustomSelect<ICountry>
                    options={countriesDropdown}
                    getOptionLabel={(option) => option.countryName}
                    getOptionValue={(option) => option.countryCode}
                    value={companyClientData.country}
                    onChange={(selected) =>
                      dispatch(changeCompanyClientForm({ country: selected }))
                    }
                    className={
                      companyClientData.errors?.country
                        ? "customselect-error"
                        : ""
                    }
                  />
                  <Form.Control
                    type="hidden"
                    isInvalid={Boolean(companyClientData.errors?.country)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {companyClientData.errors?.country}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} lg={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Currency</Form.Label>
                  <CustomSelect<ICurrency>
                    options={currenciesDropdown}
                    getOptionLabel={(option) =>
                      option.currencyCode +
                      (option.currencySymbol
                        ? " (" + option.currencySymbol + ")"
                        : "")
                    }
                    getOptionValue={(option) => option.currencyCode}
                    value={companyClientData.currency}
                    onChange={(selected) =>
                      dispatch(changeCompanyClientForm({ currency: selected }))
                    }
                    className={
                      companyClientData.errors?.currency
                        ? "customselect-error"
                        : ""
                    }
                  />
                  <Form.Control
                    type="hidden"
                    isInvalid={Boolean(companyClientData.errors?.currency)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {companyClientData.errors?.currency}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xs={12} lg={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Hide contact from contact dropdowns</Form.Label>
                  <CustomSelect<IClientVisibility>
                      components={{Option: CustomVisibilityOption}}
                      options={clientVisibilityDropdown}
                      value={companyClientData.hidden}
                      onChange={(selected) =>
                          dispatch(changeCompanyClientForm({ hidden: selected }))
                      }
                      className={
                        companyClientData.errors?.hidden
                            ? "customselect-error"
                            : ""
                      }
                  />
                  <Form.Control
                      type="hidden"
                      isInvalid={Boolean(companyClientData.errors?.hidden)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {companyClientData.errors?.hidden}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {/*
            ACTIONS
             */}
            <Row className="justify-content-end mb-4">
              {/*<Col xs="auto">
                {!isNewClient && (
                  <Button
                    variant="secondary"
                    type="button"
                    disabled={
                      isUpsertCompanyClientLoading ||
                      isRemoveCompanyClientLoading
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      if (loadedCompany?.id && companyClientData.clientUuid) {
                        removeCompanyClient({
                          urlArgs: {
                            companyId: loadedCompany.id,
                            clientUuid: companyClientData.clientUuid,
                          },
                        });
                      }
                    }}
                  >
                    {isRemoveCompanyClientLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Remove Client"
                    )}
                  </Button>
                )}
              </Col>*/}
              <Col xs="auto">
                <Row>
                  <Col xs="auto">
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={(event) => {
                        event.preventDefault();

                        navigate(RoutePaths.CLIENTS);
                      }}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="primary"
                      type="submit"
                      // disabled={
                      //   isUpsertCompanyClientLoading ||
                      //   isRemoveCompanyClientSuccess
                      // }
                      disabled={isUpsertCompanyClientLoading}
                      onClick={(event) => {
                        event.preventDefault();

                        if (loadedCompany?.id) {
                          upsertCompanyClientMutation({
                            body: transformCompanyClientDataForAPIRequestBody({
                              data: companyClientData,
                            }),
                            urlArgs: {
                              ...(companyClientData.clientUuid && {
                                clientUuid: companyClientData.clientUuid,
                              }),
                              companyId: loadedCompany.id,
                            },
                          });
                        }
                      }}
                    >
                      {isUpsertCompanyClientLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            {requestErrors?.message && (
              <Row>
                <Alert variant="danger">{requestErrors?.message}</Alert>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    </Container>
  );
};
